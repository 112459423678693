<template lang="pug">
.notification-message
	span {{ t('error.facebook.failedToLoadSdk') }}
	a(@click="openLink('https://help.singa.com')") {{ t('general.support') }}
</template>

<script setup lang="ts">
const { t } = useI18n()

const emit = defineEmits(['close'])

const openLink = (link: any) => {
	navigateTo(link, { external: true })
	emit('close')
}
</script>
