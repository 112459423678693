declare global {
	interface Window { popout: any }
}

export const usePopoutWindowSet = (popout: any) => {
	window.popout = popout
}

export const usePopoutWindowGet = () => {
	return window.popout
}
