import type { Song } from '~/types'
import { useRoute } from '#imports'

type Nullable<T> = T | null

export const useSonglists = ({ songlistApiResource, params }: any) => {
	const route = useRoute()

	const initialLoad: Ref<boolean> = ref(false)
	const results: Ref<Array<Song>> = ref([])
	const loading: Ref<boolean> = ref(false)
	const count: Ref<number> = ref(-1)
	const page: Ref<number> = ref(1)
	const nextPage: Ref<Nullable<string>> = ref('')
	const apiResource: Ref<Function> = ref(songlistApiResource)
	const filterString: Ref<string> = ref('')
	const hasNextPage = computed(() => nextPage.value !== null)
	const previousParams: Ref<any> = ref()
	const previousDetailID: Ref<any> = ref()

	const geoLocationStore = useGeoLocationStore()
	const { contentMarket } = storeToRefs(geoLocationStore)
	/**
	 * Set the API resource to use to fetch the list of songs
	 *
	 * @param {Function} resource - The API resource to use to fetch the list of songs
	 */
	const setSongListResource = (resource: any) => {
		apiResource.value = resource
	}

	/**
	 * Reset the page, count, and nextPage refs to their initial values
	 */
	const resetPage = () => {
		page.value = 1
		count.value = -1
		nextPage.value = ''
	}

	/**
 	 * Reset the results ref to an empty array
 	 */
	const resetResults = () => {
		results.value = []
	}

	/**
	 * Load list of songs from the API from the given API resource and set the results to the results ref
	 *
	 * @param {boolean} search - If true, reset the page and results
	 * @param {Object} query - Any additional query params to pass to the API request
	 * @param {String} id - Artist or playlist id to load songs for the ID
	 * @throws {Error} - Error in the API request
	 */
	const loadSongs = async (search = false, query?: any, detailId?: number, clearResults: boolean = false) => {
		if (!apiResource.value) { return }
		loading.value = true

		try {
			if (search) {
				resetPage()
				resetResults()
			}
			if (route.query.sort) { params.sort = route.query.sort }
			if (filterString.value) { params.search = filterString.value }
			if (query?.page) { page.value = query.page }
			params = { ...params, ...contentMarket.value, ...query, page: page.value }
			previousParams.value = params
			previousDetailID.value = detailId
			const { data } = await apiResource.value(params, detailId)
			if (contentMarket.value.market && data.value && data.value.results) {
				for (const key in data.value.results) {
					const song = 'song' in data.value.results[key] ? data.value.results[key].song : data.value.results[key] as Song
					song.geoblocked = !song.available_markets.includes(contentMarket.value.market)
				}
			}
			// An escape hatch to stop possible infinite loops
			if (!data.value || (data.value.results && !data.value.results.length)) {
				loading.value = false
				return
			}
			nextPage.value = data.value.next
			initialLoad.value = true
			results.value = clearResults ? [...data.value.results] : [...results.value, ...data.value.results]
			count.value = data.value.count
			page.value++
		} catch (error) {
			console.log({ error })
			nextPage.value = null
		} finally {
			loading.value = false
		}
	}
	watch(() => contentMarket.value, async (value) => {
		results.value = []
		await loadSongs(previousParams.value?.search, previousParams.value, previousDetailID.value)
	})
	return {
		results, loading, filterString, count, nextPage, hasNextPage, loadSongs, initialLoad, setSongListResource
	}
}
