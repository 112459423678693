<script>
import { OButton } from '@oruga-ui/oruga-next/dist/esm/button'

export default {
	name: 'SingaButton',
	extends: OButton
}
</script>

<style lang="sass" scoped>
.button
	height: auto
	span
		font: inherit
	&:not(.btn-stacked):not(.is-text-cta)
		@include font(basier, medium)

	//- Size
	&.is-small
		span
			&.icon
				@include fontSize(xs)
			&:not(.icon)
				@include fontSize(s)
		padding: $spacing-8 $spacing-24
	&.is-pill
		padding: 0 $spacing-8
		margin-top: 6px
		span
			@include fontSize(xs)
	&.is-icon
		background-color: transparent
		border: none
		color: $color-grey-30
		padding: 0
		&:hover
			color: white

	&.is-regular
		padding: 12px 32px
		@include fontSize(s)
		border-radius: $radius-default
	&.is-regular-narrow
		padding: 12px $spacing-24
		@include fontSize(s)
		border-radius: $radius-default
	&.dropdown-button-small
		padding: 8px 16px 8px 20px
	&.dropdown-button-regular
		padding: 12px 16px 12px 24px
	&.dropdown-button-small, &.dropdown-button-regular
		.icon
			margin: 0 0 0 16px !important
			width: 16px
			height: 16px
	:deep(.icon)
		margin-left: initial
		margin-right: initial
		&:first-child:last-child
			margin-left: 0
			margin-right: 0
		&:first-child:not(:last-child)
			margin-left: 0
			margin-right: $spacing-4
		&:last-child:not(:first-child)
			margin-left: $spacing-4
			margin-right: 0
	&.SongFavoriteButton
		padding: $spacing-8 20px $spacing-8 20px

//- Border radius
.btn-sharp
	border-radius: $radius-sharp

.button-stacked
	padding: 6px 16px
	text-align: center
	svg
		display: block
		margin: 6px auto

//- Colors
.is-transparent-dark
	background-color: $transparent-white-16
	color: $color-grey-30
	border: transparent
	.icon
		.base-icon
			outline: 1px solid red
	&:hover
		background-color: $transparent-white-12
	&:active
		background-color: $transparent-white-8

.is-outlined-black
	background-color: transparent
	color: black
	border: 1px solid black
	&:hover
		background-color: $color-grey-20
	&:active
		background-color: $color-grey-30

.is-ghost
	background-color: transparent
	color: $color-grey-30
	&:hover
		background-color: $transparent-white-12
	&:active
		background-color: $transparent-white-8

.is-full-ghost
	background-color: transparent
	border: none
	&.is-danger
		color: $color-red-40
		background: transparent
		&:hover
			color: $color-red-60
			background: transparent
	&:hover
		background-color: transparent

.is-primary
	background-color: $primary
	//color: $color-grey-90 !important
	&:hover
		background-color: $color-green-70
	&:active
		background-color: $color-green-80

.is-danger
	background-color: $color-red-60
	color: white
	&:hover
		background-color: $color-red-70
	&:active
		background-color: $color-red-80
.is-white
	background-color: white
	color: black
	&:hover
		background-color: $color-grey-20
	&:active
		background-color: $color-grey-30
.is-full-width
	width: 100%
.is-text-cta
	padding: 0
	width: 100%
	&:not(:hover)
		color: inherit
	&:focus
		box-shadow: none
		text-decoration: underline
.nav-dropdown-button
	&:hover
		background-color: $color-green-60

.button-wrapper
	align-items: center
</style>
