import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import _01_preview_WWIulubJyr from "/app/plugins/01.preview.ts";
import _03_singa_api_QE9JgzLUe3 from "/app/plugins/03.singa-api.ts";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import titles_dw2T9lEw4h from "/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_aRl2YLnTcb from "/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_izaWKZ8rEu from "/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import pwa_icons_Y6agwlnXZq from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import _02_storyblok_8EDnLkSkEH from "/app/plugins/02.storyblok.ts";
import audioPlayer_client_ZnEDR8J4CN from "/app/plugins/audioPlayer.client.ts";
import googleAuth_client_DdjasQPtTL from "/app/plugins/googleAuth.client.ts";
import oruga_2qeBGjDWKO from "/app/plugins/oruga.ts";
import persistedstate_client_O5MsxXlNtZ from "/app/plugins/persistedstate.client.ts";
import previewPlayer_client_rk3VDhWnj9 from "/app/plugins/previewPlayer.client.ts";
import route_scrollbehaviour_client_kCxsdDSYrK from "/app/plugins/route.scrollbehaviour.client.ts";
import translations_DWHk0W6D9U from "/app/plugins/translations.ts";
import virtualScroller_Tp1Q0WbVRA from "/app/plugins/virtualScroller.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  _01_preview_WWIulubJyr,
  _03_singa_api_QE9JgzLUe3,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_tbFNToZNim,
  titles_dw2T9lEw4h,
  defaults_aRl2YLnTcb,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_t2GMTTFnMT,
  i18n_yfWm7jX06p,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  _02_storyblok_8EDnLkSkEH,
  audioPlayer_client_ZnEDR8J4CN,
  googleAuth_client_DdjasQPtTL,
  oruga_2qeBGjDWKO,
  persistedstate_client_O5MsxXlNtZ,
  previewPlayer_client_rk3VDhWnj9,
  route_scrollbehaviour_client_kCxsdDSYrK,
  translations_DWHk0W6D9U,
  virtualScroller_Tp1Q0WbVRA
]