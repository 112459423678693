<template lang="pug">
SingaContextMenu.ContextMenu(ref="dropdown" :appendToBodyCopyParent="true" :position="position" :appendToBody="true" aria-role="list" :mobile-modal="false" multiple @click.stop.prevent :trapFocus="true" :closeOnClick="false" :canClose="false")
	template(#trigger="{ active }")
		SingaButton.is-small.context-menu-list-button(aria-label="open or close menu" :class="active ? 'is-transparent-dark' : 'is-ghost'" icon-right="ellipsis-horizontal" v-if="props.isListItem" @click.stop.prevent="toggle")
		SingaButton.is-small.is-transparent-dark.context-menu-button(v-else aria-label="open or close menu" icon-right="ellipsis-horizontal" @click.stop.prevent="toggle")

	.wrapper(@mouseenter="isInside()" @mouseleave="isOutside()")
		slot(name="content")
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
	isListItem: {
		type: Boolean,
		default: false
	},
	allowClosing: {
		type: Boolean,
		default: false
	}
})

const emit = defineEmits(['initContext', 'close'])

const dropdown = ref()
const inside = ref(false)

const isInside = () => {
	inside.value = true
}

const isOutside = () => {
	inside.value = false
}

const toggleDropdown = () => {
	dropdown.value.toggle()
	dropdown.value.updateAppendToBody()
}

onClickOutside(dropdown, (event) => {
	if (dropdown.value.isActive && !inside.value) {
		toggleDropdown()
		emit('close')
	}
})

const leftPosition = ref(0)

const position = computed(() => {
	if (import.meta.server) {
		return 'bottom-right'
	}
	return window.innerWidth - 200 < leftPosition.value ? 'bottom-left' : 'bottom-right'
})

const toggle = (event: any) => {
	leftPosition.value = event.clientX
	if (dropdown.value.isActive) {
		emit('close')
	} else {
		emit('initContext')
	}
	toggleDropdown()
}

useEventOn('contextMenu:close', () => {
	if (dropdown.value && dropdown.value.isActive) {
		toggleDropdown()
		emit('close')
	}
})

defineExpose({
	toggleDropdown
})

onUnmounted(() => {
	useEventOff('contextMenu:close')
})
</script>

<style lang="sass" scoped>
.ContextMenu
	display: flex
.context-menu-list-button.is-small
	padding: $spacing-4 $spacing-8
	border: none
	box-shadow: none
.context-menu-button
	height: 100%
	padding: $spacing-8 20px !important
</style>

<style lang="sass">
.context-loading-wrap
	display: flex
	padding: $spacing-4 0
	.center
		margin: auto
</style>
