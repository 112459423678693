import type { Ref } from 'vue'
import type { Playlist } from '~~/types'
import { useNuxtApp } from '#imports'

export const useUserPlaylists = () => {
	const items: Ref<Playlist[]> = ref([])
	const loading = ref(false)
	const error = ref(null)
	const page = ref(1)
	const nextPage = ref(null)
	const hasNextPage = ref(false)

	/**
	 * Load the list of user's own playlists and add them to the `items`
	 *
	 * @param {Object} params - The parameters to be used in the request of user's own playlists
	 * @param {Boolean} reload - Whether to load a next page or results, or make clear the current results and load the first page
	 * @throws {Error} - If the API request fails
	 */
	const loadUserPlaylists = async (reload = false) => {
		const { $singaApi } = useNuxtApp()
		const { loadPlaylists, results, hasNextPage: hasNextPlaylistPage } = usePlaylistLists($singaApi.Me.Playlists.list, { page_size: 8 })
		loading.value = true
		if (reload) {
			items.value = []
			page.value = 1
		}
		try {
			await loadPlaylists(false, { page: page.value })
			hasNextPage.value = hasNextPlaylistPage.value
			items.value = [...items.value as Playlist[], ...results.value]
			page.value++
		} catch (err: any) {
			error.value = err
			if (err?.response?.status === 404) {
				nextPage.value = null
			}
		} finally {
			loading.value = false
		}
	}
	/**
	 * Remove a playlist from the list of user's playlists
	 *
	 * @param {number} id - playlist ID that is removed from the list of user's playlists
	 */
	const removePlaylist = (id: number) => {
		if (items.value) {
			items.value = items.value.filter(playlist => playlist.id !== id)
		}
	}
	/** Reset the list of user's playlists to empty */

	const resetUserPlaylists = () => {
		items.value = []
	}

	return {
		items,
		loading,
		loadUserPlaylists,
		removePlaylist,
		resetUserPlaylists,
		hasNextPage
	}
}
