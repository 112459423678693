import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as billinggASQZ2y793Meta } from "/app/pages/account/billing.vue?macro=true";
import { default as index5Ir6lW214cMeta } from "/app/pages/account/email/index.vue?macro=true";
import { default as profilelwi6rEuniiMeta } from "/app/pages/account/profile.vue?macro=true";
import { default as settingsD93HPwacmMMeta } from "/app/pages/account/settings.vue?macro=true";
import { default as _91hash_93pUeS7qeIGjMeta } from "/app/pages/artists/[slug]/[hash].vue?macro=true";
import { default as _91hash_93ipX8AKZCTDMeta } from "/app/pages/artists/[slug]/[songSlug]/[hash].vue?macro=true";
import { default as index1q1d5vezG8Meta } from "/app/pages/device/index.vue?macro=true";
import { default as discoverePGQ37vGI5Meta } from "/app/pages/discover.vue?macro=true";
import { default as _91id_93KYgUbW8XrhMeta } from "/app/pages/genres/[name]-karaoke/[id].vue?macro=true";
import { default as index8NF141nNWJMeta } from "/app/pages/genres/index.vue?macro=true";
import { default as _91hash_93AkECKHHZ8pMeta } from "/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue?macro=true";
import { default as indexR4G1VcjjKYMeta } from "/app/pages/karaoke-near-me/[term]/[city]/index.vue?macro=true";
import { default as index1WgoSzkf4TMeta } from "/app/pages/karaoke-near-me/[term]/index.vue?macro=true";
import { default as indexSHbk7l6bByMeta } from "/app/pages/login/forgot/index.vue?macro=true";
import { default as resetow09FRWSyTMeta } from "/app/pages/login/forgot/reset.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as my_45libraryPuzOxTchHTMeta } from "/app/pages/my-library.vue?macro=true";
import { default as _91hash_93mQ6zjAwoVzMeta } from "/app/pages/playlists/[slug]/[hash].vue?macro=true";
import { default as indexoS4qtmBtZrMeta } from "/app/pages/playlists/index.vue?macro=true";
import { default as presentation8W2SsY0f16Meta } from "/app/pages/presentation.vue?macro=true";
import { default as _91search_93SJIFlfof3wMeta } from "/app/pages/results/artists/[search].vue?macro=true";
import { default as _91search_93lxmqddgDraMeta } from "/app/pages/results/playlists/[search].vue?macro=true";
import { default as _91search_93T0091xY7wbMeta } from "/app/pages/results/songs/[search].vue?macro=true";
import { default as _91search_93AgHrfJw0IjMeta } from "/app/pages/search/results/[search].vue?macro=true";
import { default as indexPR01X9xlmeMeta } from "/app/pages/search/results/index.vue?macro=true";
import { default as indexQlXibAvyQGMeta } from "/app/pages/songs/index.vue?macro=true";
import { default as _91language_93UDZalqi6H7Meta } from "/app/pages/songs/language/[language].vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug___us",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/us/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug___fi",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/fi/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug___sv",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/sv/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: billinggASQZ2y793Meta?.name ?? "account-billing___en",
    path: billinggASQZ2y793Meta?.path ?? "/en/account/billing",
    meta: billinggASQZ2y793Meta || {},
    alias: billinggASQZ2y793Meta?.alias || [],
    redirect: billinggASQZ2y793Meta?.redirect,
    component: () => import("/app/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billinggASQZ2y793Meta?.name ?? "account-billing___us",
    path: billinggASQZ2y793Meta?.path ?? "/us/account/billing",
    meta: billinggASQZ2y793Meta || {},
    alias: billinggASQZ2y793Meta?.alias || [],
    redirect: billinggASQZ2y793Meta?.redirect,
    component: () => import("/app/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billinggASQZ2y793Meta?.name ?? "account-billing___fi",
    path: billinggASQZ2y793Meta?.path ?? "/fi/account/billing",
    meta: billinggASQZ2y793Meta || {},
    alias: billinggASQZ2y793Meta?.alias || [],
    redirect: billinggASQZ2y793Meta?.redirect,
    component: () => import("/app/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: billinggASQZ2y793Meta?.name ?? "account-billing___sv",
    path: billinggASQZ2y793Meta?.path ?? "/sv/account/billing",
    meta: billinggASQZ2y793Meta || {},
    alias: billinggASQZ2y793Meta?.alias || [],
    redirect: billinggASQZ2y793Meta?.redirect,
    component: () => import("/app/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: index5Ir6lW214cMeta?.name ?? "account-email___en",
    path: index5Ir6lW214cMeta?.path ?? "/en/account/email",
    meta: index5Ir6lW214cMeta || {},
    alias: index5Ir6lW214cMeta?.alias || [],
    redirect: index5Ir6lW214cMeta?.redirect,
    component: () => import("/app/pages/account/email/index.vue").then(m => m.default || m)
  },
  {
    name: index5Ir6lW214cMeta?.name ?? "account-email___us",
    path: index5Ir6lW214cMeta?.path ?? "/us/account/email",
    meta: index5Ir6lW214cMeta || {},
    alias: index5Ir6lW214cMeta?.alias || [],
    redirect: index5Ir6lW214cMeta?.redirect,
    component: () => import("/app/pages/account/email/index.vue").then(m => m.default || m)
  },
  {
    name: index5Ir6lW214cMeta?.name ?? "account-email___fi",
    path: index5Ir6lW214cMeta?.path ?? "/fi/account/email",
    meta: index5Ir6lW214cMeta || {},
    alias: index5Ir6lW214cMeta?.alias || [],
    redirect: index5Ir6lW214cMeta?.redirect,
    component: () => import("/app/pages/account/email/index.vue").then(m => m.default || m)
  },
  {
    name: index5Ir6lW214cMeta?.name ?? "account-email___sv",
    path: index5Ir6lW214cMeta?.path ?? "/sv/account/email",
    meta: index5Ir6lW214cMeta || {},
    alias: index5Ir6lW214cMeta?.alias || [],
    redirect: index5Ir6lW214cMeta?.redirect,
    component: () => import("/app/pages/account/email/index.vue").then(m => m.default || m)
  },
  {
    name: profilelwi6rEuniiMeta?.name ?? "account-profile___en",
    path: profilelwi6rEuniiMeta?.path ?? "/en/account/profile",
    meta: profilelwi6rEuniiMeta || {},
    alias: profilelwi6rEuniiMeta?.alias || [],
    redirect: profilelwi6rEuniiMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilelwi6rEuniiMeta?.name ?? "account-profile___us",
    path: profilelwi6rEuniiMeta?.path ?? "/us/account/profile",
    meta: profilelwi6rEuniiMeta || {},
    alias: profilelwi6rEuniiMeta?.alias || [],
    redirect: profilelwi6rEuniiMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilelwi6rEuniiMeta?.name ?? "account-profile___fi",
    path: profilelwi6rEuniiMeta?.path ?? "/fi/account/profile",
    meta: profilelwi6rEuniiMeta || {},
    alias: profilelwi6rEuniiMeta?.alias || [],
    redirect: profilelwi6rEuniiMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilelwi6rEuniiMeta?.name ?? "account-profile___sv",
    path: profilelwi6rEuniiMeta?.path ?? "/sv/account/profile",
    meta: profilelwi6rEuniiMeta || {},
    alias: profilelwi6rEuniiMeta?.alias || [],
    redirect: profilelwi6rEuniiMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: settingsD93HPwacmMMeta?.name ?? "account-settings___en",
    path: settingsD93HPwacmMMeta?.path ?? "/en/account/settings",
    meta: settingsD93HPwacmMMeta || {},
    alias: settingsD93HPwacmMMeta?.alias || [],
    redirect: settingsD93HPwacmMMeta?.redirect,
    component: () => import("/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsD93HPwacmMMeta?.name ?? "account-settings___us",
    path: settingsD93HPwacmMMeta?.path ?? "/us/account/settings",
    meta: settingsD93HPwacmMMeta || {},
    alias: settingsD93HPwacmMMeta?.alias || [],
    redirect: settingsD93HPwacmMMeta?.redirect,
    component: () => import("/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsD93HPwacmMMeta?.name ?? "account-settings___fi",
    path: settingsD93HPwacmMMeta?.path ?? "/fi/account/settings",
    meta: settingsD93HPwacmMMeta || {},
    alias: settingsD93HPwacmMMeta?.alias || [],
    redirect: settingsD93HPwacmMMeta?.redirect,
    component: () => import("/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsD93HPwacmMMeta?.name ?? "account-settings___sv",
    path: settingsD93HPwacmMMeta?.path ?? "/sv/account/settings",
    meta: settingsD93HPwacmMMeta || {},
    alias: settingsD93HPwacmMMeta?.alias || [],
    redirect: settingsD93HPwacmMMeta?.redirect,
    component: () => import("/app/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93pUeS7qeIGjMeta?.name ?? "artists-slug-hash___en",
    path: _91hash_93pUeS7qeIGjMeta?.path ?? "/en/artists/:slug()/:hash()",
    meta: _91hash_93pUeS7qeIGjMeta || {},
    alias: _91hash_93pUeS7qeIGjMeta?.alias || [],
    redirect: _91hash_93pUeS7qeIGjMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93pUeS7qeIGjMeta?.name ?? "artists-slug-hash___us",
    path: _91hash_93pUeS7qeIGjMeta?.path ?? "/us/artists/:slug()/:hash()",
    meta: _91hash_93pUeS7qeIGjMeta || {},
    alias: _91hash_93pUeS7qeIGjMeta?.alias || [],
    redirect: _91hash_93pUeS7qeIGjMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93pUeS7qeIGjMeta?.name ?? "artists-slug-hash___fi",
    path: _91hash_93pUeS7qeIGjMeta?.path ?? "/fi/artists/:slug()/:hash()",
    meta: _91hash_93pUeS7qeIGjMeta || {},
    alias: _91hash_93pUeS7qeIGjMeta?.alias || [],
    redirect: _91hash_93pUeS7qeIGjMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93pUeS7qeIGjMeta?.name ?? "artists-slug-hash___sv",
    path: _91hash_93pUeS7qeIGjMeta?.path ?? "/sv/artists/:slug()/:hash()",
    meta: _91hash_93pUeS7qeIGjMeta || {},
    alias: _91hash_93pUeS7qeIGjMeta?.alias || [],
    redirect: _91hash_93pUeS7qeIGjMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93ipX8AKZCTDMeta?.name ?? "artists-slug-songSlug-hash___en",
    path: _91hash_93ipX8AKZCTDMeta?.path ?? "/en/artists/:slug()/:songSlug()/:hash()",
    meta: _91hash_93ipX8AKZCTDMeta || {},
    alias: _91hash_93ipX8AKZCTDMeta?.alias || [],
    redirect: _91hash_93ipX8AKZCTDMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[songSlug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93ipX8AKZCTDMeta?.name ?? "artists-slug-songSlug-hash___us",
    path: _91hash_93ipX8AKZCTDMeta?.path ?? "/us/artists/:slug()/:songSlug()/:hash()",
    meta: _91hash_93ipX8AKZCTDMeta || {},
    alias: _91hash_93ipX8AKZCTDMeta?.alias || [],
    redirect: _91hash_93ipX8AKZCTDMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[songSlug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93ipX8AKZCTDMeta?.name ?? "artists-slug-songSlug-hash___fi",
    path: _91hash_93ipX8AKZCTDMeta?.path ?? "/fi/artists/:slug()/:songSlug()/:hash()",
    meta: _91hash_93ipX8AKZCTDMeta || {},
    alias: _91hash_93ipX8AKZCTDMeta?.alias || [],
    redirect: _91hash_93ipX8AKZCTDMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[songSlug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93ipX8AKZCTDMeta?.name ?? "artists-slug-songSlug-hash___sv",
    path: _91hash_93ipX8AKZCTDMeta?.path ?? "/sv/artists/:slug()/:songSlug()/:hash()",
    meta: _91hash_93ipX8AKZCTDMeta || {},
    alias: _91hash_93ipX8AKZCTDMeta?.alias || [],
    redirect: _91hash_93ipX8AKZCTDMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[songSlug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: index1q1d5vezG8Meta?.name ?? "device___en",
    path: index1q1d5vezG8Meta?.path ?? "/en/device",
    meta: index1q1d5vezG8Meta || {},
    alias: index1q1d5vezG8Meta?.alias || [],
    redirect: index1q1d5vezG8Meta?.redirect,
    component: () => import("/app/pages/device/index.vue").then(m => m.default || m)
  },
  {
    name: index1q1d5vezG8Meta?.name ?? "device___us",
    path: index1q1d5vezG8Meta?.path ?? "/us/device",
    meta: index1q1d5vezG8Meta || {},
    alias: index1q1d5vezG8Meta?.alias || [],
    redirect: index1q1d5vezG8Meta?.redirect,
    component: () => import("/app/pages/device/index.vue").then(m => m.default || m)
  },
  {
    name: index1q1d5vezG8Meta?.name ?? "device___fi",
    path: index1q1d5vezG8Meta?.path ?? "/fi/device",
    meta: index1q1d5vezG8Meta || {},
    alias: index1q1d5vezG8Meta?.alias || [],
    redirect: index1q1d5vezG8Meta?.redirect,
    component: () => import("/app/pages/device/index.vue").then(m => m.default || m)
  },
  {
    name: index1q1d5vezG8Meta?.name ?? "device___sv",
    path: index1q1d5vezG8Meta?.path ?? "/sv/device",
    meta: index1q1d5vezG8Meta || {},
    alias: index1q1d5vezG8Meta?.alias || [],
    redirect: index1q1d5vezG8Meta?.redirect,
    component: () => import("/app/pages/device/index.vue").then(m => m.default || m)
  },
  {
    name: discoverePGQ37vGI5Meta?.name ?? "discover___en",
    path: discoverePGQ37vGI5Meta?.path ?? "/en/discover",
    meta: discoverePGQ37vGI5Meta || {},
    alias: discoverePGQ37vGI5Meta?.alias || [],
    redirect: discoverePGQ37vGI5Meta?.redirect,
    component: () => import("/app/pages/discover.vue").then(m => m.default || m)
  },
  {
    name: discoverePGQ37vGI5Meta?.name ?? "discover___us",
    path: discoverePGQ37vGI5Meta?.path ?? "/us/discover",
    meta: discoverePGQ37vGI5Meta || {},
    alias: discoverePGQ37vGI5Meta?.alias || [],
    redirect: discoverePGQ37vGI5Meta?.redirect,
    component: () => import("/app/pages/discover.vue").then(m => m.default || m)
  },
  {
    name: discoverePGQ37vGI5Meta?.name ?? "discover___fi",
    path: discoverePGQ37vGI5Meta?.path ?? "/fi/discover",
    meta: discoverePGQ37vGI5Meta || {},
    alias: discoverePGQ37vGI5Meta?.alias || [],
    redirect: discoverePGQ37vGI5Meta?.redirect,
    component: () => import("/app/pages/discover.vue").then(m => m.default || m)
  },
  {
    name: discoverePGQ37vGI5Meta?.name ?? "discover___sv",
    path: discoverePGQ37vGI5Meta?.path ?? "/sv/discover",
    meta: discoverePGQ37vGI5Meta || {},
    alias: discoverePGQ37vGI5Meta?.alias || [],
    redirect: discoverePGQ37vGI5Meta?.redirect,
    component: () => import("/app/pages/discover.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KYgUbW8XrhMeta?.name ?? "genres-name-karaoke-id___en",
    path: _91id_93KYgUbW8XrhMeta?.path ?? "/en/genres/:name()-karaoke/:id()",
    meta: _91id_93KYgUbW8XrhMeta || {},
    alias: _91id_93KYgUbW8XrhMeta?.alias || [],
    redirect: _91id_93KYgUbW8XrhMeta?.redirect,
    component: () => import("/app/pages/genres/[name]-karaoke/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93KYgUbW8XrhMeta?.name ?? "genres-name-karaoke-id___us",
    path: _91id_93KYgUbW8XrhMeta?.path ?? "/us/genres/:name()-karaoke/:id()",
    meta: _91id_93KYgUbW8XrhMeta || {},
    alias: _91id_93KYgUbW8XrhMeta?.alias || [],
    redirect: _91id_93KYgUbW8XrhMeta?.redirect,
    component: () => import("/app/pages/genres/[name]-karaoke/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93KYgUbW8XrhMeta?.name ?? "genres-name-karaoke-id___fi",
    path: _91id_93KYgUbW8XrhMeta?.path ?? "/fi/genres/:name()-karaoke/:id()",
    meta: _91id_93KYgUbW8XrhMeta || {},
    alias: _91id_93KYgUbW8XrhMeta?.alias || [],
    redirect: _91id_93KYgUbW8XrhMeta?.redirect,
    component: () => import("/app/pages/genres/[name]-karaoke/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93KYgUbW8XrhMeta?.name ?? "genres-name-karaoke-id___sv",
    path: _91id_93KYgUbW8XrhMeta?.path ?? "/sv/genres/:name()-karaoke/:id()",
    meta: _91id_93KYgUbW8XrhMeta || {},
    alias: _91id_93KYgUbW8XrhMeta?.alias || [],
    redirect: _91id_93KYgUbW8XrhMeta?.redirect,
    component: () => import("/app/pages/genres/[name]-karaoke/[id].vue").then(m => m.default || m)
  },
  {
    name: index8NF141nNWJMeta?.name ?? "genres___en",
    path: index8NF141nNWJMeta?.path ?? "/en/genres",
    meta: index8NF141nNWJMeta || {},
    alias: index8NF141nNWJMeta?.alias || [],
    redirect: index8NF141nNWJMeta?.redirect,
    component: () => import("/app/pages/genres/index.vue").then(m => m.default || m)
  },
  {
    name: index8NF141nNWJMeta?.name ?? "genres___us",
    path: index8NF141nNWJMeta?.path ?? "/us/genres",
    meta: index8NF141nNWJMeta || {},
    alias: index8NF141nNWJMeta?.alias || [],
    redirect: index8NF141nNWJMeta?.redirect,
    component: () => import("/app/pages/genres/index.vue").then(m => m.default || m)
  },
  {
    name: index8NF141nNWJMeta?.name ?? "genres___fi",
    path: index8NF141nNWJMeta?.path ?? "/fi/genres",
    meta: index8NF141nNWJMeta || {},
    alias: index8NF141nNWJMeta?.alias || [],
    redirect: index8NF141nNWJMeta?.redirect,
    component: () => import("/app/pages/genres/index.vue").then(m => m.default || m)
  },
  {
    name: index8NF141nNWJMeta?.name ?? "genres___sv",
    path: index8NF141nNWJMeta?.path ?? "/sv/genres",
    meta: index8NF141nNWJMeta || {},
    alias: index8NF141nNWJMeta?.alias || [],
    redirect: index8NF141nNWJMeta?.redirect,
    component: () => import("/app/pages/genres/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___us",
    path: indexN6pT4Un8hYMeta?.path ?? "/us",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fi",
    path: indexN6pT4Un8hYMeta?.path ?? "/fi",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___sv",
    path: indexN6pT4Un8hYMeta?.path ?? "/sv",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93AkECKHHZ8pMeta?.name ?? "karaoke-near-me-term-city-slug-hash___en",
    path: _91hash_93AkECKHHZ8pMeta?.path ?? "/en/karaoke-near-me/:term()/:city()/:slug()/:hash()",
    meta: _91hash_93AkECKHHZ8pMeta || {},
    alias: _91hash_93AkECKHHZ8pMeta?.alias || [],
    redirect: _91hash_93AkECKHHZ8pMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93AkECKHHZ8pMeta?.name ?? "karaoke-near-me-term-city-slug-hash___us",
    path: _91hash_93AkECKHHZ8pMeta?.path ?? "/us/karaoke-near-me/:term()/:city()/:slug()/:hash()",
    meta: _91hash_93AkECKHHZ8pMeta || {},
    alias: _91hash_93AkECKHHZ8pMeta?.alias || [],
    redirect: _91hash_93AkECKHHZ8pMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93AkECKHHZ8pMeta?.name ?? "karaoke-near-me-term-city-slug-hash___fi",
    path: _91hash_93AkECKHHZ8pMeta?.path ?? "/fi/karaoke-near-me/:term()/:city()/:slug()/:hash()",
    meta: _91hash_93AkECKHHZ8pMeta || {},
    alias: _91hash_93AkECKHHZ8pMeta?.alias || [],
    redirect: _91hash_93AkECKHHZ8pMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93AkECKHHZ8pMeta?.name ?? "karaoke-near-me-term-city-slug-hash___sv",
    path: _91hash_93AkECKHHZ8pMeta?.path ?? "/sv/karaoke-near-me/:term()/:city()/:slug()/:hash()",
    meta: _91hash_93AkECKHHZ8pMeta || {},
    alias: _91hash_93AkECKHHZ8pMeta?.alias || [],
    redirect: _91hash_93AkECKHHZ8pMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexR4G1VcjjKYMeta?.name ?? "karaoke-near-me-term-city___en",
    path: indexR4G1VcjjKYMeta?.path ?? "/en/karaoke-near-me/:term()/:city()",
    meta: indexR4G1VcjjKYMeta || {},
    alias: indexR4G1VcjjKYMeta?.alias || [],
    redirect: indexR4G1VcjjKYMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexR4G1VcjjKYMeta?.name ?? "karaoke-near-me-term-city___us",
    path: indexR4G1VcjjKYMeta?.path ?? "/us/karaoke-near-me/:term()/:city()",
    meta: indexR4G1VcjjKYMeta || {},
    alias: indexR4G1VcjjKYMeta?.alias || [],
    redirect: indexR4G1VcjjKYMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexR4G1VcjjKYMeta?.name ?? "karaoke-near-me-term-city___fi",
    path: indexR4G1VcjjKYMeta?.path ?? "/fi/karaoke-near-me/:term()/:city()",
    meta: indexR4G1VcjjKYMeta || {},
    alias: indexR4G1VcjjKYMeta?.alias || [],
    redirect: indexR4G1VcjjKYMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: indexR4G1VcjjKYMeta?.name ?? "karaoke-near-me-term-city___sv",
    path: indexR4G1VcjjKYMeta?.path ?? "/sv/karaoke-near-me/:term()/:city()",
    meta: indexR4G1VcjjKYMeta || {},
    alias: indexR4G1VcjjKYMeta?.alias || [],
    redirect: indexR4G1VcjjKYMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: index1WgoSzkf4TMeta?.name ?? "karaoke-near-me-term___en",
    path: index1WgoSzkf4TMeta?.path ?? "/en/karaoke-near-me/:term()",
    meta: index1WgoSzkf4TMeta || {},
    alias: index1WgoSzkf4TMeta?.alias || [],
    redirect: index1WgoSzkf4TMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/index.vue").then(m => m.default || m)
  },
  {
    name: index1WgoSzkf4TMeta?.name ?? "karaoke-near-me-term___us",
    path: index1WgoSzkf4TMeta?.path ?? "/us/karaoke-near-me/:term()",
    meta: index1WgoSzkf4TMeta || {},
    alias: index1WgoSzkf4TMeta?.alias || [],
    redirect: index1WgoSzkf4TMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/index.vue").then(m => m.default || m)
  },
  {
    name: index1WgoSzkf4TMeta?.name ?? "karaoke-near-me-term___fi",
    path: index1WgoSzkf4TMeta?.path ?? "/fi/karaoke-near-me/:term()",
    meta: index1WgoSzkf4TMeta || {},
    alias: index1WgoSzkf4TMeta?.alias || [],
    redirect: index1WgoSzkf4TMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/index.vue").then(m => m.default || m)
  },
  {
    name: index1WgoSzkf4TMeta?.name ?? "karaoke-near-me-term___sv",
    path: index1WgoSzkf4TMeta?.path ?? "/sv/karaoke-near-me/:term()",
    meta: index1WgoSzkf4TMeta || {},
    alias: index1WgoSzkf4TMeta?.alias || [],
    redirect: index1WgoSzkf4TMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSHbk7l6bByMeta?.name ?? "login-forgot___en",
    path: indexSHbk7l6bByMeta?.path ?? "/en/login/forgot",
    meta: indexSHbk7l6bByMeta || {},
    alias: indexSHbk7l6bByMeta?.alias || [],
    redirect: indexSHbk7l6bByMeta?.redirect,
    component: () => import("/app/pages/login/forgot/index.vue").then(m => m.default || m)
  },
  {
    name: indexSHbk7l6bByMeta?.name ?? "login-forgot___us",
    path: indexSHbk7l6bByMeta?.path ?? "/us/login/forgot",
    meta: indexSHbk7l6bByMeta || {},
    alias: indexSHbk7l6bByMeta?.alias || [],
    redirect: indexSHbk7l6bByMeta?.redirect,
    component: () => import("/app/pages/login/forgot/index.vue").then(m => m.default || m)
  },
  {
    name: indexSHbk7l6bByMeta?.name ?? "login-forgot___fi",
    path: indexSHbk7l6bByMeta?.path ?? "/fi/login/forgot",
    meta: indexSHbk7l6bByMeta || {},
    alias: indexSHbk7l6bByMeta?.alias || [],
    redirect: indexSHbk7l6bByMeta?.redirect,
    component: () => import("/app/pages/login/forgot/index.vue").then(m => m.default || m)
  },
  {
    name: indexSHbk7l6bByMeta?.name ?? "login-forgot___sv",
    path: indexSHbk7l6bByMeta?.path ?? "/sv/login/forgot",
    meta: indexSHbk7l6bByMeta || {},
    alias: indexSHbk7l6bByMeta?.alias || [],
    redirect: indexSHbk7l6bByMeta?.redirect,
    component: () => import("/app/pages/login/forgot/index.vue").then(m => m.default || m)
  },
  {
    name: resetow09FRWSyTMeta?.name ?? "login-forgot-reset___en",
    path: resetow09FRWSyTMeta?.path ?? "/en/login/forgot/reset",
    meta: resetow09FRWSyTMeta || {},
    alias: resetow09FRWSyTMeta?.alias || [],
    redirect: resetow09FRWSyTMeta?.redirect,
    component: () => import("/app/pages/login/forgot/reset.vue").then(m => m.default || m)
  },
  {
    name: resetow09FRWSyTMeta?.name ?? "login-forgot-reset___us",
    path: resetow09FRWSyTMeta?.path ?? "/us/login/forgot/reset",
    meta: resetow09FRWSyTMeta || {},
    alias: resetow09FRWSyTMeta?.alias || [],
    redirect: resetow09FRWSyTMeta?.redirect,
    component: () => import("/app/pages/login/forgot/reset.vue").then(m => m.default || m)
  },
  {
    name: resetow09FRWSyTMeta?.name ?? "login-forgot-reset___fi",
    path: resetow09FRWSyTMeta?.path ?? "/fi/login/forgot/reset",
    meta: resetow09FRWSyTMeta || {},
    alias: resetow09FRWSyTMeta?.alias || [],
    redirect: resetow09FRWSyTMeta?.redirect,
    component: () => import("/app/pages/login/forgot/reset.vue").then(m => m.default || m)
  },
  {
    name: resetow09FRWSyTMeta?.name ?? "login-forgot-reset___sv",
    path: resetow09FRWSyTMeta?.path ?? "/sv/login/forgot/reset",
    meta: resetow09FRWSyTMeta || {},
    alias: resetow09FRWSyTMeta?.alias || [],
    redirect: resetow09FRWSyTMeta?.redirect,
    component: () => import("/app/pages/login/forgot/reset.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login___en",
    path: indexgK5VicK7y2Meta?.path ?? "/en/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login___us",
    path: indexgK5VicK7y2Meta?.path ?? "/us/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login___fi",
    path: indexgK5VicK7y2Meta?.path ?? "/fi/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login___sv",
    path: indexgK5VicK7y2Meta?.path ?? "/sv/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: my_45libraryPuzOxTchHTMeta?.name ?? "my-library___en",
    path: my_45libraryPuzOxTchHTMeta?.path ?? "/en/my-library",
    meta: my_45libraryPuzOxTchHTMeta || {},
    alias: my_45libraryPuzOxTchHTMeta?.alias || [],
    redirect: my_45libraryPuzOxTchHTMeta?.redirect,
    component: () => import("/app/pages/my-library.vue").then(m => m.default || m)
  },
  {
    name: my_45libraryPuzOxTchHTMeta?.name ?? "my-library___us",
    path: my_45libraryPuzOxTchHTMeta?.path ?? "/us/my-library",
    meta: my_45libraryPuzOxTchHTMeta || {},
    alias: my_45libraryPuzOxTchHTMeta?.alias || [],
    redirect: my_45libraryPuzOxTchHTMeta?.redirect,
    component: () => import("/app/pages/my-library.vue").then(m => m.default || m)
  },
  {
    name: my_45libraryPuzOxTchHTMeta?.name ?? "my-library___fi",
    path: my_45libraryPuzOxTchHTMeta?.path ?? "/fi/my-library",
    meta: my_45libraryPuzOxTchHTMeta || {},
    alias: my_45libraryPuzOxTchHTMeta?.alias || [],
    redirect: my_45libraryPuzOxTchHTMeta?.redirect,
    component: () => import("/app/pages/my-library.vue").then(m => m.default || m)
  },
  {
    name: my_45libraryPuzOxTchHTMeta?.name ?? "my-library___sv",
    path: my_45libraryPuzOxTchHTMeta?.path ?? "/sv/my-library",
    meta: my_45libraryPuzOxTchHTMeta || {},
    alias: my_45libraryPuzOxTchHTMeta?.alias || [],
    redirect: my_45libraryPuzOxTchHTMeta?.redirect,
    component: () => import("/app/pages/my-library.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93mQ6zjAwoVzMeta?.name ?? "playlists-slug-hash___en",
    path: _91hash_93mQ6zjAwoVzMeta?.path ?? "/en/playlists/:slug()/:hash()",
    meta: _91hash_93mQ6zjAwoVzMeta || {},
    alias: _91hash_93mQ6zjAwoVzMeta?.alias || [],
    redirect: _91hash_93mQ6zjAwoVzMeta?.redirect,
    component: () => import("/app/pages/playlists/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93mQ6zjAwoVzMeta?.name ?? "playlists-slug-hash___us",
    path: _91hash_93mQ6zjAwoVzMeta?.path ?? "/us/playlists/:slug()/:hash()",
    meta: _91hash_93mQ6zjAwoVzMeta || {},
    alias: _91hash_93mQ6zjAwoVzMeta?.alias || [],
    redirect: _91hash_93mQ6zjAwoVzMeta?.redirect,
    component: () => import("/app/pages/playlists/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93mQ6zjAwoVzMeta?.name ?? "playlists-slug-hash___fi",
    path: _91hash_93mQ6zjAwoVzMeta?.path ?? "/fi/playlists/:slug()/:hash()",
    meta: _91hash_93mQ6zjAwoVzMeta || {},
    alias: _91hash_93mQ6zjAwoVzMeta?.alias || [],
    redirect: _91hash_93mQ6zjAwoVzMeta?.redirect,
    component: () => import("/app/pages/playlists/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93mQ6zjAwoVzMeta?.name ?? "playlists-slug-hash___sv",
    path: _91hash_93mQ6zjAwoVzMeta?.path ?? "/sv/playlists/:slug()/:hash()",
    meta: _91hash_93mQ6zjAwoVzMeta || {},
    alias: _91hash_93mQ6zjAwoVzMeta?.alias || [],
    redirect: _91hash_93mQ6zjAwoVzMeta?.redirect,
    component: () => import("/app/pages/playlists/[slug]/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexoS4qtmBtZrMeta?.name ?? "playlists___en",
    path: indexoS4qtmBtZrMeta?.path ?? "/en/playlists",
    meta: indexoS4qtmBtZrMeta || {},
    alias: indexoS4qtmBtZrMeta?.alias || [],
    redirect: indexoS4qtmBtZrMeta?.redirect,
    component: () => import("/app/pages/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: indexoS4qtmBtZrMeta?.name ?? "playlists___us",
    path: indexoS4qtmBtZrMeta?.path ?? "/us/playlists",
    meta: indexoS4qtmBtZrMeta || {},
    alias: indexoS4qtmBtZrMeta?.alias || [],
    redirect: indexoS4qtmBtZrMeta?.redirect,
    component: () => import("/app/pages/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: indexoS4qtmBtZrMeta?.name ?? "playlists___fi",
    path: indexoS4qtmBtZrMeta?.path ?? "/fi/playlists",
    meta: indexoS4qtmBtZrMeta || {},
    alias: indexoS4qtmBtZrMeta?.alias || [],
    redirect: indexoS4qtmBtZrMeta?.redirect,
    component: () => import("/app/pages/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: indexoS4qtmBtZrMeta?.name ?? "playlists___sv",
    path: indexoS4qtmBtZrMeta?.path ?? "/sv/playlists",
    meta: indexoS4qtmBtZrMeta || {},
    alias: indexoS4qtmBtZrMeta?.alias || [],
    redirect: indexoS4qtmBtZrMeta?.redirect,
    component: () => import("/app/pages/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: presentation8W2SsY0f16Meta?.name ?? "presentation___en",
    path: presentation8W2SsY0f16Meta?.path ?? "/en/presentation",
    meta: presentation8W2SsY0f16Meta || {},
    alias: presentation8W2SsY0f16Meta?.alias || [],
    redirect: presentation8W2SsY0f16Meta?.redirect,
    component: () => import("/app/pages/presentation.vue").then(m => m.default || m)
  },
  {
    name: presentation8W2SsY0f16Meta?.name ?? "presentation___us",
    path: presentation8W2SsY0f16Meta?.path ?? "/us/presentation",
    meta: presentation8W2SsY0f16Meta || {},
    alias: presentation8W2SsY0f16Meta?.alias || [],
    redirect: presentation8W2SsY0f16Meta?.redirect,
    component: () => import("/app/pages/presentation.vue").then(m => m.default || m)
  },
  {
    name: presentation8W2SsY0f16Meta?.name ?? "presentation___fi",
    path: presentation8W2SsY0f16Meta?.path ?? "/fi/presentation",
    meta: presentation8W2SsY0f16Meta || {},
    alias: presentation8W2SsY0f16Meta?.alias || [],
    redirect: presentation8W2SsY0f16Meta?.redirect,
    component: () => import("/app/pages/presentation.vue").then(m => m.default || m)
  },
  {
    name: presentation8W2SsY0f16Meta?.name ?? "presentation___sv",
    path: presentation8W2SsY0f16Meta?.path ?? "/sv/presentation",
    meta: presentation8W2SsY0f16Meta || {},
    alias: presentation8W2SsY0f16Meta?.alias || [],
    redirect: presentation8W2SsY0f16Meta?.redirect,
    component: () => import("/app/pages/presentation.vue").then(m => m.default || m)
  },
  {
    name: _91search_93SJIFlfof3wMeta?.name ?? "results-artists-search___en",
    path: _91search_93SJIFlfof3wMeta?.path ?? "/en/results/artists/:search()",
    meta: _91search_93SJIFlfof3wMeta || {},
    alias: _91search_93SJIFlfof3wMeta?.alias || [],
    redirect: _91search_93SJIFlfof3wMeta?.redirect,
    component: () => import("/app/pages/results/artists/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93SJIFlfof3wMeta?.name ?? "results-artists-search___us",
    path: _91search_93SJIFlfof3wMeta?.path ?? "/us/results/artists/:search()",
    meta: _91search_93SJIFlfof3wMeta || {},
    alias: _91search_93SJIFlfof3wMeta?.alias || [],
    redirect: _91search_93SJIFlfof3wMeta?.redirect,
    component: () => import("/app/pages/results/artists/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93SJIFlfof3wMeta?.name ?? "results-artists-search___fi",
    path: _91search_93SJIFlfof3wMeta?.path ?? "/fi/results/artists/:search()",
    meta: _91search_93SJIFlfof3wMeta || {},
    alias: _91search_93SJIFlfof3wMeta?.alias || [],
    redirect: _91search_93SJIFlfof3wMeta?.redirect,
    component: () => import("/app/pages/results/artists/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93SJIFlfof3wMeta?.name ?? "results-artists-search___sv",
    path: _91search_93SJIFlfof3wMeta?.path ?? "/sv/results/artists/:search()",
    meta: _91search_93SJIFlfof3wMeta || {},
    alias: _91search_93SJIFlfof3wMeta?.alias || [],
    redirect: _91search_93SJIFlfof3wMeta?.redirect,
    component: () => import("/app/pages/results/artists/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93lxmqddgDraMeta?.name ?? "results-playlists-search___en",
    path: _91search_93lxmqddgDraMeta?.path ?? "/en/results/playlists/:search()",
    meta: _91search_93lxmqddgDraMeta || {},
    alias: _91search_93lxmqddgDraMeta?.alias || [],
    redirect: _91search_93lxmqddgDraMeta?.redirect,
    component: () => import("/app/pages/results/playlists/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93lxmqddgDraMeta?.name ?? "results-playlists-search___us",
    path: _91search_93lxmqddgDraMeta?.path ?? "/us/results/playlists/:search()",
    meta: _91search_93lxmqddgDraMeta || {},
    alias: _91search_93lxmqddgDraMeta?.alias || [],
    redirect: _91search_93lxmqddgDraMeta?.redirect,
    component: () => import("/app/pages/results/playlists/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93lxmqddgDraMeta?.name ?? "results-playlists-search___fi",
    path: _91search_93lxmqddgDraMeta?.path ?? "/fi/results/playlists/:search()",
    meta: _91search_93lxmqddgDraMeta || {},
    alias: _91search_93lxmqddgDraMeta?.alias || [],
    redirect: _91search_93lxmqddgDraMeta?.redirect,
    component: () => import("/app/pages/results/playlists/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93lxmqddgDraMeta?.name ?? "results-playlists-search___sv",
    path: _91search_93lxmqddgDraMeta?.path ?? "/sv/results/playlists/:search()",
    meta: _91search_93lxmqddgDraMeta || {},
    alias: _91search_93lxmqddgDraMeta?.alias || [],
    redirect: _91search_93lxmqddgDraMeta?.redirect,
    component: () => import("/app/pages/results/playlists/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93T0091xY7wbMeta?.name ?? "results-songs-search___en",
    path: _91search_93T0091xY7wbMeta?.path ?? "/en/results/songs/:search()",
    meta: _91search_93T0091xY7wbMeta || {},
    alias: _91search_93T0091xY7wbMeta?.alias || [],
    redirect: _91search_93T0091xY7wbMeta?.redirect,
    component: () => import("/app/pages/results/songs/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93T0091xY7wbMeta?.name ?? "results-songs-search___us",
    path: _91search_93T0091xY7wbMeta?.path ?? "/us/results/songs/:search()",
    meta: _91search_93T0091xY7wbMeta || {},
    alias: _91search_93T0091xY7wbMeta?.alias || [],
    redirect: _91search_93T0091xY7wbMeta?.redirect,
    component: () => import("/app/pages/results/songs/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93T0091xY7wbMeta?.name ?? "results-songs-search___fi",
    path: _91search_93T0091xY7wbMeta?.path ?? "/fi/results/songs/:search()",
    meta: _91search_93T0091xY7wbMeta || {},
    alias: _91search_93T0091xY7wbMeta?.alias || [],
    redirect: _91search_93T0091xY7wbMeta?.redirect,
    component: () => import("/app/pages/results/songs/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93T0091xY7wbMeta?.name ?? "results-songs-search___sv",
    path: _91search_93T0091xY7wbMeta?.path ?? "/sv/results/songs/:search()",
    meta: _91search_93T0091xY7wbMeta || {},
    alias: _91search_93T0091xY7wbMeta?.alias || [],
    redirect: _91search_93T0091xY7wbMeta?.redirect,
    component: () => import("/app/pages/results/songs/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93AgHrfJw0IjMeta?.name ?? "search-results-search___en",
    path: _91search_93AgHrfJw0IjMeta?.path ?? "/en/search/results/:search()",
    meta: _91search_93AgHrfJw0IjMeta || {},
    alias: _91search_93AgHrfJw0IjMeta?.alias || [],
    redirect: _91search_93AgHrfJw0IjMeta?.redirect,
    component: () => import("/app/pages/search/results/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93AgHrfJw0IjMeta?.name ?? "search-results-search___us",
    path: _91search_93AgHrfJw0IjMeta?.path ?? "/us/search/results/:search()",
    meta: _91search_93AgHrfJw0IjMeta || {},
    alias: _91search_93AgHrfJw0IjMeta?.alias || [],
    redirect: _91search_93AgHrfJw0IjMeta?.redirect,
    component: () => import("/app/pages/search/results/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93AgHrfJw0IjMeta?.name ?? "search-results-search___fi",
    path: _91search_93AgHrfJw0IjMeta?.path ?? "/fi/search/results/:search()",
    meta: _91search_93AgHrfJw0IjMeta || {},
    alias: _91search_93AgHrfJw0IjMeta?.alias || [],
    redirect: _91search_93AgHrfJw0IjMeta?.redirect,
    component: () => import("/app/pages/search/results/[search].vue").then(m => m.default || m)
  },
  {
    name: _91search_93AgHrfJw0IjMeta?.name ?? "search-results-search___sv",
    path: _91search_93AgHrfJw0IjMeta?.path ?? "/sv/search/results/:search()",
    meta: _91search_93AgHrfJw0IjMeta || {},
    alias: _91search_93AgHrfJw0IjMeta?.alias || [],
    redirect: _91search_93AgHrfJw0IjMeta?.redirect,
    component: () => import("/app/pages/search/results/[search].vue").then(m => m.default || m)
  },
  {
    name: indexPR01X9xlmeMeta?.name ?? "search-results___en",
    path: indexPR01X9xlmeMeta?.path ?? "/en/search/results",
    meta: indexPR01X9xlmeMeta || {},
    alias: indexPR01X9xlmeMeta?.alias || [],
    redirect: indexPR01X9xlmeMeta?.redirect,
    component: () => import("/app/pages/search/results/index.vue").then(m => m.default || m)
  },
  {
    name: indexPR01X9xlmeMeta?.name ?? "search-results___us",
    path: indexPR01X9xlmeMeta?.path ?? "/us/search/results",
    meta: indexPR01X9xlmeMeta || {},
    alias: indexPR01X9xlmeMeta?.alias || [],
    redirect: indexPR01X9xlmeMeta?.redirect,
    component: () => import("/app/pages/search/results/index.vue").then(m => m.default || m)
  },
  {
    name: indexPR01X9xlmeMeta?.name ?? "search-results___fi",
    path: indexPR01X9xlmeMeta?.path ?? "/fi/search/results",
    meta: indexPR01X9xlmeMeta || {},
    alias: indexPR01X9xlmeMeta?.alias || [],
    redirect: indexPR01X9xlmeMeta?.redirect,
    component: () => import("/app/pages/search/results/index.vue").then(m => m.default || m)
  },
  {
    name: indexPR01X9xlmeMeta?.name ?? "search-results___sv",
    path: indexPR01X9xlmeMeta?.path ?? "/sv/search/results",
    meta: indexPR01X9xlmeMeta || {},
    alias: indexPR01X9xlmeMeta?.alias || [],
    redirect: indexPR01X9xlmeMeta?.redirect,
    component: () => import("/app/pages/search/results/index.vue").then(m => m.default || m)
  },
  {
    name: indexQlXibAvyQGMeta?.name ?? "songs___en",
    path: indexQlXibAvyQGMeta?.path ?? "/en/songs",
    meta: indexQlXibAvyQGMeta || {},
    alias: indexQlXibAvyQGMeta?.alias || [],
    redirect: indexQlXibAvyQGMeta?.redirect,
    component: () => import("/app/pages/songs/index.vue").then(m => m.default || m)
  },
  {
    name: indexQlXibAvyQGMeta?.name ?? "songs___us",
    path: indexQlXibAvyQGMeta?.path ?? "/us/songs",
    meta: indexQlXibAvyQGMeta || {},
    alias: indexQlXibAvyQGMeta?.alias || [],
    redirect: indexQlXibAvyQGMeta?.redirect,
    component: () => import("/app/pages/songs/index.vue").then(m => m.default || m)
  },
  {
    name: indexQlXibAvyQGMeta?.name ?? "songs___fi",
    path: indexQlXibAvyQGMeta?.path ?? "/fi/songs",
    meta: indexQlXibAvyQGMeta || {},
    alias: indexQlXibAvyQGMeta?.alias || [],
    redirect: indexQlXibAvyQGMeta?.redirect,
    component: () => import("/app/pages/songs/index.vue").then(m => m.default || m)
  },
  {
    name: indexQlXibAvyQGMeta?.name ?? "songs___sv",
    path: indexQlXibAvyQGMeta?.path ?? "/sv/songs",
    meta: indexQlXibAvyQGMeta || {},
    alias: indexQlXibAvyQGMeta?.alias || [],
    redirect: indexQlXibAvyQGMeta?.redirect,
    component: () => import("/app/pages/songs/index.vue").then(m => m.default || m)
  },
  {
    name: _91language_93UDZalqi6H7Meta?.name ?? "songs-language-language___en",
    path: _91language_93UDZalqi6H7Meta?.path ?? "/en/songs/language/:language()",
    meta: _91language_93UDZalqi6H7Meta || {},
    alias: _91language_93UDZalqi6H7Meta?.alias || [],
    redirect: _91language_93UDZalqi6H7Meta?.redirect,
    component: () => import("/app/pages/songs/language/[language].vue").then(m => m.default || m)
  },
  {
    name: _91language_93UDZalqi6H7Meta?.name ?? "songs-language-language___us",
    path: _91language_93UDZalqi6H7Meta?.path ?? "/us/songs/language/:language()",
    meta: _91language_93UDZalqi6H7Meta || {},
    alias: _91language_93UDZalqi6H7Meta?.alias || [],
    redirect: _91language_93UDZalqi6H7Meta?.redirect,
    component: () => import("/app/pages/songs/language/[language].vue").then(m => m.default || m)
  },
  {
    name: _91language_93UDZalqi6H7Meta?.name ?? "songs-language-language___fi",
    path: _91language_93UDZalqi6H7Meta?.path ?? "/fi/songs/language/:language()",
    meta: _91language_93UDZalqi6H7Meta || {},
    alias: _91language_93UDZalqi6H7Meta?.alias || [],
    redirect: _91language_93UDZalqi6H7Meta?.redirect,
    component: () => import("/app/pages/songs/language/[language].vue").then(m => m.default || m)
  },
  {
    name: _91language_93UDZalqi6H7Meta?.name ?? "songs-language-language___sv",
    path: _91language_93UDZalqi6H7Meta?.path ?? "/sv/songs/language/:language()",
    meta: _91language_93UDZalqi6H7Meta || {},
    alias: _91language_93UDZalqi6H7Meta?.alias || [],
    redirect: _91language_93UDZalqi6H7Meta?.redirect,
    component: () => import("/app/pages/songs/language/[language].vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "signup___en",
    path: indexgK5VicK7y2Meta?.path ?? "/en/signup",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "signup___us",
    path: indexgK5VicK7y2Meta?.path ?? "/us/signup",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "signup___fi",
    path: indexgK5VicK7y2Meta?.path ?? "/fi/signup",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "signup___sv",
    path: indexgK5VicK7y2Meta?.path ?? "/sv/signup",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: profilelwi6rEuniiMeta?.name ?? "account___en",
    path: profilelwi6rEuniiMeta?.path ?? "/en/account",
    meta: profilelwi6rEuniiMeta || {},
    alias: profilelwi6rEuniiMeta?.alias || [],
    redirect: profilelwi6rEuniiMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilelwi6rEuniiMeta?.name ?? "account___us",
    path: profilelwi6rEuniiMeta?.path ?? "/us/account",
    meta: profilelwi6rEuniiMeta || {},
    alias: profilelwi6rEuniiMeta?.alias || [],
    redirect: profilelwi6rEuniiMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilelwi6rEuniiMeta?.name ?? "account___fi",
    path: profilelwi6rEuniiMeta?.path ?? "/fi/account",
    meta: profilelwi6rEuniiMeta || {},
    alias: profilelwi6rEuniiMeta?.alias || [],
    redirect: profilelwi6rEuniiMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profilelwi6rEuniiMeta?.name ?? "account___sv",
    path: profilelwi6rEuniiMeta?.path ?? "/sv/account",
    meta: profilelwi6rEuniiMeta || {},
    alias: profilelwi6rEuniiMeta?.alias || [],
    redirect: profilelwi6rEuniiMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name ?? undefined,
    path: component_45stubnO9MU04yTUMeta?.path ?? "/*/terms-of-use",
    meta: component_45stubnO9MU04yTUMeta || {},
    alias: component_45stubnO9MU04yTUMeta?.alias || [],
    redirect: component_45stubnO9MU04yTUMeta?.redirect,
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name ?? undefined,
    path: component_45stubnO9MU04yTUMeta?.path ?? "/*/privacy-policy",
    meta: component_45stubnO9MU04yTUMeta || {},
    alias: component_45stubnO9MU04yTUMeta?.alias || [],
    redirect: component_45stubnO9MU04yTUMeta?.redirect,
    component: component_45stubnO9MU04yTU
  }
]