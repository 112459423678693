export const useSongProperties = (song: any) => {
	const useImage = useGetImageSize()
	const songName = song?.name
	const artistString = song?.artists.map((n: any) => n.name).join(', ')
	const artistSlug = song?.artists.map((artist: any) => artist.slug).join(',')
	const image = useImage(song?.image, 'medium', 'song', true)
	const id = song?.id
	const resource_id = song?.resource_id

	const songObject = { name: song?.name, link: `/artists/${artistSlug}/${song?.slug}/${song?.hash}/` }
	const artistObjects = song?.artists.map((artist: any) => { return { name: artist.name, link: `/artists/${artist.slug}/${artist.hash}` } })
	return {
		songName, artistString, image, artistObjects, songObject, id, resource_id
	}
}
