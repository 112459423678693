import { defineStore } from 'pinia'
import { useProgrammatic } from '@oruga-ui/oruga-next'
import type { ComposerTranslation } from 'vue-i18n/dist/vue-i18n.d.ts'
import type { Tokens, Credentials } from '../types'
import { useUserStore } from './userStore'
import { useQueueStore } from './queueStore'
import { useAudioPlayerStore } from './player/audioPlayerStore'
import { useMediaFileStore } from './mediaFileStore'
import { usePlayerStore } from './player/karaokePlayer'
import { useNuxtApp, useCookie, useLocalePath, refreshNuxtData, useSegment } from '#imports'

const { oruga } = useProgrammatic()

type Nullable<T> = T | null

interface AuthState {
	tokens: Nullable<Tokens>
	loading: boolean
	error: any
	refreshPromise: any
}

export const useAuthStore = defineStore('authStore', {
	state: (): AuthState => {
		return {
			tokens: null,
			loading: false,
			error: null,
			refreshPromise: null
		}
	},
	getters: {
		isLoggedIn: state => state.tokens !== null && state.tokens !== undefined,
		hasRefreshPromise: state => state.refreshPromise !== null,
		getError: state => state.error
	},
	actions: {
		/**
		 * Logs out the user and resets the user store and clears tokens
		 *
		 * @param {Nullable<Boolean>} loggedOutByUser - if true, redirects to home page after logout
		 * @param {Function} [translate] - optional function to translate the logout message
		 */
		// optional argument translate
		async logout(loggedOutByUser: Nullable<boolean>, translate?: ComposerTranslation) {
			const userStore = useUserStore()
			const queueStore = useQueueStore()
			const audioPlayer = useAudioPlayerStore()
			const { $audioPlayer } = useNuxtApp()
			const mediaFileStore = useMediaFileStore()
			const karaokeStore = usePlayerStore()
			const variantStore = useVariantStore()
			const { setShowMiniPlayer } = karaokeStore
			this.$reset()
			setShowMiniPlayer(false)
			queueStore.$reset()
			audioPlayer.$reset()
			mediaFileStore.$reset()
			variantStore.$reset()
			if ($audioPlayer) {
				queueStore.stopSong()
			}
			if (!loggedOutByUser) {
				userStore.$reset()
			}
			await this.setTokens(null)

			const { clearAll } = useDiscoverStore()
			clearAll()

			if (loggedOutByUser) {
				const { userResourceId } = userStore
				const { segmentEvent } = useSegment()
				const { resetFullStory } = useFullstory()
				segmentEvent('User Logged Out', {
					user_id: userResourceId
				})
				resetFullStory()
				const localePath = useLocalePath()
				navigateTo({ path: localePath('/') })
				if (translate) {
					oruga.notification.open({
						message: translate('auth.loggedOut'),
						icon: 'checkmark-outline',
						iconSize: 'medium',
						closable: true
					})
				}
				userStore.$reset()
			}
		},
		/**
		 * Clears the auth store state's login error message
		 */
		clearLoginError() {
			this.error = null
		},
		/**
		 * Sets the tokens to auth store state and to the cookie
		 * @param {Tokens} tokens - Tokens object or null
		 */
		setTokens(tokens: Nullable<Tokens>) {
			const authCookie: Ref<Nullable<Tokens>> = useCookie('sng_tokens')
			this.tokens = tokens
			authCookie.value = tokens
		},
		/**
		 * Sends the payload from a social login to the Singa OAuth endpoint
		 *
		 * @param {String} [redirectUrl="/"] - route to redirect to after login, defaults to root
		 * @param {Credentials} credentials - payload from the social connection to send to the login endpoint
		 * @param {Function} [translate] - optional function to translate the signup message
		 * @returns {Promise} - Response from the `$singaApi.Connections.getTokens` endpoint
		 * @throws {Error} - Error returned from the `$singaApi.Connections.getTokens` request
		 */
		async registerUser(credentials: Credentials, redirectUrl = '/', translate: ComposerTranslation, getFavorites = true) {
			this.loading = true
			const { $singaApi } = useNuxtApp()
			try {
				await $singaApi.Auth.register(credentials)
				const tokenResponse = await this.getTokens({ username: credentials.email, email: undefined, password: credentials.password }, redirectUrl, getFavorites)
				this.setTokens(tokenResponse)
				oruga.notification.open({
					message: translate('auth.signup.success'),
					icon: 'checkmark-outline',
					iconSize: 'medium'
				})
				this.loading = false
				return tokenResponse
			} catch (err: any) {
				this.loading = false
				this.error = err?.response
				return err
			}
		},

		async getSocialTokens(redirectUrl = '/', payload: object) {
			const userStore = useUserStore()
			this.loading = true
			try {
				const { $singaApi } = useNuxtApp()
				const response = await $singaApi.Connections.getTokens(payload)
				this.setTokens(response.data.value)
				this.loading = false
				await userStore.getUser(true).then(() => {
					const localePath = useLocalePath()
					navigateTo(localePath(redirectUrl))
				})
				oruga.modal.closeAll({ action: 'closeAll' })

				const { clearAll } = useDiscoverStore()
				clearAll()
				await refreshNuxtData()
				return response
			} catch (err: any) {
				this.loading = false
				this.error = err.response
			}
		},
		/**
		 * Sends the credentials to the Singa OAuth endpoint to authenticate the user. Then checks if user has completed onboarding and shows modal if not
		 *
		 * @param {Credentials} credentials - Credentials object
		 * @param {String} [redirectUrl="/"]  - route to redirect to after login, defaults to root
		 * @returns {Promise} - Response from the `$singaApi.Connections.getTokens` endpoint
		 * @throws {Error} - Error returned from the `$singaApi.Connections.getTokens` request
		 */
		async getTokens(credentials: Credentials, redirectUrl = '/', getFavorites = true) {
			const userStore = useUserStore()
			const localePath = useLocalePath()
			const dismissOnboarding = useCookie('dismissOnboarding') as Ref<boolean>

			this.loading = true
			try {
				const { $singaApi } = useNuxtApp()
				const { data, error } = await $singaApi.Auth.getTokens(credentials)
				if (error.value) {
					this.loading = false
					this.error = error.value
					return error.value
				} else {
					this.setTokens(data.value)
					this.loading = false
					await userStore.getUser(getFavorites).then(() => {
						navigateTo(redirectUrl)
						if (redirectUrl !== localePath('/') && !dismissOnboarding.value) {
							// openOnboardingModal()
						}
					})
					oruga.modal.closeAll({ action: 'closeAll' })
					const { clearAll } = useDiscoverStore()
					clearAll()
					await refreshNuxtData()
					return data.value
				}
			} catch (err: any) {
				console.log(err.response)
				this.loading = false
				this.error = err.response
				return err
			}
		},
		/**
		 *	Refreshes the access token
		 * If the auth store state has a refresh promise, returns the existing promise
		 * Or if the auth store state has a refresh token, sends the refresh token to the Singa OAuth endpoint to get new tokens
		 * Otherwise, user will be logged out
		 *
		 *	@returns {Promise} - Promise with the new tokens or error if refresh failed or no refresh token is available (logout)
		 *	@throws {Error} - Error returned from the `$singaApi.Auth.getTokens` request
		 */
		async refreshTokens(cookies: any): Promise<any> {
			if (this.hasRefreshPromise) {
				return this.refreshPromise
			} else if (!!this.tokens && this.tokens.refresh_token) {
				try {
					const body = {
						refresh_token: this.tokens.refresh_token
					}
					const response = await $fetch('/api/oauth/token', {
						body: { ...body },
						method: 'POST',
						credentials: 'include',
						headers: {
							Cookie: cookies
						}
					})
					this.refreshPromise = null
					return response
				} catch (err) {
					await this.logout(false)
					console.log('Refresh token error', err)
					return err
				}
			} else {
				await this.logout(false)
				return new Error('refresh_error')
			}
		}
	}
})
