export const appHead = {"link":[{"rel":"icon","type":"image/png","href":"/img/favicon.png"},{"rel":"apple-touch-icon","href":"/logo/singa192x192.png","sizes":"180x180"},{"rel":"mask-icon","href":"/logo/singa144x144.png"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"theme-color","content":"#000000"},{"name":"robots","content":"index"},{"name":"apple-itunes-app","content":"app-id=802874608"},{"property":"og:type","content":"website"}],"style":[],"script":[{"src":"https://connect.facebook.net/en_US/sdk.js","defer":true,"async":true}],"noscript":[],"htmlAttrs":{"lang":"en"},"title":"Singa"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000