<template lang="pug">
.container(:class="{ 'list-item': isSongListItem }")
	.fill
		.fill-bg(:style="fillerStyles")
		span.stop {{ t('player.stop')}}
		span.stop-fake(:style="textFiller") {{ t('player.stop')}}
</template>

<script setup lang="ts">
const { t } = useI18n()

const props = defineProps({
	bgcolor: {
		type: String,
		default: 'rgba(18, 185, 97, 1)',
		required: false
	},
	completed: {
		type: Number,
		required: true
	},
	isSongListItem: {
		type: Boolean,
		default: false,
		required: false
	}
})

const fillerStyles = computed(() => {
	return {
		transform: `translateX(-${100 - props.completed}%)`,
		backgroundColor: props.bgcolor
	}
})

const textFiller = computed(() => {
	return {
		clipPath: 'inset(0 0 0 ' + props.completed + '%)'
	}
})
</script>

<style lang="sass" scoped>
.container
	height: 40px
	width: 100%
	background-color: #313131
	border-radius: 0 0 $radius-default $radius-default
	overflow: hidden
	position: relative
	&.list-item
		border-radius: $radius-default
.fill
	width: 100%
	height: 100%
	position: relative
	cursor: pointer
.fill-bg
	position: absolute
	left: 0
	right: 0
	width: 100%
	height: 100%
	transform: translateX(-100%)

.stop, .stop-fake
	display: flex
	align-items: center
	justify-content: center
	@include font(basier, medium)
	@include fontSize(s)

.stop
	width: 100%
	height: 100%
	color:  #1A1A1A
	position: absolute
	color: black
.stop-fake
	position: absolute
	left: 0
	right: 0
	top: 0
	bottom: 0
	background: #313131
	color: white
</style>
