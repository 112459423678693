<template lang="pug">
.ActionModal
	.modal-card
		header.modal-card-head
			slot(name="header")
			button.delete(type="button" @click="$emit('close')")

		section
			.modal-body(:class="{ 'modal-body-wrap': hasBody }")
				slot(name="body")
</template>

<script setup lang="ts">
defineProps({
	hasBody: {
		type: Boolean,
		default: true,
		required: false
	}
})

useHead({
	htmlAttrs: {
		class: 'is-clipped'
	}
})
</script>

<style lang="sass">
.ActionModal
	margin: auto
	padding: 0 $spacing-16
	.field.is-horizontal
		padding: $spacing-16 0
		margin-bottom: 0
		&:not(.field-toggle)
			*
				flex: 1

.modal-body-wrap, .modal-form-body
	border-radius: $radius-default
	padding: $spacing-16 $spacing-24
	@media (min-width: $tablet)
		padding: $spacing-24
.modal-form-body
	background-color: $transparent-white-4
.modal-form-footer
	margin-top: $spacing-16
	@media (min-width: $tablet)
		margin-top: $spacing-24

.modal
	z-index: 9001
	&:focus-visible
		outline: 0
	.animation-content
		width: 100%
		height: 100%
		@media (min-width: $tablet)
			height: auto
.modal-background
	z-index: -1
.modal-card-title
	@include font(basier, bold)
	padding-top: 8px
.modal-card
	border: 1px solid $color-grey-80
	border-radius: $radius-default
	padding: $spacing-16
	background-color: $color-grey-90
	margin: auto!important
	overflow-y: auto
	width: 100%
	.delete
		position: absolute
		top: 0
		right: 0
		max-width: 28px
		max-height: 28px
		width: 28px
		height: 28px
		&:hover
			background-color: $color-grey-30
	@media (min-width: $tablet)
		padding: $spacing-24
.modal-card-head
	flex-direction: column
	align-items: flex-start
.modal-card-subtitle
	@include font(basier, medium)
	@include fontSize(m)
	padding-top: $spacing-16
	color: $color-grey-30
	@media (min-width: $tablet)
		padding-right: $spacing-80
.modal-action-background
	.label
		color: $color-grey-30
.container-delete
	margin-top: 20px
	margin-right: 20px
.modal-close
	display: none

.is-clipped
	overflow: hidden

.ActionModal
	.hide-on-modal
		display: none !important
</style>
