export const useMetaTags = () => {
	const setMetaImg = (img?: string, width?: number, height?: number) => {
		const { locale } = useI18n()
		const config = useRuntimeConfig()

		const defaultImages: { [key: string]: string } = {
			fi: `${config.public.SINGA_RESOLVE_PREFIX}/img/share_image_fi.png`,
			sv: `${config.public.SINGA_RESOLVE_PREFIX}/img/share_image_se.png`
		}
		const localeValue = locale.value as string

		if (!img) {
			img = defaultImages[localeValue] || `${config.public.SINGA_RESOLVE_PREFIX}/img/share_image.png`
		}

		return [
			{
				hid: 'og:image',
				property: 'og:image',
				content: img
			},
			{
				hid: 'twitter:image',
				name: 'twitter:image',
				content: img
			},
			{
				hid: 'og:image:width',
				property: 'og:image:width',
				content: width
			},
			{
				hid: 'og:image:height',
				property: 'og:image:height',
				content: height
			}
		]
	}

	const setMetaTitle = (title: string) => {
		return [
			{
				hid: 'og:title',
				property: 'og:title',
				content: title
			},
			{
				hid: 'twitter:title',
				property: 'twitter:title',
				content: title
			}
		]
	}

	const setMetaDescription = (desc: string) => {
		return [
			{
				hid: 'description',
				name: 'description',
				content: desc
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: desc
			},
			{
				hid: 'twitter:description',
				property: 'twitter:description',
				content: desc
			}
		]
	}

	return {
		setMetaImg,
		setMetaTitle,
		setMetaDescription
	}
}
