import { defineStore } from 'pinia'
import { usePopoutWindowGet, usePopoutWindowSet } from '../../composables/usePopout'
import { useQueueStore } from '../queueStore'
import { useMediaFileStore } from '../mediaFileStore'
import { usePlayerStore } from '../player/karaokePlayer'
import { useUserStore } from '../userStore'
import type { SecondScreenState } from '~/types/state'
import { useNuxtApp, useI18n, useRuntimeConfig } from '#imports'

export const useSecondScreenStore = defineStore('secondScreenStore', {
	state: (): SecondScreenState => ({
		presentationRequest: null,
		presentationId: null,
		popoutWindow: null,
		presentationConnection: null,
		playerState: 'interrupted',
		showSecondScreenGuidance: false,
		loadingPlayerData: false
	}),
	getters: {
		connectionState: state => state.presentationConnection ? state.presentationConnection!.state : 'terminated',
		hasConnection: state => (state.presentationConnection !== null && state.presentationConnection !== undefined)
		|| (state.popoutWindow !== null && state.popoutWindow !== undefined),
		isPlayerPlaying: state => (state.playerState && state.playerState === 'resume') || state.playerState === 'started',
		isPlayerInactive: state => state.playerState !== null && (state.playerState === 'interrupted' || state.playerState === 'finished'),
		isPlayerLoading: state => state.playerState !== null && state.playerState === 'loading',
		isSecondScreenActive: state => state.popoutWindow !== null || state.presentationConnection !== null
	},
	actions: {
		initPresentationRequest() {
			const { $oruga } = useNuxtApp()
			const { t } = useI18n()
			const { config } = useRuntimeConfig()
			// @ts-ignore
			const base = config?.app?.baseURL || ''
			// @ts-ignore
			const presentationRequest = new PresentationRequest([`${base}presentation?protocol=PresentationAPI`])

			this.presentationRequest = presentationRequest

			this.showSecondScreenGuidance = true
			return presentationRequest.start().then((connection: any) => {
				this.showSecondScreenGuidance = false
				this.setupConnection({ connection, popoutWindow: null })
				return { success: true }
			}).catch((error: any) => {
				this.showSecondScreenGuidance = false
				if (error && error.message) {
					console.log('error starting presentationRequest', {
						message: error?.message,
						name: error?.name,
						code: error?.code
					})
					if (error.message.includes('incognito')) {
						try {
							$oruga.notifications.open({
								message: t('error.noIcognito'),
								variant: 'danger',
								position: 'top-right',
								duration: 20000
							})
							if (this.presentationConnection) {
								this.presentationConnection.terminate()
							}
						} catch (error) {
							console.error(error)
						}
					}
				} else {
					console.log('error starting presentationRequest, no error message')
				}
				const toReturn = { error }
				return toReturn
			})
		},

		reconnect(presentationId: any) {
			const { config } = useRuntimeConfig()
			if (!this.presentationRequest) {
				// @ts-ignore
				const base = config?.app?.baseURL || ''
				// @ts-ignore
				const presentationRequest = new PresentationRequest([`${base}presentation?protocol=PresentationAPI`])
				this.presentationRequest = presentationRequest
			}

			this.presentationRequest!.reconnect(presentationId).then((connection) => {
				return this.setupConnection({ connection, popoutWindow: null })
			}).catch((error) => {
				if (error) {
					console.log('Presentation.reconnect() error, ' + error?.name + ': ' + error?.message)
				}
			})
		},

		async closeConnection() {
			const { $audioPlayer } = useNuxtApp()
			if (this.presentationConnection && this.presentationConnection.state) {
				await this.presentationConnection!.terminate()
				$audioPlayer.pause()
			}
			this.presentationConnection = null
			if (this.popoutWindow) {
				const popout = usePopoutWindowGet()
				if (popout) {
					popout.close()
				}
			}
		},

		setPlayerState(playerState: any) {
			this.playerState = playerState
			console.log('set player state')
			this.sendMessageToSecondScreen({ method: 'SET_PLAYER_STATE', playerState })
		},

		setupConnection({ connection, popoutWindow }: any) {
			const { $audioPlayer } = useNuxtApp()

			const handleEvent = (event: any) => {
				try {
					const data = JSON.parse(event.data)
					const queueStore = useQueueStore()
					const karaokePlayerStore = usePlayerStore()
					const { setLoadingState } = karaokePlayerStore

					if (data.method === 'SECOND_SCREEN_PLAYER_EVENT') {
						// @ts-ignore
						useEventEmit(`playerEvent:${data.playerEvent}`, true)
						this.playerState = data.playerEvent

						if (data.playerEvent === 'finish') {
							queueStore.removeCurrentFromQueue()
						} else if (data.playerEvent === 'ready') {
							this.loadingPlayerData = false
							window.audioPlayer.events.addEventListener('frequenttimeupdate', (timeStamp: any) => {
								this.sendMessageToSecondScreen({ method: 'SET_PLAYER_TIME_STAMP', timeStamp })
							}, 'PLAYER_CONTROLS_LISTENER')
							const userStore = useUserStore()
							if (userStore.queueAutoPlay || queueStore.firstInQueue.bypassAutoPlay) {
								$audioPlayer.play()
							}
							setLoadingState(false)
						} else if (data.playerEvent === 'interrupted') {
							queueStore.rewindSong()
						} else if (data.playerEvent === 'started') {
							this.loadingPlayerData = false
							$audioPlayer.play()
							queueStore.songStarted()
						}
					} else if (data.method === 'SEND_NEXT_UP_DATA') {
						console.log('setting data', data)
					} else if (data.method === 'SECOND_SCREEN_READY') {
						if (typeof window !== 'undefined') {
							window.focus()
						}

						if (queueStore.hasSongFiles) {
							this.startPlayerInSecondScreen()
						}
						const timeout = queueStore.hasSongFiles ? 5000 : 500
						setTimeout(() => {
							this.sendMessageToSecondScreen({
								method: 'SET_QUEUE_INFO',
								queueInfo: queueStore.queue,
								hideLoading: true
							})
						}, timeout)
					} else if (data.method === 'POPOUT_UNLOAD') {
						console.log('UNLOAD POPOUT WINDOW')
						window.removeEventListener('message', handleEvent, false)
						this.closePopoutWindow()
					}
				} catch (error) {
					console.error(error)
				}
			}
			if (popoutWindow) {
				console.log('SET POPOUT WINDOW')
				usePopoutWindowSet(popoutWindow)
				this.popoutWindow = popoutWindow

				window.removeEventListener('message', handleEvent, false)
				window.addEventListener('message', handleEvent)
			} else {
				connection.addEventListener('message', handleEvent)

				connection.addEventListener('terminate', (event: any) => {
					this.presentationConnection = null
					$audioPlayer.pause()
				})

				connection.addEventListener('change', (event: any) => {
					if (connection.state !== 'connected') {
						connection.terminate()
						$audioPlayer.pause()
					}
				})
				connection.addEventListener('close', () => {
					this.presentationConnection = null
				})
				connection.addEventListener('connect', (event: any) => {})

				this.presentationConnection = connection
			}
		},

		startPlayerInSecondScreen(countdown = false) {
			console.log('start player in second screen')
			const { $audioPlayer } = useNuxtApp()
			const queueStore = useQueueStore()
			const mediaFileStore = useMediaFileStore()
			const { images, lyrics, audioFileByType } = mediaFileStore
			const { firstInQueue, countdownTime } = queueStore
			const { outroStart } = useLyrics()
			queueStore.firstInQueue.variant.outroStart = outroStart(lyrics.content)

			this.sendMessageToSecondScreen({
				method: 'START_PLAYER',
				dataForPlayer: {
					images: toRaw(images.content),
					audio: toRaw(audioFileByType.value),
					lyrics: toRaw(lyrics.content),
					song: toRaw(firstInQueue)
				} as any,
				showLoading: true,
				startingTime: $audioPlayer.getCurrentTime()
			})
			if (countdown) {
				this.sendMessageToSecondScreen({ method: 'SET_AUTOPLAY_COUNTDOWN', initialCountdown: countdownTime })
			}
		},

		async sendMessageToSecondScreen(data: any) {
			const dataToSend = JSON.stringify(data)
			try {
				if (this.presentationConnection) {
					await this.presentationConnection!.send(dataToSend)
				}
				if (this.popoutWindow) {
					usePopoutWindowGet().postMessage(dataToSend, '*')
				}
			} catch (error) {
				console.log('error sending message', error)
			}
		},
		async openPopoutWindow(data?: any) {
			const config = useRuntimeConfig()
			// @ts-ignore
			const base = config?.app?.baseURL || ''
			const popout = await window.open(
				`${base}presentation/?protocol=popoutWindow`,
				'popoutPlayer',
				'width=' + screen.availWidth * 0.6 + ',height=' + screen.availHeight * 0.6
			)
			this.showSecondScreenGuidance = false
			this.setupConnection({ connection: undefined, popoutWindow: popout })

			return Promise.resolve({ popoutOpened: true })
		},
		closePopoutWindow() {
			const { $audioPlayer } = useNuxtApp()

			this.popoutWindow = null

			usePopoutWindowSet(null)
			$audioPlayer.pause()
		},
		commandPlayer(action: any) {
			this.sendMessageToSecondScreen({ method: 'PLAYER_ACTION', action })
		}
	}
})
