<script>
import { OSwitch } from '@oruga-ui/oruga-next/dist/esm/switch'

export default {
	name: 'SingaSwitch',
	extends: OSwitch
}
</script>

<style lang="sass">
.switch
	margin: 0
	.check
		//width: 48px !important
		height: 28px
.switch input[type=checkbox] + .check:before
	background: white
.switch input[type=checkbox]:checked + .check-wrapper .check:before
	//transform: translate3d(calc(100% - 6px), 0, 0)
	background: white
.switch input[type=checkbox]:checked + .check.is-elastic:before
	transform: translate3d(calc(50% - 6px), 0, 0) scaleX(1.5)
.switch input[type=checkbox] + .check
	background: $transparent-white-12
.switch input[type=checkbox] + .check-wrapper .check:before
	background: $color-grey-50
.switch input[type=checkbox]:focus, .switch input[type=checkbox]:active
	box-shadow: 0 0 3px rgba(white,.6)
.switch:hover input[type=checkbox] + .check
	background: $transparent-white-24
</style>
