import mitt from 'mitt'
import type { Emitter } from 'mitt'
import type { ApplicationEvents } from '~/types/applicationEvents'

const emitter: Emitter<ApplicationEvents> = mitt<ApplicationEvents>()

export const useEventEmit = emitter.emit
export const useEventOn = emitter.on
export const useEventOff = emitter.off

if (process.env.NODE_ENV === 'development') {
	emitter.on('*', (type, e) => console.log('📬 Event:', type, e))
}
