export default defineNuxtPlugin({
	enforce: 'post',
	parallel: true,
	setup() {
		return {
			provide: {
				t: (s: any) => s
			}
		}
	}
})
