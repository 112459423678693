import type { Artist, Venue, Playlist, Song } from '~/types'

type Nullable<T> = T | null
export const useResolve = () => {
	const artistResponse: Ref<Nullable<Artist>> = ref(null)
	const venueResponse: Ref<Nullable<Venue>> = ref(null)
	const playlistResponse: Ref<Nullable<Playlist>> = ref(null)
	const songResponse: Ref<Nullable<Song>> = ref(null)

	/**
	 * Resolve an artist object with the given slug and hash from the route parameters. If it's not found, throw an error to get to the 404 page.
	 *
	 * @param {string} slug - Artist slug
	 * @param {string} hash - Artist hash
	 * @throws {Error} - API request error
	 */
	const resolveArtistObject = async (slug: string, hash: string) => {
		const { $singaApi } = useNuxtApp()
		try {
			const { data: artistData } = await $singaApi.Resolve.Artists.get(slug, hash)
			artistResponse.value = artistData.value
		} catch (error) {
			console.log('error in resolve', error)
			throw createError({ statusCode: 404 })
		}
	}

	/**
	 * Resolve a venue object with the given slug from the route parameters. If it's not found, throw an error to get to the 404 page.
	 *
	 * @param {string} slug - Venuel slug
	 * @throws {Error} - API request error
	 */
	const resolveVenueObject = async (slug: string) => {
		const { $singaApi } = useNuxtApp()
		try {
			const { data: venueData } = await $singaApi.Resolve.Venues.get(slug)
			venueResponse.value = venueData.value
		} catch (error) {
			console.log('error in resolve', error)
			throw createError({ statusCode: 404 })
		}
	}

	/**
	 * Resolve a playlist object with the given slug and hash from the route parameters. If it's not found, throw an error to get to the 404 page.
	 *
	 * @param {string} slug - playlist slug
	 * @param {string} hash - playlist hash
	 * @throws {Error} - API request error
	 */
	const resolvePlaylistObject = async (slug: string, hash: string) => {
		const { $singaApi } = useNuxtApp()
		try {
			const { data: playlistData } = await $singaApi.Resolve.Playlists.get(slug, hash)
			playlistResponse.value = playlistData.value
		} catch (error) {
			console.log('error in resolve', error)
			throw createError({ statusCode: 404 })
		}
	}

	/**
	 * Resolve a song object with the given slug and hash from the route parameters. If it's not found, throw an error to get to the 404 page.
	 *
	 * @param {string} slug - Song's artist slug
	 * @param {string} songSlug - Song title slug
	 * @param {string} hash - Song hash
	 * @throws {Error} - API request error
	 */
	const resolveSongObject = async (slug: string, songSlug: string, hash: string) => {
		const { $singaApi } = useNuxtApp()
		const { contentMarket } = useGeoLocationStore()
		try {
			const { data: songData } = await $singaApi.Resolve.Songs.get(slug, songSlug, hash)
			if (songData.value) {
				songData.value.geoblocked = !songData.value.available_markets.includes(contentMarket.market)
			}
			songResponse.value = songData.value
		} catch (error) {
			console.log('error in resolve', error)
			throw createError({ statusCode: 404 })
		}
	}
	return {
		resolveArtistObject,
		artistResponse,
		resolveVenueObject,
		venueResponse,
		resolvePlaylistObject,
		playlistResponse,
		resolveSongObject,
		songResponse
	}
}
