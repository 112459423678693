// @ts-ignore
import AddedToQueue from '~/components/song/notifications/AddedToQueue.vue'
import { useRoute, useNuxtApp } from '#imports'

export const useSongs = () => {
	const { $singaApi } = useNuxtApp()
	const route = useRoute()
	const { resolveSongObject, songResponse: songDetail } = useResolve()
	const { slug, songSlug, hash } = route.params
	const { loading, loadSongs, results, initialLoad, setSongListResource } = useSonglists({ songlistApiResource: null, params: { page_size: 6, sort: 'top' } })

	const resolveSongData = async () => await resolveSongObject(slug.toString(), songSlug.toString(), hash.toString())

	const getSongObject = async () => {
		const { getStateItem } = useContentCacheStore()
		const id = `${slug.toString()}/${songSlug.toString()}/${hash.toString()}`
		getStateItem.slug === id ? songDetail.value = getStateItem.item as Song : await resolveSongData()
	}

	const getParsedSongLyrics = async (songId: number, songVariantId: number) => {
		const mediaStore = useMediaFileStore()
		await mediaStore.fetchLyrics(songId, songVariantId)
		let lyricFile: any = mediaStore.lyrics.content
		if (typeof lyricFile === 'string') {
			lyricFile = JSON.parse(lyricFile)
		}
		if (!lyricFile) {
			console.log('Error: no lyrics found')
			return ''
		}

		const allLines: { text: string, time: number }[] = []

		lyricFile.singers.forEach((singer: any) => {
			singer.lines.forEach((line: any) => {
				const words: string[] = []
				line.words.forEach((word: any) => {
					const combinedWord = word.syllables.map((syllable: any) => syllable.text).join('')
					words.push(combinedWord)
				})
				const time = line.words[0].syllables[0].start
				allLines.push({ text: words.join(' '), time })
			})
		})

		// Sort all lines based on their time
		allLines.sort((a, b) => a.time - b.time)

		// Join the sorted lines into a single string
		return allLines.map(line => line.text).join('\n')
	}

	const promptAddedToQueue = (songName: any, songLink: any) => {
		const { $oruga } = useNuxtApp()
		$oruga.notification.open({
			component: AddedToQueue,
			props: {
				songName,
				songLink
			}
		})
	}

	watch(() => songDetail.value, async (newValue: any) => {
		if (newValue && newValue.artists[0]?.id) {
			setSongListResource((params: any) => $singaApi.Artists.artistSongs(params, newValue.artists[0].id))
		}
		await loadSongs(false)
	})

	return {
		loading, songDetail, resolveSongData, songs: results, initialLoad, getSongObject, getParsedSongLyrics, promptAddedToQueue
	}
}
