import { defineStore } from 'pinia'

export const usePlayerStore = defineStore({
	id: 'KaraokePlayerStore',
	state: () => {
		return {
			showPlayer: false,
			playerState: 'interrupted',
			playerLoading: false,
			showMiniPlayer: false
		}
	},
	actions: {
		setShowPlayer(bool: boolean) {
			const { segmentEvent } = useSegment()
			const queueStore = useQueueStore()
			segmentEvent(bool ? 'Player Expanded' : 'Player Minimized', { session_id: queueStore.firstInQueue?.entryID })
			this.showPlayer = bool
		},
		setShowMiniPlayer(bool: boolean) {
			this.showMiniPlayer = bool
		},
		setLoadingState(bool: boolean) {
			this.playerLoading = bool
		}
	},
	getters: {

	}
})
