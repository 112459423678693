export const useSearch = () => {
	const results = ref([]) as any

	const searchRequest = async (searchTerm: any, params: any) => {
		const { contentMarket } = useGeoLocationStore()
		const { $singaApi } = useNuxtApp()

		params = { ...params, ...contentMarket, backend: 'recombee' }
		const { data } = await $singaApi.Search.get({ search: searchTerm, ...params })
		results.value = data.value
	}
	return {
		searchRequest, results
	}
}
