import { StoryblokVue, apiPlugin } from '@storyblok/vue'

export default defineNuxtPlugin({
	enforce: 'post',
	parallel: true,
	hooks: {
		'app:created'() {
			const config = useRuntimeConfig()
			const nuxtApp = useNuxtApp()

			const usingEditor = nuxtApp.$preview

			useNuxtApp().vueApp.use(StoryblokVue, {
				accessToken: usingEditor ? config.public.storyblokPreviewAccessToken : config.public.storyblokAccessToken,
				bridge: usingEditor,
				use: [apiPlugin]
			})
		}
	}
})
