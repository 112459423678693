<template lang="pug">
.FreemiumModal(v-if="!hasPremiumSub && blok")
	.stage-1(v-if="freemiumStage === FreemiumStage.DEFAULT")
		.modal-card
			header
				.header-container
					.card-header(:style="{'background-color': bgColor}")
						CommonModalBackground
					button.delete(type="button" @click="closeModal()")

				section
					.modal-body
						h1 {{ blok.default_stage_title }}
						span.text {{ blok.default_stage_body }}
						SingaButton.is-regular.is-full-width.white(@click="goToSubscribe") {{ blok.default_stage_button_cta }}
	.stage-2(v-if="freemiumStage === FreemiumStage.TWO_SONGS")
		.modal-card
			header
				.header-container
					.card-header(:style="{'background-color': bgColor}")
						CommonModalBackground
					button.delete(type="button" @click="closeModal()")

				section
					.modal-body
						h1 {{ blok.two_songs_stage_title }}
						span.text {{ blok.two_songs_stage_body }}
						SingaButton.is-regular.is-full-width.white(@click="goToSubscribe") {{ blok.two_songs_stage_button_cta }}
	.stage-3(v-if="freemiumStage === FreemiumStage.ONE_SONG")
		.modal-card
			header
				.header-container
					.card-header(:style="{'background-color': bgColor}")
						CommonModalBackground
					button.delete(type="button" @click="closeModal()")

				section
					.modal-body
						h1 {{ blok.one_song_stage_title }}
						span.text {{ blok.one_song_stage_body }}
						SingaButton.is-regular.is-full-width.white(@click="goToSubscribe") {{ blok.one_song_stage_button_cta }}
	.stage-4(v-if="freemiumStage === FreemiumStage.NO_SONGS")
		.modal-card
			header
				.header-container
					.card-header(:style="{'background-color': bgColor}")
						CommonModalBackground
					button.delete(type="button" @click="closeModal()")

				section
					.modal-body
						h1 {{ blok.no_songs_stage_title }}
						span.text {{ blok.no_songs_stage_body }}
						SingaButton.is-regular.is-full-width.white(@click="goToSubscribe") {{ blok.no_songs_stage_button_cta }}
	.stage-5(v-if="freemiumStage === FreemiumStage.QUEUE")
		.modal-card
			header
				.header-container
					.card-header(:style="{'background-color': bgColor}")
						CommonModalBackground
					button.delete(type="button" @click="closeModal()")

				section
					.modal-body
						h1 {{ blok.queue_stage_title }}
						span.text {{ blok.queue_stage_body }}
						SingaButton.is-regular.is-full-width.white(@click="goToSubscribe") {{ blok.queue_stage_button_cta }}
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useQueueStore } from '~/pinia/queueStore'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'
import { useSecondScreenStore } from '~~/pinia/secondScreen/secondScreenStore'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'

const { $oruga } = useNuxtApp()

const karaokeStore = usePlayerStore()
const { setLoadingState, setShowPlayer } = karaokeStore
const queueStore = useQueueStore()
const userStore = useUserStore()

const audioPlayerStore = useAudioPlayerStore()
const { pauseAudioPlayer } = audioPlayerStore

const bgColor = ref('#12B961')

enum FreemiumStage {
	DEFAULT = 'default',
	TWO_SONGS = 'Two songs left',
	ONE_SONG = 'One song left',
	NO_SONGS = 'No songs left',
	QUEUE = 'queue'
}

const props = defineProps({
	fromQueue: {
		type: Boolean,
		default: false
	},
	blok: {
		required: true,
		type: Object
	}
})

const freemiumStage = ref('')

const { hasPremiumSub, numberOfFreeSongsLeft } = storeToRefs(userStore)
const ctaButtonPath = ref('')

// Get user data first so that there's updated information on the user's free song amount.
// Then set the stage, the background color and the CTA button's path accordingly.

const checkFreemiumStage = async () => {
	await userStore.getUser()
	if (props.fromQueue) {
		freemiumStage.value = FreemiumStage.QUEUE
		bgColor.value = props.blok.queue_stage_color
		ctaButtonPath.value = props.blok.queue_stage_button_cta_path
		return
	}
	if (numberOfFreeSongsLeft.value === 4 || numberOfFreeSongsLeft.value === 3) {
		freemiumStage.value = FreemiumStage.DEFAULT
		bgColor.value = props.blok.default_stage_color
		ctaButtonPath.value = props.blok.default_stage_button_cta_path
	} else if (numberOfFreeSongsLeft.value === 2) {
		freemiumStage.value = FreemiumStage.TWO_SONGS
		bgColor.value = props.blok.two_songs_stage_color
		ctaButtonPath.value = props.blok.two_songs_stage_button_cta_path
	} else if (numberOfFreeSongsLeft.value === 1) {
		freemiumStage.value = FreemiumStage.ONE_SONG
		bgColor.value = props.blok.one_song_stage_color
		ctaButtonPath.value = props.blok.one_song_stage_button_cta_path
	} else if (numberOfFreeSongsLeft.value === 0) {
		freemiumStage.value = FreemiumStage.NO_SONGS
		bgColor.value = props.blok.no_songs_stage_color
		ctaButtonPath.value = props.blok.no_songs_stage_button_cta_path
	}
}

const secondScreenStore = useSecondScreenStore()
const { closeConnection, setPlayerState } = secondScreenStore
const { isSecondScreenActive } = storeToRefs(secondScreenStore)

// After navigating to the path, close the modal, stop the song and hide the player.
const goToSubscribe = async () => {
	if (isSecondScreenActive.value) {
		await closeConnection().then(() => setPlayerState('interrupted'))
	}
	navigateTo(ctaButtonPath.value, { external: true })
	$oruga.modal.closeAll({ action: 'closeAll' })
	queueStore.stopSong()
	setShowPlayer(false)
	setLoadingState(false)
}

useHead({
	htmlAttrs: {
		class: 'is-clipped'
	}
})

const closeModal = () => {
	$oruga.modal.closeAll({ action: 'closeAll' })
}

onMounted(async () => {
	await checkFreemiumStage()
	if (freemiumStage.value !== FreemiumStage.QUEUE) {
		pauseAudioPlayer()
	}
})
</script>

<style scoped lang="sass">
.FreemiumModal
	margin: auto
	padding: $spacing-16

.modal-card
	padding: 0
	border: none
	@media(min-width: $mobile-small)
		max-height: none

.header-container
	overflow: hidden
	position: relative

.card-header
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center

h1
	@include font(basier, bold)
	@include fontSize(3xl)
	padding: $spacing-16 0 $spacing-16 0

.modal-body
	background-color: $color-grey-90
	padding: $spacing-32

.delete
	margin: $spacing-16

.FreemiumModal
	max-width: 768px

.white
	margin-top: $spacing-32
	color: black
	background-color: $color-white

.text
	@include font(basier, regular)
	@include fontSize(m)
	color: $color-grey-30
</style>
