<template lang="pug">
.SongGenres(@click.prevent.stop)
	span.song-genre(v-for="genre in genres" :key="genre.id" v-if="genres" @click="updateFilterByGenre(genre.id)") {{ t(`genre.${genre.name}`) }}
</template>

<script setup lang="ts">
const { t } = useI18n()
const route = useRoute()
const localePath = useLocalePath()

defineProps({
	genres: {
		type: Array as PropType<Genre[]>,
		default: null
	}
})

const updateFilterByGenre = (id: number) => {
	if (!route.fullPath.includes('/songs/')) {
		const query = { genre: id }
		navigateTo({ path: localePath('/songs/'), query: { ...query } })
	}
}
</script>

<style lang="sass" scoped>
.SongGenres
	display: flex
	min-width: 0
	max-width: 322px
	flex-wrap: wrap
	align-items: center
	gap: $spacing-8
	.song-genre
		display: flex
		min-width: 0
		padding: 2px 0
		color: $color-grey-70
		transition: .3s
		cursor: pointer
		@include fontSize(xxs)
		text-transform: uppercase
		&:hover
			color: $color-grey-60
</style>
