import type { UseFetchOptions } from 'nuxt/app'
import type { $Fetch, NitroFetchRequest } from 'nitropack'

export function useAPI<T>(url: string | (()=> string), options: UseFetchOptions<T> = {}) {
	const clientCookieHeaders = useRequestHeaders(['Cookie'])
	const runtimeConfig = useRuntimeConfig()
	const authStore = useAuthStore()
	const apiVersion = '/v1.4'
	const debug = process.env.NODE_ENV === 'development'
	const authTokens = useCookie('sng_tokens')
	const apiClient = $fetch.create({
		retry: 1,
		keepalive: true,
		retryStatusCodes: [400, 401, 402, 403],
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		baseURL: (import.meta.server ? runtimeConfig.SINGA_API_BASE_URL : runtimeConfig.public.SINGA_API_URL) + apiVersion,
		onRequest: ({ request, options }) => {
			if (debug) {
				console.log('Starting Request', JSON.stringify(request))
			}
			const tokens = authStore.tokens
			if (tokens && tokens !== null && options.headers) {
				options.headers = {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': `${tokens.token_type} ${tokens.access_token}`
				}
			}
		},
		onResponseError: async (ctx) => {
			const authStore = useAuthStore()
			const tokens = authStore.tokens
			if (ctx.response?.status === 401 && tokens && tokens !== null) {
				try {
					const response = await authStore.refreshTokens(clientCookieHeaders.cookie)
					if (typeof response === 'object' && response !== null) {
						authTokens.value = JSON.stringify(response)
						authStore.tokens = response
					} else {
						authTokens.value = response
						authStore.tokens = JSON.parse(response)
					}
				} catch (err) {
					return Promise.reject(err)
				}
			}
		}
		// onResponse: async ({ request, response }) => {
		// 	console.log('3 ->->-> onResponse req', request)
		// 	console.log('3 ->->-> onResponse res', response)
		// }
	})
	return useFetch(url, {
		...options,
		$fetch: apiClient as $Fetch<unknown, NitroFetchRequest>
	})
}
