import FreemiumModal from '../components/modals/Freemium.vue'
import { useNuxtApp } from '#imports'

export const openFreemiumModal = async (calledFromQueue = false) => {
	const { $oruga, $preview, $i18n } = useNuxtApp()
	const config = useRuntimeConfig()
	const token = config.public.storyblokAccessToken
	const version = $preview ? 'draft' : 'published'
	const storyId = 'modals-freemium-' + $i18n.locale.value
	// Fetch the story
	const { data }: any = await useFetch(
		`https://api.storyblok.com/v2/cdn/stories/modals/freemium/?&token=${token}&language=${$i18n.locale.value}&cv=0&resolve_links=url&version=${version}`,
		{
			key: storyId,
			watch: [$i18n.locale],
			deep: true
		}
	)
	$oruga.modal.open({
		component: FreemiumModal,
		width: 768,
		props: {
			fromQueue: calledFromQueue,
			story: data.value.story
		}
	})

	return { openFreemiumModal }
}
