<template lang="pug">
.PasswordResetSuccess(ref="el")
	.auth-header
		SingaIcon.success(icon="checkmark-circle-outline" size="large")
		h1 {{ t('auth.email.sent.short') }}
	.auth-body
		p.body-sub {{ t('auth.email.linkCta') }}
	SingaButton.is-full-width.is-transparent-dark.is-regular(@click="navigateTo(localePath('/'))") {{ t('general.backToFront') }}
</template>

<script setup lang="ts">
const el = ref<HTMLDivElement>()
const { t } = useI18n()
const localePath = useLocalePath()

const emit = defineEmits([
	'update-height'
])
const updateHeightOnResize = useDebounceFn(() => {
	emit('update-height', el.value?.offsetHeight)
}, 100)

onMounted(() => {
	window.addEventListener('resize', updateHeightOnResize)
	emit('update-height', el.value?.offsetHeight)
})

onUnmounted(() => {
	window.removeEventListener('resize', updateHeightOnResize)
})
</script>

<style lang="sass" scoped>
.body-sub
	text-align: center
	@include fontSize(l)
	margin-bottom: $spacing-40

.icon
	width: 78px
	height: 78px
	color: $color-red-50
	margin-bottom: $spacing-32
	&.success
		color: $primary
.PasswordResetSuccess
	position: absolute
	left: 0
	right: 0
</style>
