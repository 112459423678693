<script>
import { OInput } from '@oruga-ui/oruga-next/dist/esm/input'

export default {
	name: 'SingaInput',
	extends: OInput
}
</script>

<style lang="sass">
.control
	input.input-regular
		padding: 12px 20px !important
		height: auto
		&:focus, &:hover
			background-color: $transparent-white-16
		:deep(.icon)
			width: 20px !important
	&.has-icons-left
		input:not(.queue-search)
			padding: 12px 56px 12px 56px !important
	.icon.is-left
		height: 100% !important
		.base-icon
			width: 20px
			height: 20px
	.icon.is-right
		height: 100% !important
		.base-icon
			width: 20px
			height: 20px
.control.has-icons-right .icon.is-right
	height: 100%
	right: 20px
.control.has-icons-left .icon, .control.has-icons-right .icon
	width: 20px
	height: 20px
	top: initial
</style>
