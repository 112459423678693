import { createPersistedState } from 'pinia-plugin-persistedstate'

export default defineNuxtPlugin({
	enforce: 'post',
	parallel: true,
	hooks: {
		'app:created'() {
			const nuxtApp = useNuxtApp()
			const pinia: any = nuxtApp.$pinia // weird typing issue
			pinia.use(createPersistedState())
		}
	}
})
