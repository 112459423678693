import { defineStore } from 'pinia'
import type { SearchState } from '../types'
import { useSearch } from '#imports'

export const useSearchStore = defineStore('searchStore', {
	state: (): SearchState => (
		{
			searchTerm: '',
			loading: false,
			error: null,
			previewResults: {
				songs: [],
				artists: [],
				playlists: []
			},
			songs: {
				hasNextPage: false,
				page: 1,
				results: []
			},
			artists: {
				hasNextPage: false,
				page: 1,
				results: []
			},
			playlists: {
				hasNextPage: false,
				page: 1,
				results: []
			},
			selectedSearchFilters: {
				language: [],
				genre: [],
				sort: '',
				has_cover: false,
				has_plus: false,
				has_duet: false,
				has_explicit: false,
				has_original: false
			}
		}
	),
	getters: {
		getSearchTerm: state => state.searchTerm,
		getPreviewSearch: state => state.previewResults,
		getSongs: state => state.songs,
		getArtists: state => state.artists,
		getPlaylists: state => state.playlists,
		isLoading: state => state.loading,
		getFilters: state => state.selectedSearchFilters,
		getGenres: state => state.selectedSearchFilters.genre,
		getSort: state => state.selectedSearchFilters.sort,
		getLanguages: state => state.selectedSearchFilters.language,
		getHasCover: state => state.selectedSearchFilters.has_cover,
		getHasPlus: state => state.selectedSearchFilters.has_plus,
		getHasDuet: state => state.selectedSearchFilters.has_duet,
		getHasExplicit: state => state.selectedSearchFilters.has_explicit,
		getHasOriginal: state => state.selectedSearchFilters.has_original
	},
	actions: {
		/**
		 * Loads the preview search results for the search terms and params, and sets the results to the state
		 *
		 * @param {Object} params - params to be used in the search query
		 * @param {Object} query - query to be used in the search query
		 * @throws {Error} - Search request error
		 */
		async loadPreviewSearch(params: object, query: object) {
			this.loading = true
			try {
				const { searchRequest, results } = useSearch()
				const searchParams = { page: 1, ...params, ...query }
				await searchRequest(this.searchTerm, searchParams)
				this.previewResults.songs = results.value.songs?.items ? results.value.songs.items : []
				this.previewResults.artists = results.value.artists?.items ? results.value.artists.items : []
				this.previewResults.playlists = results.value.playlists?.items ? results.value.playlists.items : []
				this.loading = false
			} catch (err: any) {
				console.log(err)
				this.error = err
				this.loading = false
			}
		},
		/**
		 * Sets the search term and calls the the preview search action
		 * Reset the search store if the new search term differs from the current one in the state
		 *
		 * @param {Object} payload - search term
		 * @param {Object} params - params to be used in the search query
		 * @param {Object} query - query to be used in the search query
		 */
		setSearch(payload: string | null, params: Record<string, any>, query: Record<string, any>) {
			if (this.searchTerm !== payload) { this.resetSearch() }
			this.searchTerm = payload
			this.loadPreviewSearch(params, query)
		},
		/**
		 * Loads the search results for the search term and sets the results to the state
		 * Increases the page number if there is a next page available
		 *
		 * @param {Object} params - params to be used in the search query
		 * @throws {Error} - Search request error
		 */
		async loadDetailSearch(params: any) {
			const { searchRequest, results } = useSearch()
			this.loading = true
			try {
				this.searchTerm = params.search

				const searchParams = { page: 1, ...params }
				await searchRequest(this.searchTerm, searchParams)
				const typeToSearch = this[params.type as keyof object] as any
				typeToSearch.results = results.value[params.type] && results.value[params.type].items ? results.value[params.type].items : []

				if (results.value[params.type].next) {
					typeToSearch.page++
					typeToSearch.hasNextPage = true
				} else {
					typeToSearch.hasNextPage = false
				}
			} catch (err: any) {
				this.error = err
			} finally {
				this.loading = false
			}
		},
		/**
		 * Loads more results for the search and adds them to the existing results in the state
		 *
		 * @param {Object} params - params to be used in the search query
		 * @throws {Error} - Search request error
		 */
		async loadMore(params: any) {
			const { searchRequest, results } = useSearch()
			this.loading = true
			try {
				const typeToSearch = this[params.type as keyof object] as any

				const searchParams = { page: typeToSearch.page, ...params }
				await searchRequest(this.searchTerm, searchParams)
				// Stop possible infinite loops, since the else statement below might not be working
				if (results.value[params.type] === null || undefined) {
					this.loading = false
					typeToSearch.hasNextPage = false
					return
				}
				if (results.value[params.type].next) {
					typeToSearch.page++
					typeToSearch.hasNextPage = true
				} else {
					typeToSearch.hasNextPage = false
				}
				typeToSearch.results = [...typeToSearch.results, ...results.value[params.type].items]
			} catch (err: any) {
				this.error = err
			} finally {
				this.loading = false
			}
		},
		addToSelectedFilter(filterGroup: any, filter: any) {
			(this.selectedSearchFilters[filterGroup as keyof object] as any) = filter
		},
		/**
		 * Resets the search store
		 */
		replaceSelectedSearchFilters(payload: any) {
			this.selectedSearchFilters = payload
		},
		resetSearch() {
			this.$reset()
		}
	}
})
