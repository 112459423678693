import { defineStore } from 'pinia'

interface VariantState {
	versionAutoselect: boolean
	variantSelectionCount: number
	automaticVersionSelectTreshold: number
}

export const useVariantStore = defineStore('variantStore', {
	state: (): VariantState => (
		{
			versionAutoselect: true,
			variantSelectionCount: 0,
			automaticVersionSelectTreshold: 10
		}
	),
	persist: true,
	getters: {
		isVersionAutoselectEnabled: state => state.versionAutoselect,
		getCount: state => state.variantSelectionCount
	},
	actions: {
		incrementVariantSelectionCount() {
			this.variantSelectionCount++
		},
		toggleVersionAutoselect() {
			this.versionAutoselect = !this.versionAutoselect
		}
	}
})
