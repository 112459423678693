export default defineNuxtPlugin({
	enforce: 'post',
	parallel: true,
	hooks: {
		'app:created'() {
			const router: any = useNuxtApp().$router // weird typing issue
			router.options.scrollBehavior = (to: any, from: any, savedPosition: any) => {
				return new Promise((resolve, reject) => {
					const timerId = setInterval(() => {
						if (savedPosition) {
							if (document.documentElement.scrollHeight > savedPosition.top) {
								resolve({ ...savedPosition })
								clearInterval(timerId)
							}
						} else {
							resolve({ top: 0 })
							clearInterval(timerId)
						}
					}, 50)
					setTimeout(() => {
						clearInterval(timerId)
					}, 3000) // This is a bit hacky, but I couldn't figure out a better way and I'm not sure vue/nuxt provides a better way
				})
			}
		}
	}
})
