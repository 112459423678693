export default defineNuxtPlugin({
	name: 'preview',
	enforce: 'pre',
	parallel: true,
	async setup(nuxtApp) {
		const route = useRoute()
		const preview = await !!route.query?._storyblok

		return { provide: { preview } }
	}
})
