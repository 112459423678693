<script>
import { OCarousel } from '@oruga-ui/oruga-next/dist/esm/carousel'

export default {
	name: 'SingaCarousel',
	extends: OCarousel
}
</script>

<style lang="sass">
.icon.carousel-arrow
	width: 40px
	height: 40px
	z-index: 2 !important
	border: none
	color: $color-grey-50
	transition: none
	background: transparent
	&:hover
		border: none
		color: white
	&.has-icons-left
		left: -38px
	&.has-icons-right
		right: -38px
.versions-selector
	overflow: visible
	&.carousel
		width: calc(100% - 14px)
		margin: auto
	.carousel-scene
		margin: auto
		&:before, &:after
			content: ''
			position: absolute
			top: -12px
			bottom: 0
			width: 100%
			height: calc(100% + 12px)
			background: #1a1a1a
			z-index: 1
		&:before
			left: -100%
		&:after
			right: -100%
.version-preference
	.preference-right
		margin-left: $spacing-16
.carousel-items
	align-items: stretch
	.carousel-item
		border-left: 6px solid transparent !important
		border-right: 6px solid transparent !important
</style>
