import { storeToRefs } from 'pinia'
import { useUserStore } from '../pinia/userStore'
import { useAuthStore } from '../pinia/authStore'

export const useUser = () => {
	const userStore = useUserStore()
	const authStore = useAuthStore()
	const { isLoggedIn } = storeToRefs(authStore)

	const hasPremiumOrFreeSongs = ref(false)
	if (userStore.hasFreeSongsLeft || userStore.hasPremiumSub) {
		hasPremiumOrFreeSongs.value = true
	}

	return { isUserAuthorised: isLoggedIn, hasPremiumOrFreeSongs }
}
