<template lang="pug">
.cookie-consent
	.cookie-consent__content
		span.cookie-consent__text
			|{{ t('cookies.bodyText') }}
			NuxtLink.link(:to="localePath('/privacy/')") {{ t('general.privacyPolicy') }}
		SingaButton.is-full.is-primary(@click="acceptCookies") {{ t('general.accept') }}
</template>

<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

const cookieConsent = useCookie<boolean>('cookieConsent', {
	maxAge: 365 * 24 * 60 * 60
})

const acceptCookies = () => {
	cookieConsent.value = true
	reloadNuxtApp()
}
</script>

<style lang="sass" scoped>
.cookie-consent
	position: fixed
	bottom: 20px
	left: 0
	right: 0
	background-color: $color-grey-80
	padding: $spacing-8 $spacing-16 $spacing-8 $spacing-16
	text-align: center
	border-radius: $radius-default
	width: calc(100% - 40px)
	min-height: 64px
	z-index: 1000
	display: flex
	justify-content: center
	margin: 0 auto
	&__content
		display: flex
		justify-content: center
		align-items: center
		&__text
			color: $color-grey-10
			@include fontSize(s)

.link
	text-decoration: underline !important
	color: $color-grey-10
	&:hover
		color: $color-grey-20

.button
	margin: 0 $spacing-16 0 $spacing-16 !important
</style>
