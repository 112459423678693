// @ts-nocheck
import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import type { User, UserState } from '../types'
import { useNuxtApp } from '#imports'

export const useUserStore = defineStore('user', {
	state: (): UserState => (
		{
			user: null,
			loading: false,
			error: null,
			autoPlay: true,
			useSongGrid: false,
			favorites: {
				songs: [],
				artists: [],
				playlists: []
			}
		}
	),
	getters: {
		username: state => state.user?.username,
		getUseSongGrid: state => state.useSongGrid,
		userResourceId: state => state.user?.resource_id,
		userCountry: state => state.user?.country,
		favoritedSongs: state => state.favorites.songs,
		favoritedArtists: state => state.favorites.artists,
		favoritedPlaylists: state => state.favorites.playlists,
		queueAutoPlay: state => state.autoPlay,
		/**
		 * Returns the user country from the state to be used in the content requests
		 *
		 * @param state - User store state
		 */
		userCountryAsObject(state) {
			const country = state.user?.country
			return { market: country }
		},
		hasFreeSongsLeft: state => state.user && state.user.limits.free_songs_left > 0,
		hasPremiumSub: state => state.user && state.user.subscription && state.user.subscription.is_active,
		numberOfFreeSongsLeft: state => state.user?.limits.free_songs_left || 0,
		hasSubOrFreeSongsLeft() { return this.hasPremiumSub || this.hasFreeSongsLeft },
		showDemoPlayer: state => !state.user || useDevice().isMobile
	},
	actions: {
	/** Get all the user's favorites */
		async getAllFavorites() {
			const { $singaApi } = useNuxtApp()
			const { data: results } = await $singaApi.Favorites.list()
			this.favorites.songs = results.value.songs
			this.favorites.artists = results.value.artists
			this.favorites.playlists = results.value.playlists
		},
		/**
		 * Loads the Me object from the API and sets the results to the user store state
		 *
		 * @throws {Error} - Error from the API request
		 * @param getFavorites - Whether to get the user's favorites
		 */
		async getUser(getFavorites = false) {
			this.loading = true
			try {
				const { $singaApi } = useNuxtApp()
				await $singaApi.Me.get().then(async (response) => {
					this.user = response.data.value
					if (this.user) {
						const subscriptionType = () => {
							if (this.hasPremiumSub) {
								return 'premium'
							} else if (this.user.subscription?.trial_end && this.user.subscription.trial_end > new Date().toISOString()) {
								return 'trialing B2C'
							} else {
								return 'free'
							}
						}
						if (getFavorites) {
							await this.getAllFavorites()
						}
						if (import.meta.client) {
							const { city } = useGeoLocationStore()
							const { identifyFullstoryUser } = useFullstory()
							const { isDesktop } = useDevice()
							window.analytics?.identify(this.user.resource_id, {
								country: this.user.country,
								email: this.user.email,
								username: this.user.username,
								city: city?.value,
								subscription_plan: subscriptionType(),
								registration_time: this.user.created
							})
							if (isDesktop && window.FS && identifyFullstoryUser) {
								identifyFullstoryUser({
									resource_id: this.user.resource_id,
									name: this.user.name,
									username_str: this.user.username,
									email: this.user.email
								})
							}
							this.getAutoPlay()
						}
						Sentry?.setUser({
							id: this.user.resource_id,
							username: this.user.username,
							country: this.user.country,
							limits: this.user.limits,
							offboarded: this.user.offboarded,
							subscription: this.user.subscription,
							payment_receipts_enabled: this.user.payment_receipts_enabled,
							payment_failed: this.user.payment_failed,
							eligible_for_trial: this.user.eligible_for_trial,
							canonical_url: this.user.canonical_url
						})
					}
					this.loading = false
					return response.data.value
				})
			} catch (err: any) {
				this.error = err
				this.loading = false
				return err
			}
		},
		/**
		 * Sets the use grid preference
		 *
		 * @param enable - Whether to use the grid or not
		 */
		setUseSongGrid(enable: boolean) {
			this.useSongGrid = enable
		},
		/**
		 * Toggles the use grid preference
		 */
		toggleUseSongGrid() {
			this.useSongGrid = !this.useSongGrid
		},
		/**
		 * Sets the user state
		 *
		 * @param payload - User object
		 */
		async setUser(payload: User | null) {
			this.user = await payload

			if (this.user) {
				const geolocStore = useGeoLocationStore()
				geolocStore.setGeoLocation(this.user.country)
			}
		},
		/**
		 * Sets the user's auto play preference
		 *
		 * @param autoPlay - Whether to auto play or not
		 */
		setAutoPlay(autoPlay: boolean) {
			this.autoPlay = autoPlay
			const autoPlayCookie = useCookie<boolean>('autoPlay', { maxAge: 365 * 24 * 60 * 60 })
			autoPlayCookie.value = autoPlay
		},
		getAutoPlay() {
			const autoPlayCookie = useCookie<boolean>('autoPlay')
			if (autoPlayCookie.value !== null && autoPlayCookie.value !== undefined) {
				this.autoPlay = autoPlayCookie.value
			} else if (!this.hasPremiumSub) {
				this.autoPlay = false
			} else {
				this.autoPlay = true
			}
			return this.autoPlay
		},
		/**
		 * Adds a favorite to the user's favorites
		 *
		 * @param favoriteGroup - The group (songs/artists/playlists) to add the favorite to
		 * @param favoriteId - The favorite's id
		 */
		addFavorite(favoriteGroup: string, favoriteId: number) {
			this.favorites[favoriteGroup].push(favoriteId)
		},
		/**
		 * Deletes a favorite from the user's favorites
		 *
		 * @param favoriteGroup - The group (songs/artists/playlists) to delete the favorite from
		 * @param favoriteId - The favorite's id
		 */
		deleteFavorite(favoriteGroup: string, favoriteId: number) {
			const index: number = this.favorites[favoriteGroup].indexOf(favoriteId)
			delete this.favorites[favoriteGroup][index]
		}
	}
})
