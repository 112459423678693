import { useNuxtApp } from '#imports'

export const useSongHistory = () => {
	const { $singaApi } = useNuxtApp()

	const songlistApiResource = $singaApi.Me.SongHistory.list

	const filter = ref('latest')

	const { loadSongs, results, loading, hasNextPage, initialLoad } = useSonglists({ songlistApiResource, params: { page_size: 10 } })

	/**
	 * Set the sort filter for the user's song history
	 *
	 * @param {string} filterId - The id of the sort filter that will be used to sort the user's song history
	 */
	const setSortFilter = (filterId: any) => {
		filter.value = filterId
	}

	return {
		songHistory: results,
		filter,
		setSortFilter,
		loadSongs,
		loading,
		hasNextPage,
		initialLoad
	}
}
