<template lang="pug">
div.lower-index
	div(v-if="!reportingView")
		span.settings-title {{ t('player.settings') }}
		.settings-row
			SingaField(horizontal :label="t('settings.version')")
				SingaButton.version-button.is-ghost(:disabled="!hasMultipleVariants" @click="changeVariant" :icon-right="hasMultipleVariants ? 'chevron-forward' : ''")
					span(v-if="firstInQueue") {{ firstInQueue.variant.catalog.name }}

		.settings-row.regular.setting-toggle(:class="{'disabled-demo-player': showDemoPlayer}")
			SingaField(horizontal :label="t('settings.vocals')")
				SingaToggle(v-if="hasVocals" v-model="settings.vocals" :disabled="showDemoPlayer" @update:modelValue="setVocals(settings.vocals)")
				span.na-text(v-else) {{ t('general.notAvailable') }}

		.settings-row.regular.setting-toggle(:class="{'disabled-demo-player': showDemoPlayer}")
			SingaField(horizontal :label="t('player.queueAutoPlay')")
				SingaToggle(v-model="autoPlay" @update:modelValue="setAutoPlay(autoPlay)" :disabled="showDemoPlayer || !hasPremiumSub")

		.settings-row(:class="{'disabled-demo-player': showDemoPlayer}")
			SingaField.small-uppercase(:label="t('settings.volume')")
				.volume-slider-wrap
					SingaVolumeSlider(v-model="volumeAmount" :disabled="showDemoPlayer")
					SingaIcon.volume-icon.volume-low(icon="volume-off" size="medium" v-if="volumeAmount < 10")
					SingaIcon.volume-icon(icon="volume-low" size="medium" v-if="volumeAmount < 50 && volumeAmount >= 10")
					SingaIcon.volume-icon(icon="volume-medium" size="medium" v-if="volumeAmount <= 75 && volumeAmount >= 50")
					SingaIcon.volume-icon(icon="volume-high" size="medium" v-if="volumeAmount > 75")
			SingaField.small-uppercase(grouped :label="t('settings.pitch')" v-if="firstInQueue")
				.number-input
					SingaButton.is-full-ghost(icon-left="remove" @click="decrementPitch()" :disabled="settings.pitch === -4 || showDemoPlayer")
					span {{ settings.pitch }}
					SingaButton.is-full-ghost(icon-left="add" @click="incrementPitch()" :disabled="settings.pitch === 4 || showDemoPlayer")

		span.settings-title.report-song-title(:class="{'disabled-demo-player': showDemoPlayer}") {{ t('songError.report.linkTitle') }}
		.settings-row(:class="{'disabled-demo-player': showDemoPlayer}")
			SingaField.report-song(horizontal :label="t('songError.report.subtitle')")
				SingaButton.is-icon.report-button(@click="reportingView = true" :icon-right="'chevron-forward'")
	
	div(v-else)
		.report-section-title
			SingaButton.is-icon.back-button(@click="reportingView = false" :icon-left="'arrow-back'" size="small")
			span.reporting-title {{ t('songError.report.mainTitle') }}

		.song-info
			span.song-and-artist {{ songAndArtist }}
			span.catalog-text {{ firstInQueue.variant.catalog.name }} - {{ firstInQueue.variant.catalog_code }}
		
		.checkboxes
			SingaCheckbox(:label="t('songError.report.wrongLyrics')" v-model="wrongLyricsError")
			SingaCheckbox(:label="t('songError.report.wrongAudio')" v-model="wrongAudioError")
			SingaCheckbox(:label="t('songError.report.lyricsAhead')" v-model="lyricsAheadError")
			SingaCheckbox(:label="t('songError.report.lyricsBehind')" v-model="lyricsBehindError")
			SingaCheckbox(:label="t('songError.report.start')" v-model="startError")
			SingaCheckbox(:label="t('songError.report.audioQuality')" v-model="audioQualityError")
			SingaCheckbox(:label="t('songError.report.other')" v-model="otherError")
		.comment-field.settings-row
			SingaInput.comment-input(ref="feedbackInputElem" :expanded="false" :placeholder="t('songError.report.comment')" v-model="songComment" type="textarea")
		.submit-button 
			SingaButton.is-primary.is-full-width.is-regular(@click="sendReport" type="submit" :disabled="!hasCheckedErrors") {{ t('songError.report.linkTitle') }}

	.demo-player-cta(v-if="showDemoPlayer")
		NuxtLink(v-if="!isMobile" :to="localePath('/login')")
			SingaButton.is-primary.is-regular {{ t('settings.demoPlayer.cta') }}
		SingaButton.is-primary.is-regular(v-else @click="appModal") {{ t('settings.demoPlayer.cta.download') }}
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'
import { useQueueStore } from '~/pinia/queueStore'
import { useSecondScreenStore } from '~~/pinia/secondScreen/secondScreenStore'

const DownloadApp = resolveComponent('ModalsDownloadApp')

const { t } = useI18n()

const { $audioPlayer, $oruga } = useNuxtApp()

const localePath = useLocalePath()

const { queueItemSelection } = useVariantSelection()
const secondScreenStore = useSecondScreenStore()
const mediaStore = useMediaFileStore()
const { nullifyFiles, setVocals } = useMediaFileStore()
const queueStore = useQueueStore()
const audioStore = useAudioPlayerStore()

const { settings } = storeToRefs(mediaStore)
const { firstInQueue, hasMultipleVariants } = storeToRefs(queueStore)
const { isAudioPlaying } = storeToRefs(audioStore)
const hasVocals = computed(() => firstInQueue?.value?.variant?.has_cover)

const { changeCurrentVariant } = queueStore
const { isSecondScreenActive } = storeToRefs(secondScreenStore)
const { setAutoPlay } = useUserStore()
const userStore = useUserStore()
const { showDemoPlayer, autoPlay, hasPremiumSub } = storeToRefs(userStore)

const { isMobile } = useDevice()

const reportingView = ref(false)
const lyricsError = ref(false)
const startError = ref(false)
const wrongLyricsError = ref(false)
const wrongAudioError = ref(false)
const lyricsAheadError = ref(false)
const lyricsBehindError = ref(false)
const audioQualityError = ref(false)
const otherError = ref(false)
const songComment = ref('')

const hasCheckedErrors = computed(() => {
	return lyricsError.value ||
	startError.value ||
	wrongLyricsError.value ||
	wrongAudioError.value || 
	lyricsAheadError.value ||
	lyricsBehindError.value ||
	audioQualityError.value ||
	otherError.value
})

const issues = computed(() => {
  const issuesList = []

  if (lyricsError.value) {
    issuesList.push('wrong_lyrics')
  }
  if (startError.value) {
    issuesList.push('song_does_not_start')
  }
  if (wrongAudioError.value) {
    issuesList.push('wrong_song')
  }
  if (wrongLyricsError.value) {
    issuesList.push('wrong_lyrics')
  }
  if (lyricsAheadError.value) {
    issuesList.push('lyrics_ahead')
  }
  if (lyricsBehindError.value) {
    issuesList.push('lyrics_behind')
  }
  if (audioQualityError.value) {
    issuesList.push('bad_audio_quality')
  }
  if (otherError.value) {
    issuesList.push('other')
  }

  return issuesList
})

const sendReport = async () => {
  const payload = {
    variant: firstInQueue.value.variant.id,
    issues: issues,
    description: songComment.value || undefined
  }
	const { $singaApi } = useNuxtApp()
	const { error } = await $singaApi.Songs.postError(payload)
	if (error.value) {
		$oruga.notification.open({ 
			message: t('songError.report.error'),
			position: 'bottom-right',
			variant: 'dark',
			icon: 'alert-circle-outline',
			iconSize: 'medium'
		})
	} else {
		$oruga.notification.open({
			message: t('songError.report.submitted'),
			position: 'bottom-right',
			variant: 'dark',
			icon: 'checkmark-outline',
			iconSize: 'medium'
		})
	}
	useEventEmit('player:closeSettings')
}

const songAndArtist = computed(() => {
	return `${firstInQueue.value.entry.name} - ${firstInQueue.value.entry.artists.map((artist) => artist.name).join(', ')}`
})

const appModal = () => {
	const { $audioPlayer } = useNuxtApp()
	$audioPlayer.pause()
	$oruga.modal.open({
		component: DownloadApp,
		scroll: 'keep'
	})
}

const volumeAmount = computed({
	get: () => settings.value.volume,
	set: async (value) => {
		settings.value.volume = value
		// set audioplayer volume
		const newVolume = value / 100.0
		if (!window.audioPlayer.initialized) {
			await new Promise(resolve => setTimeout(resolve, 200))
		}
		$audioPlayer.setVolume(newVolume)
	}
})

let startVolume: any = null

watch(volumeAmount, (newVolume, oldVolume) => {
	if (startVolume === null) {
		startVolume = oldVolume
	}
})

watchDebounced(
	volumeAmount,
	(newVolume) => {
		if (startVolume !== null) {
			const eventParams = {
				song_id: firstInQueue?.value?.entry.resource_id,
				popout_player: isSecondScreenActive.value,
				session_id: firstInQueue?.value?.entryID,
				previous_volume: startVolume,
				current_volume: newVolume
			}
			const { segmentEvent } = useSegment()
			segmentEvent('Player Playback Volume Adjustment Completed', eventParams)
			startVolume = null
		}
	},
	{ debounce: 300 }
)

watch(() => settings.value.volume, (newValue) => {
	volumeAmount.value = newValue
})

const decrementPitch = () => {
	settings.value.pitch = Math.max(settings.value.pitch - 1, -4)
}
const incrementPitch = () => {
	settings.value.pitch = Math.min(settings.value.pitch + 1, 4)
}

const changeVariant = async () => {
	const wasPlaying = isAudioPlaying.value
	$audioPlayer.pause()
	// A setManually parameter was added to enable opening the modal mid-song
	const previousVariantDetails = {
		catalog_name: firstInQueue?.value?.variant.catalog.name,
		resource_id: firstInQueue?.value?.variant.resource_id
	}
	const queueItem = await queueItemSelection(firstInQueue.value.entry, true, previousVariantDetails)
	if (!queueItem && wasPlaying) {
		$audioPlayer.play()
		return
	}
	if (queueItem?.id) {
		// Nullifying the files is done to ensure the change starts with a clean slate, including the visuals, lyrics and all
		nullifyFiles()
		changeCurrentVariant(queueItem)
		useEventEmit('player:startPlayer')
	}
}

watch(() => settings.value.pitch, async (newValue) => {
	if (firstInQueue.value) {
		useEventEmit('player:userUpdatedPitch', newValue)
		if (!isAudioPlaying.value) {
			mediaStore.setPitch(settings.value.pitch)
		}
	}
})
</script>

<style scoped lang="sass">
.volume-slider-wrap
	position: relative
	z-index: 0
	width: 100%
.volume-icon
	position: absolute
	left: 12px
	top: 0
	bottom: 0
	height: 100%
	pointer-events: none
	&:not(.volume-low)
		color: $color-grey-80
.second-screen-toggle-link
	margin: 0
	padding: $spacing-8 $spacing-16
.second-screen-toggle-icon-wrapper
	padding: $spacing-8 $spacing-16
.second-screen-toggle-icon
	display: flex
	color: $color-grey-50
	transition: .2s
	&:hover
		cursor: pointer
		color: $color-grey-70
.link-danger
	a
		color: $color-red-50
		&:hover
			color: $color-red-70
.volume-low
	color: $transparent-white-40
.settings-wrap
	@include backdropBlur(regular)
	.field.is-horizontal
		align-items: center
.settings-row
	background-color: $transparent-white-8
	margin: $spacing-8 0 $spacing-8 0
	padding: 14px 20px
	border-radius: $radius-default
	&:last-child
		margin-bottom: 0
	&.regular
		height: 52px
	&.setting-toggle
		.field
			display: flex
			height: 100%
		:deep(.field-label)
			margin-bottom: 0
	@media (min-width: $tablet)
			.field
				height: 100%
.number-input
	width: 100%
	height: 40px
	display: flex
	align-items: center
	justify-content: space-between
	background-color: $transparent-white-12
	border-radius: $radius-round
	button
		color: $transparent-white-40
		&:hover, &:focus, &:active
			color: white
	.button:focus:not(:active):first-child, .button.is-focused:not(:active):first-child
		border-radius: 100px 0 0 100px
	.button:focus:not(:active):last-child, .button.is-focused:not(:active):last-child
		border-radius: 0 100px 100px 0
.version-button[disabled]
	cursor: default
	border: none
	user-select: none
	&:hover, &:focus, &:active
		background-color: transparent
		text-decoration: none

.lower-index
	z-index: -10 !important
	position: relative

.na-text
	color: $color-grey-40
	padding: 0 $spacing-16 0 $spacing-16

.small-uppercase
	text-transform: uppercase
	// Don't know how to force font size to xxs

.settings-title
	color: $color-grey-30
	@include fontSize(xl)
	@include font(basier, semiBold)
	display: none
	padding-bottom: $spacing-8
	@media (min-height: 600px)
		display: block

.report-song-title
	padding: $spacing-8 0 $spacing-8 0

.reporting-title
	color: $color-grey-30
	@include fontSize(xl)
	@include font(basier, semiBold)
	padding-left: $spacing-8

.demo-player-cta
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	border-radius: $radius-default
	width: 100%
	gap: $spacing-16
	position: absolute
	bottom: 0
	padding: $spacing-16 $spacing-16 51px
	@media (min-height: 700px)
		position: relative
		padding: $spacing-16
.demo-cta-text
	@include font(basier, regular)
	@include fontSize(s)
	color: $color-grey-30
	text-align: center

.disabled-demo-player
	opacity: .3

.second-screen-playing
	// Don't know how to get this to work so that the playing text would not break to two lines
	:deep(.is-horizontal .field-label)
		flex: 2
		
.report-section-title
	display: flex
	align-items: center
	align-content: flex-start

.report-song
	display: flex
	flex-direction: row
	:deep(.label)
		width: 350px
	
.song-info
	color: $color-grey-30
	display: flex
	margin-left: 30px
	margin-top: $spacing-16
	flex-direction: column

.song-and-artist
	@include font(basier, medium)
	@include fontSize(s)
	display: block
	text-overflow: ellipsis
	white-space: nowrap
	min-width: 0
	overflow: hidden

.catalog-text
	text-transform: uppercase
	color: $color-grey-50
	@include fontSize(xxs)
	@include font(basier, medium)
	margin-top: $spacing-4

.checkboxes
	background-color: $transparent-white-8
	margin: $spacing-24 0 $spacing-8 0
	padding: $spacing-16 $spacing-32
	border-radius: $radius-default
	display: flex
	flex-direction: column
	gap: $spacing-8

:deep(.textarea)
	background: transparent
	border: none
	box-shadow: none
	padding: 0 $spacing-16 0 $spacing-16
	resize: none

.comment-field
	padding: $spacing-24 $spacing-24 $spacing-24 $spacing-16

:deep(.b-checkbox.checkbox .check)
	border: $color-grey-30 1px solid

.report-button
	width: 50px
</style>

<style lang="sass">
.settings-wrap
	.field-body
		.field
			display: flex
			justify-content: flex-end
</style>
