<template lang="pug">
.SingaSearchInput(:class="size")
	.search-right(v-if="searchString" @click.stop.prevent="clearSearch(true)")
		.esc {{ t('search.esc') }}
		SingaIcon(icon="close-outline" size="large")
	SingaField
		SingaInput(ref="input" rounded icon="search-outline" :placeholder="placeholderText" :aria-label="t('search.input')" v-model="searchString" @focus="onFocus" @click="inputInteractedWith = true; onFocus()" @keydown.esc="clearSearch(true)")
</template>

<script setup lang="ts">
import { nextTick } from 'vue'
import { storeToRefs } from 'pinia'

const searchStore = useSearchStore()
const { t } = useI18n()

const { getSearchTerm } = storeToRefs(searchStore)

const timeoutId = ref(-1)

const route = useRoute()
const inputInteractedWith = ref(false)

const props = defineProps({
	size: {
		type: String,
		default: '',
		required: false
	},
	placeholderText: {
		type: String,
		default: '',
		required: false
	},
	filterMode: {
		type: Boolean,
		default: false,
		required: false
	},
	queueMode: {
		type: Boolean,
		default: false,
		required: false
	},
	songSearch: {
		type: Boolean,
		default: false,
		required: false
	}
})

const searchString = (route.params.search && route.params.search.length > 0 && !props.queueMode) ? ref(route.params.search as string) : ref('')
const { placeholderText, filterMode, queueMode } = toRefs(props)

const input = ref()
const previousRoute = ref()
const localePath = useLocalePath()
const skipReroute = ref(false)

const emit = defineEmits(['filterChanged', 'focus', 'focusout'])

const clearFocus = () => {
	// Temporary hack (🤞) to prevent a warning from useRoute
	if (useNuxtApp()._processingMiddleware) {
		setTimeout(() => {
			emit('focusout')
		}, 50)
	} else {
		emit('focusout')
	}
	inputInteractedWith.value = false
}

watch(searchString, (value) => {
	if (timeoutId.value > -1) {
		clearTimeout(timeoutId.value)
		timeoutId.value = -1
	}
	timeoutId.value = window.setTimeout(() => {
		if (!filterMode.value) {
			if ((!queueMode.value || props.songSearch) && !skipReroute.value) {
				navigateTo({ path: localePath(`/search/results/${encodeURIComponent(value)}`) })
			} else {
				skipReroute.value = false
				emit('filterChanged', value)
			}
		} else {
			emit('filterChanged', value)
		}
	}, 1000)
})

watch(() => route.name, async (value) => {
	if (!value?.toString().includes('results')) {
		await nextTick()
		searchString.value = ''
		searchStore.searchTerm = ''
		skipReroute.value = true
		clearFocus()
	}
})

watch(() => getSearchTerm.value, () => {
	if (getSearchTerm.value) {
		searchString.value = getSearchTerm.value
	}
})

const onFocus = function () {
	if (!inputInteractedWith.value || searchString.value) { return }
	skipReroute.value = false
	if (!route.name?.toString().includes('search')) {
		previousRoute.value = route.fullPath
	}
	if (!route.path.startsWith('/search/') && !filterMode.value && !queueMode.value && !props.songSearch) {
		navigateTo({ path: localePath('/search/results/') })
	}
	emit('focus')
	inputInteractedWith.value = false
}

const clearSearch = function (focusout: boolean) {
	skipReroute.value = true
	if (focusout) {
		clearFocus()
	}
	searchString.value = ''
	if (props.songSearch) {
		return
	}
	navigateTo({ path: previousRoute.value ? previousRoute.value : localePath('/') })
	emit('filterChanged', '')
}

const clickedOutside = function (e: any) {
	if (input?.value?.$el !== null && input?.value?.$el.contains(e.target)) {
		emit('focus')
	} else {
		clearFocus()
	}
}

onUpdated(async () => {
	await nextTick()
	if (import.meta.client) {
		window?.addEventListener('click', clickedOutside)
	}
})

onBeforeUnmount(() => {
	if (import.meta.client) {
		window?.removeEventListener('click', clickedOutside)
	}
})
</script>

<style lang="sass" scoped>
.SingaSearchInput
	width: 100%
	position: relative
	height: 48px
	&:not(:focus-within)
		.esc
			display: none
	:deep(.control .icon)
		width: 32px
		height: 32px
		top: initial
		.base-icon
			width: 32px
			height: 32px
	:deep(input)
		border-color: transparent !important
		text-align: center
		@include font(basier, medium)
		padding-left: 48px !important
		height: 100%
.search-right
	display: flex
	align-items: center
	position: absolute
	right: 12px
	z-index: 1000
	height: 100%
	cursor: pointer
	.icon
		color: $color-grey-50
	.esc
		background-color: $transparent-white-8
		color: $transparent-white-40
		padding: 4px 10px
		border-radius: $radius-default
		margin-right: $spacing-16
		display: none
		@media (min-width: $tablet)
			display: block
.field, .control
	height: 100%

@media (min-width: $tablet)
	.SingaSearchInput:not(.is-small)
		height: 56px
</style>

<style lang="sass">
.SingaSearchInput
	@media (min-width: $tablet)
		input
			@include fontSize(m)
</style>
