import { useRoute } from '#imports'
import type { Playlist } from '~/types'

type Nullable<T> = T | null

export const usePlaylistLists = (songlistApiResource: any, params: any) => {
	const route = useRoute()

	const initialLoad: Ref<boolean> = ref(false)
	const results: Ref<Array<Playlist>> = ref([])
	const loading: Ref<boolean> = ref(false)
	const count: Ref<number> = ref(-1)
	const page: Ref<number> = ref(1)
	const nextPage: Ref<Nullable<string>> = ref('')
	const apiResource: Ref<Function> = ref(songlistApiResource)
	const filterString: Ref<string> = ref('')
	const hasNextPage = computed(() => nextPage.value !== null)
	const previousParams: Ref<any> = ref()

	const geoLocationStore = useGeoLocationStore()
	const { contentMarket } = storeToRefs(geoLocationStore)

	/**
	 * Set the API resource to use to fetch the list of playlist
	 *
	 * @param {Function} resource - The API resource to use to fetch the list of playlist
	 */
	const setPlaylistListResource = (resource: any) => {
		apiResource.value = resource
	}

	/**
	 * Reset the page, count, and nextPage refs to their initial values
	 */
	const resetPage = () => {
		page.value = 1
		count.value = -1
		nextPage.value = ''
	}

	/**
 	 * Reset the results ref to an empty array
 	 */
	const resetResults = () => {
		results.value = []
	}

	/**
	 * Load list of playlist from the API from the given API resource and set the results to the results ref
	 *
	 * @param {boolean} search - If true, reset the page and results
	 * @param {Object} query - Any additional query params to pass to the API request
	 * @throws {Error} - Error in the API request
	 */
	const loadPlaylists = async (search = false, query?: any, clearResults: boolean = false) => {
		if (!apiResource.value) { return }
		loading.value = true

		try {
			if (search) {
				resetPage()
				resetResults()
			}
			if (route.query.sort) { params.sort = route.query.sort }
			if (filterString.value) { params.search = filterString.value }
			params = { ...params, ...contentMarket.value, ...query, page: page.value }
			previousParams.value = params
			if (query?.page) { params.page = query.page }
			const { data } = await apiResource.value(params)

			nextPage.value = data.value.next
			initialLoad.value = true
			results.value = clearResults ? [...data.value.results] : [...results.value, ...data.value.results]
			count.value = data.value.count
			page.value++
			params.page++
		} catch (error) {
			console.log({ error })
			nextPage.value = null
		} finally {
			loading.value = false
		}
	}

	watch(() => contentMarket.value, async (value) => {
		results.value = []
		await loadPlaylists(previousParams.value.search, previousParams.value)
	})

	return {
		results, loading, filterString, count, nextPage, hasNextPage, loadPlaylists, initialLoad, setPlaylistListResource
	}
}
