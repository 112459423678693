import * as Sentry from '@sentry/vue'

export const useSentry = (t?: any) => {
	const initSentry = () => {
		const { public: { sentry } } = useRuntimeConfig()
		const router = useRouter()

		if (!sentry.dsn) {
			return
		}

		Sentry.init({
			release: 'consumer-nuxt@4.1.3',
			app: useNuxtApp().vueApp,
			dsn: sentry.dsn,
			environment: sentry.environment,
			integrations: [
				Sentry.browserTracingIntegration({ router }),
				Sentry.replayIntegration({
					maskAllText: false
				})
			],

			tracesSampleRate: 0.2, // Change in prod
			replaysSessionSampleRate: 0.005, // Change in prod
			replaysOnErrorSampleRate: 0.01 // Change in prod if necessary
		})
	}
	return {
		initSentry
	}
}
