<template lang="pug">
ModalsActionModal.song-credits-modal(@close="closeModal")
	template(v-slot:body)
		SongCredits.song-credits(:credits="credits")
</template>

<script setup lang="ts">
const emits = defineEmits(['close'])

const closeModal = () => {
	emits('close')
}

defineProps({
	credits: {
		type: Object,
		required: true
	}
})
</script>

<style lang="sass" scoped>
.song-credits-modal
	width: 100%
.song-credits
	background-color: $color-grey-90
	border-radius: $radius-default

:deep(.modal-body)
	padding: 0!important
	margin: 0!important

:deep(.modal-card-head)
	padding: 0!important
	margin: 0!important
</style>
