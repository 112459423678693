<template lang="pug">
.SongCredits(:class="{ 'is-standalone': standalone }")
	h1 {{ t('song.credits.title') }}
	.field(v-if="credits.artist")
		.field-title {{ t('song.credits.artist') }}
		.field-body {{ credits.artist }}
	.field(v-if="credits.composer")
		.field-title {{ t('song.credits.composer') }}
		.field-body {{ credits.composer }}
	.field(v-if="credits.originalLyrics")
		.field-title {{ t('song.credits.originalLyrics') }}
		.field-body {{ credits.originalLyrics }}
	.field(v-if="credits.translatedLyrics")
		.field-title {{ t('song.credits.translatedLyrics') }}
		.field-body {{ credits.translatedLyrics }}
	.field(v-if="credits.publisher")
		.field-title {{ t('song.credits.publisher') }}
		.field-body {{ credits.publisher }}
	.field(v-if="credits.copyright")
		.field-title {{ t('song.credits.copyright') }}
		.field-body {{ credits.copyright }}
</template>

<script setup lang="ts">
const { t } = useI18n()

defineProps({
	credits: {
		type: Object,
		required: true
	},
	standalone: {
		type: Boolean,
		default: false
	}
})
</script>

<style lang="sass" scoped>
.SongCredits
	margin: $spacing-16 0 $spacing-16 0
	display: flex
	flex-direction: column
	@include fontSize(l)
	@include font(basier, semiBold)
	&.is-standalone
		margin-top: 0
		color: $color-grey-30
		margin: 0

h1
	margin-bottom: $spacing-16
.field
	@include fontSize(s)
	@include font(basier, regular)
	border-bottom: .1px solid $color-grey-70
	padding-bottom: $spacing-16
	width: 100%
	max-width: 400px
	&.is-standalone
		max-width: 600px

.field-title
	color: $color-grey-60
.field-body
	color: $color-grey-20
</style>
