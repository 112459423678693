<template lang="pug">
.PasswordReset(ref="el")
	.auth-header
		p.auth-sub {{ t('auth.email.enter') }}
	.auth-body
		form.reset-password(@submit.prevent="resetPassword")
			SingaField(:message="emailError" messageClass="error" :label="t('auth.email.label')" labelFor="loginEmail")
				SingaInput.input-regular(
					ref="emailInputElem"
					rounded
					v-model="updatedUsername"
					:placeholder="t('auth.email.placeholder')"
					icon="mail"
					@input="updateUsername"
					id="loginEmail")
			SingaSubmitButton.is-full-width.is-transparent-dark.is-regular(native-type="submit" :isLoading="isResetLoginLoading" :buttonCTA="t('auth.password.reset')")
		.link
			a(@click="cancelLogin") {{ t('general.cancel') }}
</template>

<script setup lang="ts">
const { $singaApi } = useNuxtApp()
const isResetLoginLoading = ref(false)
const emailError = ref('')
const updatedUsername = ref('')
const el = ref<HTMLDivElement>()
const { t } = useI18n()

const props = defineProps({
	username: {
		type: String,
		default: ''
	}
})

const emit = defineEmits([
	'update-username',
	'reset-success',
	'reset-cancel',
	'update-height'
])

const resetPassword = async () => {
	isResetLoginLoading.value = true
	const { error } = await $singaApi.Me.Reset.Password.getResetToken(updatedUsername.value)
	if (error.value) {
		emailError.value = 'No account found'
		console.log(error)
	} else {
		emit('reset-success')
	}
	isResetLoginLoading.value = false
}

const updateUsername = () => {
	emailError.value = ''
	emit('update-username', updatedUsername.value)
}

const cancelLogin = () => {
	emit('reset-cancel')
}
const updateHeightOnResize = useDebounceFn(() => {
	emit('update-height', el.value?.offsetHeight)
}, 100)

onMounted(() => {
	window.addEventListener('resize', updateHeightOnResize)
	updatedUsername.value = props.username
	emit('update-height', el.value?.offsetHeight)
})

onUnmounted(() => {
	window.removeEventListener('resize', updateHeightOnResize)
})
</script>

<style lang="sass" scoped>
.reset-password
	margin-bottom: $spacing-16
.PasswordReset
	position: absolute
	left: 0
	right: 0
</style>
