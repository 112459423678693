<template lang="pug">
SingaButton.is-transparent-dark.back-button.is-regular(v-if="isDesktop && hasHistory" @click="router.back()")
	.btn-wrap
		SingaIcon(icon="arrow-back")
		span {{ t('general.goBack') }}
</template>

<script setup lang="ts">
const { t } = useI18n()
const { isDesktop } = useDevice()
const router = useRouter()
const hasHistory = router.options.history.state.back
</script>

<style lang="sass" scoped>
button
	color: $color-grey-30
	.btn-wrap
		display: flex
		align-items: center
</style>
