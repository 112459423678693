<template lang="pug">
.SongListItemRow
	.left
		.song-img
			nuxt-img(:src="image" width="48" height="48" quality="100")
		.song-info
			span.song-name {{ songName }}
			a.song-artist {{ artistString }}
	.right
		span.playing-text(:class="{'next-text': showNextInQueueText }") {{ queueText }}
		span.time-until-song(v-if="!isfirstInQueue") {{ timeUntilSong }}
	.buttons
		SingaIcon.move-icon(v-if="!isfirstInQueue" icon="move-outline" size="medium")
		SongContextMenu(
			:isQueueItem="true"
			:song="song.entry"
			:link="link"
			:is-list-item="false"
			:id="song.entry.id" layout="column"
			@set-active="setContextMenu(true)"
			@reset="setContextMenu(false)"
			accessPoint="Player queue item"
			)
		SingaButton.is-small.is-transparent-dark.add-to-queue(:icon-left="'trash-outline'" @click.stop.prevent="deleteFromQueue")
</template>

<script setup lang="ts">
import type { PropType } from '#imports'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'

const useImage = useGetImageSize()
const audioPlayerStore = useAudioPlayerStore()

const queueStore = useQueueStore()
const { t } = useI18n()

const { removeFromQueue } = queueStore

const hasStartedPlaying = computed(() => audioPlayerStore.currentSeconds > 0)
const showNextInQueueText = computed(() => !hasStartedPlaying.value)
const queueText = computed(() => hasStartedPlaying.value ? t('song.nowPlaying') : t('miniQueue.nextUp'))

const contextMenuOpen = ref(false)

const setContextMenu = (value: boolean) => {
	contextMenuOpen.value = value
	useEventEmit('queue:contextMenu', value)
}

const props = defineProps({
	song: {
		type: Object as PropType<QueueItem>,
		required: true
	},
	timeUntilSong: {
		type: String,
		default: '0'
	}
})

const isfirstInQueue = computed(() => {
	return queueStore.firstInQueue?.entryID === props.song.entryID
})

const songName = computed(() => props.song.entry.name)
const artistString = computed(() => {
	return props.song.entry.artists.map((n: Artist) => n.name).join(', ')
})
const artistSlug = props.song.entry.artists.map(artist => artist.slug).join(',')

const link = `/artists/${artistSlug}/${props.song.entry.slug}/${props.song.entry.hash}`

const image = computed(() => {
	return useImage(props.song.entry.image, 'tiny', 'song', true)
})

const deleteFromQueue = () => {
	removeFromQueue(props.song)
}
</script>

<style lang="sass" scoped>
.SongListItemRow
	padding: 16px
	display: inline-block
	border-radius: $radius-default
	width: 100%
	display: flex
	align-items: center
	justify-content: space-between
	position: relative
	&:not(.queue-playing)
		.playing-text
			display: none
	&.queue-playing
		.add-to-queue
			display: none
	&:not(:only-child):not(:last-child)
		&::after
			content: ''
			position: absolute
			bottom: 0
			left: 82px
			right: 0
			width: calc(100% - 80px)
			height: 1px
			background-color: $transparent-white-8
.left
	display: flex
	align-items: center
	justify-content: flex-start
.right
	display: flex
	align-items: center

.buttons
	display: none

.SongListItemRow
	&:hover, &:active, &:focus, &:focus-within
		background-color: $transparent-white-4
		.buttons
			gap: $spacing-16
			display: flex
			align-items: center
			margin-bottom: 2px

		.next-text
			display: none
		.time-until-song
			display: none
		.song-info
			width: 160px

:deep(.button.is-small)
	height: 40px
	padding: $spacing-4 $spacing-16
	width: 64px
	margin: 0

.song-img
	border-radius: $radius-default
	width: 48px
	height: 48px
	margin-right: $spacing-16
	background-color: $transparent-white-4
	overflow: hidden
	position: relative
	&:before
		content: ""
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: rgba($transparent-black-56, 0.5)
.song-name
	@include font(basier, medium)
	color: $color-grey-30
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis
	display: block
.song-artist
	color: $color-grey-60
	white-space: nowrap
	text-overflow: ellipsis
	min-width: 0
	overflow: hidden
	padding-right: $spacing-4
	display: block
button.is-small
	height: 40px
.song-info
	width: 250px
	padding-right: $spacing-16
.queue-playing
	user-select: none
	.song-name
		color: $primary
		@include fontSize(m)
		@include font(basier, medium)
	.song-artist
		color: $color-green-80
	.playing-text
		color: $primary

.next-text
	display: block
	color: $color-grey-60 !important

.move-icon
	color: $color-grey-30

.time-until-song
	width: 100%
	text-align: right
	display: flex
	align-items: right
	justify-content: flex-end
	@include fontSize(xs)
	color: $color-grey-60
</style>

<style lang="sass">
.SongListItemRow
	.like-song
		svg
			path
				stroke: $color-grey-30
		&:hover, &:active
			svg
				path
					fill: $color-grey-30
</style>
