import { defineStore } from 'pinia'

interface DiscoverStoreBlock {
	expanded: boolean
	items: any[]
}

interface DiscoverStore {
	songHistory: DiscoverStoreBlock
	popularGenres: DiscoverStoreBlock
	popularSongs: DiscoverStoreBlock
	mostSungSongs: DiscoverStoreBlock
	popularPlaylists: DiscoverStoreBlock
	topArtists: DiscoverStoreBlock
	recentlyAddedSongs: DiscoverStoreBlock
	countryPlaylists: DiscoverStoreBlock
}

export const useDiscoverStore = defineStore({
	id: 'discoverStore',
	state: (): DiscoverStore => {
		return {
			songHistory: { expanded: false, items: [] },
			popularGenres: { expanded: false, items: [] },
			popularSongs: { expanded: false, items: [] },
			mostSungSongs: { expanded: false, items: [] },
			popularPlaylists: { expanded: false, items: [] },
			topArtists: { expanded: false, items: [] },
			recentlyAddedSongs: { expanded: false, items: [] },
			countryPlaylists: { expanded: false, items: [] }
		}
	},
	actions: {
		clearAll() {
			this.songHistory.items = []
			this.popularGenres.items = []
			this.popularSongs.items = []
			this.mostSungSongs.items = []
			this.popularPlaylists.items = []
			this.topArtists.items = []
			this.recentlyAddedSongs.items = []
			this.countryPlaylists.items = []
		},
		setBlock(itemName: keyof DiscoverStore, value: DiscoverStoreBlock) {
			this[itemName] = value
		},
		setItems(itemName: keyof DiscoverStore, items: any[]) {
			this[itemName].items = items
		},
		setExpanded(itemName: keyof DiscoverStore, expanded: boolean) {
			this[itemName].expanded = expanded
		},
		getItem(itemName: keyof DiscoverStore) {
			return this[itemName]
		},
		setFirstItem(itemName: keyof DiscoverStore, item: any) {
			this[itemName].items.unshift({ song: item })
		}
	}
})
