import { useAudioPlayerStore } from '../pinia/player/audioPlayerStore'
import { useQueueStore } from '~~/pinia/queueStore'
import { useSecondScreenStore } from '~~/pinia/secondScreen/secondScreenStore'
import { initAudioPlayer } from '~/player/audioplayer.js'

declare global {
	interface Window { audioPlayer: any }
}
declare module '#app' {
	interface NuxtApp {
		$audioPlayer: any
	}
}
initAudioPlayer(window)

export default defineNuxtPlugin({
	enforce: 'post',
	parallel: true,
	setup() {
		console.log('IN PLUGIN', window.audioPlayer)
		const audioPlayerStore = useAudioPlayerStore()
		const secondScreenStore = useSecondScreenStore()

		window.audioPlayer.events.addEventListener('pause', function (event: any) {
			console.log('PAUSE')
			audioPlayerStore.setPlaying(false)
		})

		window.audioPlayer.events.addEventListener('play', function (event: any) {
			console.log('PLAY')
			if (window.location.href) {
				audioPlayerStore.setPlaying(true)
			}
		})

		window.audioPlayer.events.addEventListener('ended', async function (event: any) {
			console.log('ENDED')
			const queueStore = useQueueStore()
			await queueStore.nextSong()
		})

		window.audioPlayer.events.addEventListener('loadedmetadata', function (event: any) {
			console.log('loadedmetadata in audio', window.audioPlayer.getDuration())
			audioPlayerStore.setSongDuration(window.audioPlayer.getDuration())
		})

		window.audioPlayer.events.addEventListener('frequenttimeupdate', function (timeStamp: any) {
		})

		window.audioPlayer.events.addEventListener('timeupdate', function (timeStamp: any) {
			const audioPlayerStore = useAudioPlayerStore()
			audioPlayerStore.updateCurrentSeconds(timeStamp)
		})

		window.audioPlayer.events.addEventListener('seeked', function (event: any) {
			useEventEmit('player:seeked', event)
		})

		window.audioPlayer.events.addEventListener('waiting', function () {
			const { isAudioPlaying, roundedDuration } = useAudioPlayerStore()
			if (isAudioPlaying && roundedDuration) {
				const { segmentEvent } = useSegment()

				segmentEvent('Player Playback Buffer Started', {
					song_id: useQueueStore().firstInQueue?.entry.resource_id,
					popout_player: useSecondScreenStore().isSecondScreenActive,
					position: roundedDuration,
					session_id: useQueueStore().firstInQueue?.entryID
				})
			}
		})

		window.audioPlayer.events.addEventListener('playing', function () {
			const { isAudioPlaying, roundedDuration } = useAudioPlayerStore()
			if (isAudioPlaying && roundedDuration) {
				const { segmentEvent } = useSegment()
				segmentEvent('Player Playback Buffer Started', {
					song_id: useQueueStore().firstInQueue?.entry.resource_id,
					popout_player: useSecondScreenStore().isSecondScreenActive,
					position: roundedDuration,
					session_id: useQueueStore().firstInQueue?.entryID
				})
			}
		})

		window.onbeforeunload = function () {
			secondScreenStore.commandPlayer('finished')
		}

		return {
			provide: {
				audioPlayer: window.audioPlayer
			}
		}
	}
})
