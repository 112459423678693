import { createApiWrapper } from '../utils/apiUtils'

declare module '#app' {
	interface NuxtApp {
		$singaApi: any
	}
}
export default defineNuxtPlugin({
	enforce: 'pre',
	parallel: true,
	setup() {
		return {
			provide: {
				singaApi: createApiWrapper()
			}
		}
	}
})
