<template lang="pug">
.player-wrap
	.player-content(:class="[{'fullscreen': isFullscreen}, {'idle': idle}, { 'player-visible': showPlayer }, { 'player-transition': !showPlayer && !playerFullyHidden}, { 'player-hidden': playerFullyHidden }]")
		.feedbacks
			ControlFeedbackPopup(v-for="feedback in feedbacks" :key="feedback.id" @finished="removeFeedback(feedback.id)" :position="feedback.position" :label="feedback.label" :icon="feedback.icon")
		.secondscreen-toggle(v-if="isDesktop")
			SingaTooltip.tooltip(v-if="!isFullscreen || isFullscreen && !idle" :label="t('settings.secondScreen.open')" override="true" active="true" position="bottom" variant="tooltip")
				SingaButton.secondscreen-button.is-transparent-dark(icon-left="open-outline" @click="toggleSecondScreen()")
		.fullscreen-toggle(v-if="!isMobile")
			SingaButton.fullscreen-button.is-transparent-dark(v-if="!isFullscreen || isFullscreen && !idle" @click="toggle()" :icon-left="isFullscreen ? 'contract' : 'expand'")
		SingaButton.is-regular.is-transparent-dark.minimize-player(
			@click="togglePlayer()"
			icon-left="chevron-down"
			v-if="!isFullscreen && !isMobile"
		) {{ t('search.minimize') }}
		SingaButton.mobile-minimize(
			@click="togglePlayer()"
			icon-left="chevron-down"
			v-if="!isFullscreen && isMobile"
		)
		.preview-player(v-if="showDemoPlayer" :class="{'preview-mobile': isMobile}")
			SingaLogo.demo-logo
			.preview-text {{  t('player.disclaimer.previewPlayer') }}

		.search-wrapper(v-if="!firstInQueue")
			.search-input
				SearchInput(:placeholderText="t('general.whatToSing')")
			ol.columns.is-mobile.is-multiline.song-items
				SongListItem.column.is-4-mobile.is-3-tablet.is-one-fifth-desktop(:key="result.id" v-for="result in results" layout="column" :song="result")
		.player-wrapper(ref="playerRefDiv" v-show="hasSongPlaying" @click="togglePause()")
		.song-starting(v-if="nowPlaying" v-show="!hasSongPlaying")
			TransitionGroup(name="fade")
				span.countdown-text(v-if="countdownActive")  {{ t('player.startsIn', { countdown: countdownTime }) }}
				span.countdown-text(v-else)  {{ t('player.nextUp') }}
			nuxt-img.song-starting-img(:src="nowPlaying.image" width="240" height="240" quality="100")
			span.song-title {{ nowPlaying.songName }}
			span.song-artists {{ nowPlaying.artistString }}

	SingaButton.is-primary.demo-cta-button(v-if="showDemoPlayer && isMobile" @click="appModal") {{  t('button.demoPlayer.cta') }}
	PlayerMiniPlayer(v-if="!isFullscreen || (isFullscreen && !idle)" :class="{'fullscreen': isFullscreen}" @togglePlayerVisibility="togglePlayer()")
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useIdle } from '@vueuse/core'
import ControlFeedbackPopup from './ControlFeedbackPopup.vue'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'
import { useQueueStore } from '~/pinia/queueStore'
import { useMediaFileStore } from '~/pinia/mediaFileStore'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'
import { useSecondScreenStore } from '~~/pinia/secondScreen/secondScreenStore'

const DownloadApp = resolveComponent('ModalsDownloadApp')

// The time in milliseconds after which the miniplayer is hidden in fullscreen mode
const IDLE_TIME = 5000
const { idle } = useIdle(IDLE_TIME)

const { $audioPlayer, $oruga } = useNuxtApp()
const mediaFileStore = useMediaFileStore()
const audioPlayerStore = useAudioPlayerStore()
const queueStore = useQueueStore()
const secondScreenStore = useSecondScreenStore()
const userStore	= useUserStore()
const { showDemoPlayer } = storeToRefs(userStore)

interface Feedback {
	id: number
	label: string
	icon: string
	position: string
	finished: boolean
}
const feedbacks = ref([] as Feedback[])
const feedbackCount = ref(0)

const { t } = useI18n()
const { hasPremiumSub, hasFreeSongsLeft } = storeToRefs(userStore)
const hasPremiumOrFreeSongs = computed(() => hasFreeSongsLeft || hasPremiumSub)

const { getSongMedia, fetchAudio } = mediaFileStore

const { startPlayerInSecondScreen, sendMessageToSecondScreen } = secondScreenStore
const { isSecondScreenActive, playerState } = storeToRefs(secondScreenStore)

const { $singaApi } = useNuxtApp()
const { allContentLoaded, images, lyrics, audioFileByType } = storeToRefs(mediaFileStore)

const { firstInQueue, hasSongPlaying, hasSongs } = storeToRefs(queueStore)

const karaokeStore = usePlayerStore()
const { showPlayer, playerLoading } = storeToRefs(karaokeStore)
const { setShowPlayer } = karaokeStore

const nowPlaying = computed(() => firstInQueue.value ? useSongProperties(firstInQueue.value.entry) : null)

const singaPlayer = useSingaPlayer()
const { setupPlayer, playerRefDiv, player: karaokePlayer } = singaPlayer

const { saveCurrentStateBeforeRestart, pauseAudioPlayer, resumeAudioPlayer } = audioPlayerStore
const { beforeRestart, isAudioPlaying, settingsOpened } = storeToRefs(audioPlayerStore)

const params = ref({ page: 1, page_size: 5, sort: 'hot' })
const songlistApiResource = $singaApi.Songs.list
const { loadSongs, results } = useSonglists({ songlistApiResource, params: { ...params.value } })

const audioTimeout = ref(-1)

const route = useRoute()

const { isMobile, isDesktop } = useDevice()

const appModal = () => {
	const { $audioPlayer } = useNuxtApp()
	$audioPlayer.pause()
	$oruga.modal.open({
		component: DownloadApp,
		scroll: 'keep'
	})
}

const playerFullyHidden = ref(false)

const togglePlayer = () => {
	if (showPlayer.value) {
		console.log('Hiding player')
		setShowPlayer(false)
		setTimeout(() => { playerFullyHidden.value = true }, 400)
	} else {
		console.log('Showing player')
		playerFullyHidden.value = false
		setTimeout(() => { setShowPlayer(true) }, 10)
	}
}

let countdownClock: any
const countdownTime = ref(queueStore.countdownTime)
const countdownActive = ref(false)
const { isFullscreen, toggle, exit } = useFullscreen()

const resetCountdown = () => {
	countdownTime.value = queueStore.countdownTime
	countdownActive.value = false
}

const countdownTimer = () => {
	if (countdownTime.value > 1) {
		countdownTime.value -= 1
	} else {
		// Reset the countdown after a second has passed to prevent reset from showing on the timer
		setTimeout(() => {
			clearInterval(countdownClock)
			resetCountdown()
		}, 1000)
	}
}

const startCountdown = () => {
	if (import.meta.server) return

	resetCountdown()
	countdownActive.value = true
	countdownClock = setInterval(() => countdownTimer(), 1000)
}

const startPlayer = (exitSecondScreen = false, countdown = false, bypass = false) => {
	// Check for mic permission before starting the player. Disabled for now
	// setupMediaStream()

	if (hasPremiumOrFreeSongs.value || showDemoPlayer.value) {
		setupPlayer({
			images: toRaw(images.value.content),
			audio: toRaw(audioFileByType.value),
			lyrics: toRaw(lyrics.value.content),
			song: toRaw(firstInQueue.value)
		} as any, false, exitSecondScreen, countdown, bypass)
		if (!showPlayer.value) {
			setShowPlayer(true)
		}
	} else {
		openFreemiumModal()
	}
}

const togglePause = async () => {
	if (settingsOpened.value) {
		return
	}
	if (isAudioPlaying.value) {
		await pauseAudioPlayer(true)
		// This GET is here to ensure that the number of free songs is updated in case the user was playing their last free song
		// Otherwise the user could pause and click to go to the next song causing an error
		if (!hasPremiumSub.value && !showDemoPlayer.value) {
			await userStore.getUser()
		}
		if (isSecondScreenActive.value) {
			sendMessageToSecondScreen({ method: 'MANUAL_AUDIO_TOGGLE', paused: true })
		}
	} else {
		if (!allContentLoaded.value && (hasPremiumSub.value || hasPremiumOrFreeSongs.value)) {
			playerLoading.value = true
			useEventEmit('player:startPlayer')
		// hasSongPlaying is there so that the user could continue a paused song even if it's the last free one
		} else if (hasPremiumOrFreeSongs.value || hasSongPlaying.value) {
			resumeAudioPlayer()
		} else {
			openFreemiumModal()
		}
		if (isSecondScreenActive.value) {
			await sendMessageToSecondScreen({ method: 'MANUAL_AUDIO_TOGGLE', paused: false })
		}
	}
}

const resumeAudioIfNecessary = (dontSeek = false) => {
	if (beforeRestart.value && allContentLoaded.value && !dontSeek) {
		$audioPlayer.seek(beforeRestart.value.timeStamp)
		$audioPlayer.play()
	} else if (beforeRestart.value && allContentLoaded.value && dontSeek) {
		$audioPlayer.play()
	}
	saveCurrentStateBeforeRestart(true)
}

const audioChangePipeline = async (cb: any, dontSeek = false) => {
	saveCurrentStateBeforeRestart(false)
	await cb()
	if (!dontSeek) {
		resumeAudioIfNecessary()
	} else {
		resumeAudioIfNecessary(true)
	}
}

const toggleSecondScreen = async () => {
	const { setShowPlayer, setLoadingState } = karaokeStore
	const { closeConnection, openPopoutWindow } = secondScreenStore
	const { hasConnection } = storeToRefs(secondScreenStore)

	if (showDemoPlayer.value) {
		return
	}
	if (isAudioPlaying.value) {
		setLoadingState(true)
	}

	// If player is in fullscreen, close it. Then set a timeOut of 600ms to stop clash with the popout window
	if (document.fullscreenElement) {
		useEventEmit('player:closeFullscreen')
	}
	const timeOut = document.fullscreenElement ? 600 : 0

	window.audioPlayer.events.removeEventListener('ended', 'AUTOPLAY_ENDED_LISTENER')
	if (hasConnection.value) {
		await closeConnection().then(() => {
			$audioPlayer.pause()
			setShowPlayer(true)
		})
	} else {
		await pauseAudioPlayer()
		setTimeout(async () => {
			await openPopoutWindow().then((result) => {
				if (result) {
					setShowPlayer(false)
					window.focus()
				} else if (isAudioPlaying) {
					resumeAudioPlayer()
				}
			})
		}, timeOut)
	}
}

/* ***************
*** WATCHERS ***
************** */

watch(() => route.name, (value) => {
	if (route.params.search) {
		setShowPlayer(false)
	}
})

watch(isSecondScreenActive, () => {
	if ((playerState && playerState.value === 'interrupted') || !hasSongs.value) {
		return
	}
	if (!isSecondScreenActive.value || karaokePlayer.value === null) {
		startPlayer(true, false, true)
	}
})

// if settings pitch changes, wait and then update the audio and restart the player from the correct time
let isFetchingAudio = false
useEventOn('player:userUpdatedPitch', (pitch) => {
	if (audioTimeout.value > -1) {
		clearTimeout(audioTimeout.value)
		audioTimeout.value = -1
	}
	playerLoading.value = true

	audioTimeout.value = window.setTimeout(() => {
		audioChangePipeline(async () => {
			if (isFetchingAudio) {
				return
			}
			isFetchingAudio = true
			const songEntry = firstInQueue.value
			if (!songEntry) {
				console.error('PlayerElement: No song entry')
				return
			}
			const variant = songEntry.variant
			const songId = songEntry.entry.id
			const variantId = variant.id
			await fetchAudio(songId, variantId, pitch)
			await $singaApi.Me.Preferences.put(firstInQueue.value.variant.id, { pitch: pitch })
			playerLoading.value = false
			isFetchingAudio = false
		}, false)
	}, 1000)
})

// if audio file type changes (from karaoke to cover or vise-versa), update the audio and restart the player
useEventOn('player:userUpdatedVocals', () => {
	if (audioFileByType.value) {
		audioChangePipeline(async () => {
			await $audioPlayer.load(audioFileByType.value, 'karaoke')
		})
	}
})

// Initiate starting the player, and send a parameter to start a countdown if necessary
const startPlayerSetup = (countdown = false, bypass = false) => {
	if (isSecondScreenActive.value) {
		startPlayerInSecondScreen(countdown)
	} else {
		startPlayer(false, countdown, bypass)
	}
}

const createFeedback = async (label: string, icon: string, position: string) => {
	const feedbackId = feedbackCount.value + 1
	feedbackCount.value++

	const newFeedback = { id: feedbackId, label: label, icon: icon, position: position, finished: false }
	feedbacks.value.push(newFeedback)
}
const removeFeedback = (feedbackId: number) => {
	feedbacks.value = feedbacks.value.filter(feedback => feedback.id !== feedbackId)
}
/* ***************
**** EVENTS  ****
************** */

useEventOn(('playerEvent:pause'), async () => {
	createFeedback('', 'pause', 'center')
})

useEventOn(('playerEvent:resume'), async () => {
	createFeedback('', 'play', 'center')
})

useEventOn(('player:skipAudioPlayer'), async ({ seconds }) => {
	createFeedback(`${seconds} sec`, 'play-forward-outline', 'right')
})

useEventOn(('player:rewindAudioPlayer'), async ({ seconds }) => {
	createFeedback(`${seconds} sec`, 'play-back-outline', 'left')
})

useEventOn(('player:changeVolume'), async ({ oldVolume, volume }) => {
	createFeedback(`${volume}%`, 'volume-medium-outline', 'bottom')
	if (oldVolume === volume) {
		return
	}
	const eventParams = {
		song_id: firstInQueue?.value?.entry.resource_id,
		popout_player: isSecondScreenActive.value,
		session_id: firstInQueue?.value?.entryID,
		previous_volume: oldVolume,
		current_volume: volume
	}
	const { segmentEvent } = useSegment()
	segmentEvent('Player Playback Volume Adjustment Completed', eventParams)
})

useEventOn('player:countdown', () => {
	startCountdown()
})

useEventOn('player:seekAudioPlayer', ({ timeStamp, clear }) => {
	// seekSingaPlayer(timeStamp, clear)
	if (isSecondScreenActive) {
		sendMessageToSecondScreen({ method: 'SEEK_PLAYER', timeStamp })
	}
})

useEventOn('player:startPlayer', async (event = {}) => {
	if (playerFullyHidden.value) {
		togglePlayer()
	}
	const countdown = ref(event.countdown !== undefined ? event.countdown : false)
	const bypass = ref(event.bypass !== undefined ? event.bypass : false)
	if (hasPremiumOrFreeSongs.value || showDemoPlayer.value) {
		await getSongMedia(firstInQueue.value)
	}
	// Add song that is about to play to the history
	queueStore.addToQueueHistory()

	if (!karaokeStore.playerLoading) {
		playerLoading.value = true
		if (!isSecondScreenActive.value) {
			showPlayer.value = true
		}
	}
	startPlayerSetup(countdown.value, bypass.value)
})

useEventOn('player:closeFullscreen', () => {
	exit()
})

await loadSongs(false, { ...params.value })

onBeforeUnmount(() => {
	useEventOff('player:userUpdatedPitch')
	useEventOff('player:userUpdatedVocals')
	useEventOff('playerEvent:pause')
	useEventOff('playerEvent:resume')
	useEventOff('player:skipAudioPlayer')
	useEventOff('player:rewindAudioPlayer')
	useEventOff('player:changeVolume')
	useEventOff('player:countdown')
	useEventOff('player:seekAudioPlayer')
	useEventOff('player:startPlayer')
	useEventOff('player:closeFullscreen')
})

// Clip scrolling when the player is full screen
useHead({
	htmlAttrs: {
		class: computed(() => {
			return showPlayer.value ? 'is-clipped' : ''
		})
	}
})
</script>

<style lang="sass" scoped>
.player-wrapper
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	height: 100%
	:deep(canvas)
		max-width: 100% !important

.search-wrapper
	display: flex
	flex-wrap: wrap
	align-items: center
	justify-content: center
	width: 100%
	max-width: 1000px
	margin-right: $spacing-24
	margin-left: $spacing-24

.search-input
	width: 100%
	@media (max-width: $tablet)
		margin-top: 25%

.song-items
	margin-top: $spacing-32
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	@include until($mobile-small)
		.column
			width: 50%
			&:not(:nth-child(-n+2))
				display: none
	@include until($tablet)
		.column:not(:nth-child(-n+3))
			display: none
	@include until($desktop)
		.column:not(:nth-child(-n+4))
			display: none

.player-wrap
	display: grid
	grid-template-columns: 1fr
	grid-template-rows: 1fr 88px
	gap: 0px 32px
	grid-template-areas: "." "PlayerMiniPlayer"
	bottom: 0

.PlayerMiniPlayer
	grid-area: PlayerMiniPlayer
	width: 100%

.player-content
	display: flex
	align-items: center
	background-color: black
	justify-content: center
	background: black
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 88px
	transition: opacity 200ms, visibility 200ms, transform 400ms
	&.fullscreen
		bottom: 0
	.song-starting
		text-align: center
		position: relative
	.song-starting-img
		width: 240px
		height: auto
		border-radius: $radius-default
		margin: $spacing-24 auto
	.countdown-text
		display: block
		color: $color-grey-50
		@include fontSize(l)
		@include font(basier, medium)
		margin: 0 auto $spacing-8
		position: absolute
		width: 100%
		top: -$spacing-16
	.song-title
		display: block
		color: $color-grey-30
		@include font(basier, bold)
		@include fontSize(3xl)
		margin-bottom: $spacing-8
	.song-artists
		display: block
		@include font(basier, medium)
		@include fontSize(l)
		color: $color-grey-50
.player-visible
	opacity: 1
	visibility: visible
	transform: translateY(0)
.player-transition
	opacity: 0
	visibility: hidden
	transform: translateY(100%)
.player-hidden
	display: none
.minimize-player
	position: absolute
	top: $spacing-32
	left: $spacing-24

.mobile-minimize
	position: absolute
	top:	20px
	left: 20px
	width: 44px !important
	height: 44px !important
	border-radius: $radius-default
	background-color: $color-grey-70
	border: none

.playerSlide-enter-active, .playerSlide-leave-active
	transition: all 0.3s
	transform: translateY(0px)

.playerSlide-enter-from
	opacity: 0
	transform: translateY(5%)
.playerSlide-leave-to
	opacity: 0
	transform: translateY(50%)

.fullscreen-toggle
	position: absolute
	right: $spacing-24
	top: $spacing-32
	z-index: 10000
	display: none
	@media (min-width: $tablet)
		display: block
	.fullscreen-button
		height: 48px
		width: 64px
		border-radius: $radius-default

.MiniPlayer
	&.fullscreen
		background-color: $transparent-black-84

.preview-text
	z-index: 1000
	@include font(basier, medium)
	@include fontSize(4xl)
	color: white
	letter-spacing: 10px !important
	text-transform: uppercase
	margin: 54px 0 0 0
	padding: 0
	width: 100%

.preview-player
	display: block
	position: absolute
	text-align: center
	top: $spacing-32
	&:not(.preview-mobile)
		text-indent: 10px
		direction: ltr

.preview-mobile
	.demo-logo
		display: none
	.preview-text
		@include fontSize(s)
		letter-spacing: 1px  !important
		margin: 0

.demo-cta-button
	position: absolute
	bottom: 120px
	max-width: 140px
	height: 28px
	margin: 0
	text-align: center
	border-radius: $radius-default
	left: 50%
	transform: translateX(-50%)
	@include fontSize(xs)
	box-shadow: $shadow-default

.is-clipped
	overflow: hidden
</style>

<style lang="sass">
.secondscreen-toggle
	position: absolute
	right: 96px
	top: $spacing-32
	z-index: 10000

	.secondscreen-button
		height: 48px
		width: 64px
		border-radius: $radius-default
</style>
