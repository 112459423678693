import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import legacy_45payments_45global from "/app/middleware/legacyPayments.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  legacy_45payments_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-guard": () => import("/app/middleware/authGuard.ts"),
  "authed-root-guard": () => import("/app/middleware/authedRootGuard.ts"),
  "dynamic-transitions": () => import("/app/middleware/dynamicTransitions.ts")
}