<template lang="pug">
.SongVersions
	header.modal-card-head
		p.version-header {{ t('songVersions.select') }}
		button.delete(type="button" @click="$emit('close')")
	.modal-card-body
		.versions-wrap
			SingaCarousel.versions-selector(v-if="!loading" :items-to-show="carouselItems" :items-to-list="carouselItems" :indicator="false" :arrowHover="false" iconNext="chevron-forward-outline" iconPrev="chevron-back-outline")
				SingaCarouselItem.version-wrap(v-for="version in sortedVersionsWithPreferences" :key="version.id" @click="closeSongVersions(version)")
					.version(:class="{'original': version.is_original, 'is-selected': isSelectedVersion(version)}")
						template(v-if="version.is_original")
							.version-original {{ t('songVersions.original') }}
						.version-popular(v-if="isMostPopularVersion(version)") {{ t('songVersions.popular') }}
						.version-selected
							SingaIcon.version-checkmark(v-if="isSelectedVersion(version)" size="medium" icon="checkmark-circle")
						span.publisher {{ version.catalog.name }}
						.version-tags(v-if="version.tags.length > 0")
							span.tag(v-for="tag in version.tags.filter((t:string) => t !== 'Original')" :class="{'is-selected': isSelectedVersion(version)}" :key="tag" v-show="tag") {{tag}}
					.version-preview
						SingaTooltip(:label="t('songVersions.disabled')" :active="playing" position="bottom" variant="link2")
							SingaButton.is-small.is-full-width.preview-button(v-if="!isCurrentlyPlaying(version)" @click.stop="previewVersion(version)" :disabled="playing")
								span {{ t('song.preview') }}
							SingaProgressBar(v-if="isCurrentlyPlaying(version)" @click.stop="previewVersion(version)" :completed="progress")
								span {{ t('song.preview') }}

		.carousel-footer
			.version-preference(v-if="!showDemoPlayer")
				.preference-left
					span.preference-title {{ t('songVersions.preferAutoSelect') }}
					span.preference-body {{ t('songVersions.preferAutoSelect.details') }}
				.preference-right
					SingaToggle(v-model="versionAutoselect" @update:modelValue="setAutomaticSelection(versionAutoselect)" :disabled="loading")
			.original-inprint(v-if="hasOriginal")
				span {{ t('songVersions.original.details') }}
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import type { PropType } from 'vue'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'
import { usePreviewPlayerStore } from '~/pinia/player/previewPlayerStore'
import { useVariantStore } from '~/pinia/variantStore'

const { $singaApi, $previewPlayer, $oruga } = useNuxtApp()
const { contentMarket } = useGeoLocationStore()
const { t } = useI18n()
const versionStore = useVariantStore()
const { versionAutoselect } = storeToRefs(versionStore)

const loading = ref(false)
const preferences = ref(null as any)
const carouselItems = ref(0)

const { playPreview, stopPreview, resetPreview, currentlyPlayingVersion, progress } = useVariantPreview()
const audioPlayerStore = useAudioPlayerStore()
const previewPlayerStore = usePreviewPlayerStore()

const { playing } = storeToRefs(audioPlayerStore)
const { playing: isPreviewPlaying } = storeToRefs(previewPlayerStore)
const queueStore = useQueueStore()
const { firstInQueue } = storeToRefs(queueStore)
const userStore = useUserStore()
const { showDemoPlayer } = storeToRefs(userStore)

const isSelectedVersion = (version: Variant) => {
	return version.id === firstInQueue.value?.variant?.id
}

const isCurrentlyPlaying = (version: Variant) => {
	return currentlyPlayingVersion.value === version.id
}

const previewClicked = ref(false)

const props = defineProps({
	versions: {
		required: true,
		type: Array as PropType<any[]>
	},
	song: {
		required: true,
		type: Object as PropType<any>
	}
})

const versionsWithTags = computed(() => {
	return props.versions.map((n: any) => {
		return {
			...n,
			tags: [
				n.is_duet ? t('tags.duet') : undefined,
				n.is_explicit ? t('tags.explicit') : undefined,
				n.is_original ? t('tags.original') : undefined,
				n.is_plus ? 'Plus' : undefined,
				n.has_cover ? t('tags.vocals') : undefined
			].filter(Boolean)
		}
	}).filter((version: any) => !!version && version.available_markets.includes(contentMarket.market))
})

const hasOriginal = computed(() => versionsWithTags.value.find((item: any) => item.tags[2] === 'Original'))
let mostPopularVersion: any

const sortVersions = (versions: any[]) => {
	// Determine most popular version overall
	mostPopularVersion = versions.reduce((prev, current) => {
		return (!prev || parseFloat(current.popularity) > parseFloat(prev.popularity)) ? current : prev
	}, null)

	// Sort for the selector
	return versions.sort((a, b) => {
		if (a.is_original !== b.is_original) {
			return a.is_original ? -1 : 1
		}
		const popularityA = a.popularity === null ? -1 : a.popularity
		const popularityB = b.popularity === null ? -1 : b.popularity
		if (popularityB === popularityA) {
			// Sort alphabetically by name if popularity is the same
			return a.catalog.name.localeCompare(b.catalog.name)
		}
		return popularityB - popularityA
	})
}

const sortedVersions = computed(() => sortVersions(versionsWithTags.value.slice()))

const sortedVersionsWithPreferences = computed(() => {
	if (preferences.value === null) {
		return sortedVersions.value
	}
	const variantsWithPreferences = versionsWithTags.value.map(variant => ({
		...variant,
		user_preferences: preferences.value?.find((preference: any) => preference.variant === variant.id) || {
			pitch: 0
		}
	}))
	return sortVersions(variantsWithPreferences)
})

const isMostPopularVersion = computed(() => (version: any) => version.id === mostPopularVersion.id)

const previewVersion = (songVersion: Variant) => {
	previewClicked.value = true
	if (!currentlyPlayingVersion.value || songVersion.id !== currentlyPlayingVersion.value) {
		playPreview(songVersion, { id: props.song.id, name: props.song.name }, 'Song version selector')
	} else {
		stopPreview()
	}
}

watch(isPreviewPlaying, (newValue: any) => {
	if (!newValue) {
		resetPreview()
	}
})

const setCarouselVariantItems = function (width: number) {
	if (width > 1200) {
		carouselItems.value = 4
	} else if (width > 850) {
		carouselItems.value = 3
	} else if (width > 620) {
		carouselItems.value = 2
	} else {
		carouselItems.value = 1
	}
}

const onCarouselResize = useDebounceFn(() => {
	setCarouselVariantItems(window.innerWidth)
}, 20)

const emit = defineEmits(['close'])

const setAutomaticSelection = (value: boolean) => {
	versionAutoselect.value = value
	emit('close')
	const toastMessage = value ? t('songVersions.automaticSelected') : t('songVersions.automaticDeselected')
	$oruga.notification.open({
		message: toastMessage,
		icon: 'checkmark-outline',
		iconSize: 'medium'
	})
}

const closeSongVersions = (version: any) => {
	if (previewClicked.value) {
		previewClicked.value = false
		return
	}
	emit('close', { version })
}

onMounted(async () => {
	useEventEmit('songVersionModal:toggle')
	loading.value = true
	setCarouselVariantItems(window.innerWidth)
	window.addEventListener('resize', onCarouselResize)
	if (!showDemoPlayer.value) {
		const { data } = await $singaApi.Me.Preferences.get(props.versions.map(n => n.id))
		const prefs = data.value.map((n: any) => {
			return { ...n, vocals: n.track !== 'karaoke' }
		})
		preferences.value = prefs
	}
	loading.value = false
})

onUnmounted(() => {
	useEventEmit('songVersionModal:toggle')
	window.removeEventListener('resize', onCarouselResize)
	if ($previewPlayer.isPlaying()) {
		stopPreview()
	}
})
</script>

<style lang="sass" scoped>
.version-preference
	background-color: $transparent-white-8
	border-radius: $radius-default
	display: flex
	justify-content: space-between
	align-items: center
	padding: $spacing-16 $spacing-24 $spacing-16 $spacing-24
	margin-top: $spacing-48
	.preference-title
		@include font(basier, semiBold)
		display: block
		margin-bottom: $spacing-4
		color: $color-grey-10
	.preference-body
		color: $color-grey-30
		@include fontSize(xs)
.preview-button
	background-color: $transparent-white-4
	border-color: transparent
	border-radius: 0 0 $radius-default $radius-default !important
	width: 100%
	height: 40px
	border: 0
	span
		color: $color-grey-30
	&:focus
		color: $color-grey-50
		background-color: $transparent-white-8
	&:disabled
		color: $color-grey-70
	&:hover
		background-color:  $transparent-white-8
.version-tags
	max-width: 200px
	text-align: center
	.tag
		text-transform: uppercase
		@include fontSize(xxs)
		@include font(basier, medium)
		color: $color-grey-50
		background: transparent
		letter-spacing: 0.5px
		padding: 0
		&.is-selected
			color: $color-grey-30
		&:after
			content: '•'
			margin: 0 $spacing-4 0 $spacing-4

	.tag:last-child
		&:after
			content: ''
			margin: 0
.version-wrap:hover
	.version
		background-color: $transparent-white-12
		&.is-selected
			background-color: $color-grey-70

.version
	padding: $spacing-24 $spacing-16
	display: flex
	cursor: pointer
	align-items: center
	justify-content: center
	flex-direction: column
	max-height: 104px
	border-radius: $radius-default $radius-default 0 0
	background-color: $transparent-white-8
	position: relative
	height: 98px
	&.is-selected
		background-color: $transparent-white-12
.publisher
	color: $color-grey-10
	@include font(basier, medium)
.version-popular, .version-original
	position: absolute
	top: -12px
	right: 12px
	border-radius: $radius-round
	@include font(basier, medium)
	@include fontSize(xs)
	padding: $spacing-4 $spacing-16
	height: $spacing-24
	display: flex
	align-items: center

.version-popular
	background-color: $primary
	color: $color-grey-90
.version-original
	background-color: white
	color: $color-grey-90
.original-svg
	position: absolute
	top: 0
	left: 0
	border-radius: $radius-default
.original
	.publisher
		@include font(basier, semiBold)
	.tag
		color: $color-grey-20
.original-inprint
	margin-top: $spacing-24
	text-align: center
	span
		color: $color-grey-50
.version-header
	@include fontSize(3xl)
	@include font(basier, bold)
.modal-card-head
	padding-bottom: $spacing-24
.carousel-footer
	padding: 0 14px
.SongVersions
	border: 1px solid $color-grey-80
	border-radius: $radius-default
	padding: 20px
	background-color: $color-grey-90
	z-index: 9999
	.delete
		position: absolute
		top: 0
		right: 0
		max-width: 28px
		max-height: 28px
		width: 28px
		height: 28px
		&:hover
			background-color: $color-grey-30
	.modal-card-body
		overflow: hidden
.b-tooltip
	width: 100% !important

.versions-wrap
	animation: fade 150ms
@keyframes fade
	from
		opacity: 0
	to
		opacity: 1

.version-checkmark
	color: $primary
	position: absolute
	left: 12px
	top: 12px

.toggle
	background-color: $color-grey-80

:deep(.carousel-item)
	height: 144px
</style>
