<script>
import { OIcon } from '@oruga-ui/oruga-next/dist/esm/icon'

export default {
	name: 'SingaIcon',
	extends: OIcon
}
</script>

<style lang="sass">
.is-left
	left: 24px !important
.icon
	svg
		width: 100%
		height: 100%
</style>
