<template lang="pug">
.custom-slider
	input.slider(ref='slider' :value="modelValue"
	@input=`//@ts-ignore Because ts is lying here
	$emit('update:modelValue', parseFloat($event.target?.value))`
	type="range" min="0" max="100" step="1"
	:disabled="disabled"
	:class="{ 'is-disabled': disabled }"
	)
</template>

<script setup lang="ts">
const props = defineProps({
	modelValue: {
		type: Number,
		default: 50
	},
	disabled: {
		type: Boolean,
		default: false
	}
})

const emit = defineEmits(['update:modelValue'])

const sliderValue = toRef(props, 'modelValue')
const slider = ref<HTMLInputElement>()

// function to get the progress of the slider
const getProgress = (value: number, min: number, max: number) => {
	return ((value - min) / (max - min)) * 100
}

// function to set the css variable for the progress
const setCSSProgress = (progress: any) => {
	slider.value?.style.setProperty('--ProgressPercent', `${progress}%`)
}

// watchEffect to update the css variable when the slider value changes
watchEffect(() => {
	if (slider.value) {
		// emit the updated value to the parent component
		emit('update:modelValue', sliderValue.value)

		// update the slider progress
		const progress = getProgress(
			sliderValue.value,
			Number(slider.value.min),
			Number(slider.value.max)
		)

		// set the css variable
		setCSSProgress(progress)
	}
})
</script>

<style scoped lang="sass">
.custom-slider
	--thumbRadius: 0rem
	height: 40px
	.slider
		height: 40px
	input[type="range"]
		position: relative
		appearance: none
		border-radius: 999px
		z-index: 0
		width: 100%
		overflow: hidden
		cursor: pointer

	input[type="range"]::before
		content: ""
		position: absolute
		width: var(--ProgressPercent, 100%)
		height: 100%
		background: $transparent-white-40
		pointer-events: none
		border-top-left-radius: 999px
		border-bottom-left-radius: 999px
	input[type="range"]::-webkit-slider-runnable-track
		appearance: none
		background: $transparent-white-12
		height: 40px
		border-radius: 999px

	input[type="range"]::-moz-range-track
		appearance: none
		background: $transparent-white-12
		height: 40px !important
		border-radius: 999px
	input[type="range"]::-moz-range-progress
		appearance: none
		background: $transparent-white-40
		height: 40px
		border-top-left-radius: 999px
		border-bottom-left-radius: 999px

	input[type="range"]::-webkit-slider-thumb
		position: relative
		width: var(--thumbRadius)
		height: var(--thumbRadius)
		margin-top: calc(40 - var(--thumbRadius) / 2)
		pointer-events: all
		appearance: none
		z-index: 1
	input[type="range"]::-moz-range-thumb
		pointer-events: all
		appearance: none
		visibility: hidden

	.is-disabled
		pointer-events: none
</style>
