<template lang="pug">
.wrapper(:class="{ conversionModal: type === 'conversionModal' && authView === AuthViewStage.INIT }")
	LayoutBackButton(v-if="!isModal && (authView === AuthViewStage.INIT || authView === AuthViewStage.PASSWORD_RESET_SUCCESS)")
	//- TODO: better state to login views
	SingaButton.is-transparent-dark.back-button.is-regular(v-else-if="!isModal" @click="authView = AuthViewStage.INIT")
		.btn-wrap
			SingaIcon(icon="arrow-back")
			span {{ t('general.goBack') }}
	.auth-wrap
		button.delete.close(v-if="isModal" type="button" @click="$emit('close')")
		.auth-card(:class="{ 'conversionModal': props.type === 'conversionModal' }")
			SingaLogo(v-if="type !== 'conversionModal' || authView !== AuthViewStage.INIT")
			.body-wrap(ref="authBodyElem")
				transition(name="slide-side")
					//- Initial modal
					AuthViewInitOptions(
						v-if="authView === AuthViewStage.INIT"
						@update-username="updateUsername"
						@email-login="continueEmailFlow(true)"
						@reset-cancel="resetCancel"
						@email-signup="continueEmailFlow(false)"
						@update-height="setCardBodyHeight"
						:conversionModal="props.type === 'conversionModal'"
						:action="props.action")

					//- Login with password modal
					AuthViewEmailLogin(
						v-else-if="authView === AuthViewStage.EMAIL_LOGIN"
						:username="username"
						@reset-cancel="resetCancel"
						:userExists="true"
						@update-height="setCardBodyHeight")
					//- signup with password modal
					AuthViewEmailLogin(
						v-else-if="authView === AuthViewStage.EMAIL_SIGNUP"
						:username="username"
						@reset-cancel="resetCancel"
						:userExists="false"
						@update-height="setCardBodyHeight")

					//- Password reset modal
					AuthViewPasswordReset(
						v-else-if="authView === AuthViewStage.PASSWORD_RESET"
						:username="username"
						@update-username="updateUsername"
						@reset-cancel="resetCancel"
						@reset-success="resetSuccess"
						@update-height="setCardBodyHeight")

					//- Password reset confirmation modal
					AuthViewPasswordResetSuccess(v-else-if="authView === AuthViewStage.PASSWORD_RESET_SUCCESS" @update-height="setCardBodyHeight")

					//- Magic link (login and sign-up)
					AuthViewMagicLink(v-else-if="authView === AuthViewStage.MAGIC_LINK" @update-height="setCardBodyHeight")

		//- Additional links at the bottom
		template(v-if="authView === AuthViewStage.INIT && type !== 'conversionModal'")
			span.auth-bottom
				| {{ t('auth.agreeToTerms') }}
				|
				NuxtLink.auth-link(:to="localePath('/terms/')") {{ t('auth.agreeToTerms.link1') }}
				|
				| {{ t('auth.agreeToTerms.and') }}
				|
				NuxtLink.auth-link(:to="localePath('/privacy/')") {{ t('auth.agreeToTerms.link2') }}
		template(v-else-if="authView === AuthViewStage.EMAIL_LOGIN")
			NuxtLink.auth-bottom.forgot-password.auth-link(@click="authView = AuthViewStage.PASSWORD_RESET") {{ t('auth.password.forgot') }}
</template>

<script setup lang="ts">
enum AuthViewStage {
	INIT = 'init',
	EMAIL_LOGIN = 'emailLogin',
	EMAIL_SIGNUP = 'emailSignup',
	MAGIC_LINK = 'magicLink',
	PASSWORD_RESET = 'passwordReset',
	PASSWORD_RESET_SUCCESS = 'passwordResetSuccess'
}

const props = defineProps({
	isModal: {
		type: Boolean,
		default: true
	},
	type: {
		type: String,
		default: 'normal'
	},
	action: {
		type: String,
		default: 'login'
	}
})

useHead({
	htmlAttrs: {
		class: props.type === 'conversionModal' ? 'is-clipped' : ''
	}
})

const authView = ref()
const { t } = useI18n()
const username = ref('')
const authBodyElem = ref<HTMLDivElement>()
const localePath = useLocalePath()

// Emits
const continueEmailFlow = (userExists: boolean) => {
	if (userExists) {
		authView.value = AuthViewStage.EMAIL_LOGIN
	} else {
		authView.value = AuthViewStage.EMAIL_SIGNUP
	}
}

const updateUsername = (user: string) => {
	username.value = user
}

const resetSuccess = () => {
	authView.value = AuthViewStage.PASSWORD_RESET_SUCCESS
}

const resetCancel = () => {
	authView.value = AuthViewStage.INIT
}

const setCardBodyHeight = (height: number) => {
	if (authBodyElem.value) {
		authBodyElem.value.style.height = height + 'px'
	}
}

onMounted(() => {
	if (props.action === 'forgot') {
		authView.value = AuthViewStage.PASSWORD_RESET
	} else {
		authView.value = AuthViewStage.INIT
	}
})
</script>

<style lang="sass" scoped>
.wrapper
	display: flex
	flex-direction: row
	align-items: flex-start
	gap: 1em
	max-width: 480px
	width: 100%
.modal
	.wrapper
		height: 100dvh
		margin: auto
		max-width: 100%
		&.conversionModal
			max-width: 609px
			position: relative
			max-height: 549px
		@media (min-width: $tablet)
			height: 100%
.back-button
	position: absolute
	left: 10%
@media (max-width: $desktop)
	.wrapper
		flex-direction: column
	.back-button
		position: relative
		left: 0
.body-wrap
	position: relative
	will-change: height
	transition: height 150ms ease
	height: 414px //- update if the initial card height changes
	width: 100%
	max-width: 480px

.close
	position: absolute
	top: $spacing-32
	right: $spacing-16
	max-width: 28px
	max-height: 28px
	width: 28px
	height: 28px
	z-index: 1
	&:hover
		background-color: $color-grey-30
	@media (min-width: $tablet)
		top: $spacing-16
.auth-wrap
	width: 100%
	margin: 0 auto
	background: transparent
	backdrop-filter: blur(40px)
	@media (min-width: $tablet)
		margin: auto

.conversionModal
	.auth-card
		// background-color: $color-grey-90 !important
		border: none !important
		max-height: 549px
		padding: 0 $spacing-32 0 $spacing-32

	.close
		top: -$spacing-32
	@media (min-width: $tablet)
		.close
			top: $spacing-16
		.auth-wrap
			max-width: 609px !important
		.auth-card
			padding: 76px 100px 76px 100px !important
			margin: 0

.auth-card
	border-radius: $radius-default
	border: 1px solid $transparent-white-12
	padding: $spacing-24 $spacing-16
	position: relative
	background-color: $color-grey-80
	button, .field
		margin-bottom: $spacing-16
	button:last-child
		margin-bottom: 0
	@media (min-width: $tablet)
		padding: $spacing-40 $spacing-32
.auth-bottom
	color: $color-grey-60
	text-align: center
	display: block
	margin: $spacing-8 0 $spacing-16
	@include fontSize(xs)
	padding: 0 $spacing-24
	@media (min-width: $tablet)
		margin: $spacing-16 0

.forgot-password
	text-decoration: underline
	&:hover
		color: $color-grey-40
.singa-logo
	width: 100%
	margin: 0 0 $spacing-16
	@media (min-width: $tablet)
		margin: $spacing-24 0

.modal
	.auth-card
		border-radius: 0px
		border: none
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		background: transparent
		&:not(.conversionModal)
			height: 100dvh
	.auth-bottom
		position: absolute
		bottom: 0
		left: 0
		right: 0
	@media (min-width: $tablet)
		.auth-wrap
			max-width: 480px
			margin: auto
		.auth-card
			border-radius: $radius-default
			border: 1px solid $transparent-white-12
			background-color: $color-grey-80
		.auth-bottom
			bottom: unset

.slide-side-enter-active
	transition: all 250ms ease
.slide-side-leave-active
	transition: all 150ms ease
.slide-side-enter-from
	opacity: 0
	transform: translateX(30px)

.slide-side-leave-to
	opacity: 0
	transform: translateX(-30px)

.auth-link
	color: $color-grey-60
	@include font(basier, semiBold)
	text-decoration: underline
	&:hover
		color: $color-grey-40

.back-button
	color: $color-grey-30
	.btn-wrap
		display: flex
		align-items: center
</style>

<style lang="sass">
.auth-card
	.link
		text-align: center
		@include fontSize(xs)
		:hover
			color: $color-grey-60
	.auth-header, .auth-body
		width: 100%
	.auth-header
		text-align: center
		h1
			@include font(basier, bold)
			@include fontSize(3xl)
			margin-bottom: $spacing-24
	.auth-sub
		@include fontSize(s)
		color: $color-grey-30
		margin-bottom: $spacing-24
		white-space: break-spaces
		@media (min-width: $tablet)
			@include fontSize(l)
	.button-google, .button-facebook
		.icon
			position: absolute
			left: $spacing-8
</style>
