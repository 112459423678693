import { defineStore } from 'pinia'
import type { Playlist, Artist, Song } from '../types'

interface ContentCacheState {
	stateItem: {
		slug: string
		item: Song | Artist | Playlist
	}
}

export const useContentCacheStore = defineStore('contentCacheStore', {
	state: (): ContentCacheState => {
		return {
			stateItem: {
				slug: '',
				item: {} as Song | Artist | Playlist
			}
		}
	},
	getters: {
		getStateItem: state => state.stateItem
	},
	actions: {
		setItem(type: 'song' | 'artist' | 'playlist', item: any) {
			let itemKey: string = ''
			if (type === 'song') {
				const artistSlugs = item.artists.map((artist: any) => artist.slug).join(',')
				itemKey = `${artistSlugs}/${item.slug}/${item.hash}`
			} else if (type === 'artist') {
				itemKey = item.slug + '/' + item.hash
			} else if (type === 'playlist') {
				itemKey = item.slug + '/' + item.hash
			}

			this.stateItem = { slug: itemKey, item }
		}
	}
})
