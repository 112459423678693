import type { UseFetchOptions } from 'nuxt/app'

export function useAPIProxy<T>(
	url: string | (()=> string),
	options: UseFetchOptions<T> = {}
) {
	return useFetch(url, {
		...options
	})
}
