<template lang="pug">
CommonContextMenu(ref="dropdown" @init-context="initContext(song)" @close="closeContextMenu()")
	template.dropdown-menu(v-slot:content)
		.init(v-if="contextView === ContextMenuStage.INIT")
			.authorised-actions(v-if="isUserAuthorised")
				.dropdown-item(v-if="!isQueueItem && !isMobile" aria-role="listitem" @click="addSongToQueue(song)") {{ t('song.addToQueue') }}
				.dropdown-item(aria-role="listitem" @click="setPlaylistsStage()") {{ t('song.addToPlaylist') }}
				.dropdown-item(v-if="loadingFavoriteStatus")
					CommonLoadingAnimation(:timeOut="0" type="dots")
				template(v-else)
					.dropdown-item(v-if="!favorited" aria-role="listitem" @click="toggleFavoriteStatus('songs', song.id, song.name, favoritesLink, true, segmentData); closeAll(); sendFavoriteEvent()" :disabled="loadingFavorite") {{ t('contextMenu.favorites.add') }}
					.dropdown-item(v-else aria-role="listitem" @click="toggleFavoriteStatus('songs', song.id, song.name, favoritesLink, true, segmentData); closeAll()" :disabled="loadingFavorite") {{ t('contextMenu.favorites.remove') }}
			.dropdown-item(v-if="!isSongDetailPage && !isMiniPlayer" aria-role="listitem" @click="openDetailPage()") {{ t('contextMenu.openDetail.song') }}
			.dropdown-item(aria-role="listitem" @click="showCredits(song)") {{ t('song.credits.menu') }}
			.dropdown-item( @click="contextView = ContextMenuStage.SHARE") {{ t('contextMenu.share') }}
		.share(v-else-if="contextView === ContextMenuStage.SHARE")
			.dropdown-item.no-radius(@click="contextView = ContextMenuStage.INIT" role="listitem")
				.btn-wrap
					SingaIcon(icon="arrow-back")
					span {{ t('general.goBack') }}
			div(@click="closeDropdown()")
				CommonShareButtons(:external-url="url")
		.add(v-else-if="contextView === ContextMenuStage.ADD")
			.dropdown-item(@click="contextView = ContextMenuStage.INIT" role="listitem")
				.btn-wrap
					SingaIcon(icon="arrow-back")
					span  {{ t('general.goBack') }}
			.dropdown-item(@click="contextView = ContextMenuStage.NEW_PLAYLIST" role="listitem") {{ t('playlist.new') }}
			.scrollable
				CommonLoadingAnimation.center(v-if="loading && !scrollToLoad")
				template(v-else v-for="playlist in playlists" :key="playlist.id")
					.dropdown-item(role="listitem" @click="saveToPlaylist(playlist)") {{ playlist.title }}
				.load-more-wrap(ref="loadMoreTrigger")
					SingaButton.is-small.is-transparent-dark(@click="loadMoreContent()" v-if="hasNextPage && !scrollToLoad && !loading") {{ t('general.loadMore') }}
					CommonLoadingAnimation(v-else-if="scrollToLoad && loading")
		.new-playlist(v-else-if="contextView === ContextMenuStage.NEW_PLAYLIST")
			.dropdown-item(@click="contextView = ContextMenuStage.ADD" role="listitem")
				.btn-wrap
					SingaIcon(icon="arrow-back")
					span {{ t('general.goBack') }}
			.new-playlist-wrap
				form(@submit.prevent="createNewPlaylist")
					SingaField.input-label(:label="t('playlist.new')")
						SingaInput.is-rounded(ref="playlistInputElem" v-model="playlistTitle" :placeholder="t('playlist.name')")
					SingaSubmitButton.is-small.is-full-width.is-primary(:isLoading="loading" native-type="submit" :buttonCTA="t('general.create')")
</template>

<script setup lang="ts">
import { useInfiniteScroll } from '@vueuse/core'
import PlaylistCreated from '../song/notifications/PlaylistCreated.vue'
import type { Song } from '~/types'
import { useUserPlaylists } from '~~/composables/library/useUserPlaylists'
import { openSongCreditsModal } from '~~/composables/useSongCreditsModal'
import AddedToPlaylist from '~~/components/song/notifications/AddedToPlaylist.vue'

const { $singaApi, $oruga } = useNuxtApp()
const { isUserAuthorised } = useUser()

const { t } = useI18n()
const queueStore = useQueueStore()

const { addToQueue } = queueStore
const { queueItemSelection } = useVariantSelection()

const loadMoreTrigger = ref()

const { items: playlists, loadUserPlaylists, hasNextPage, loading } = useUserPlaylists()
const { getFavoriteStatus, loadingFavorite, toggleFavoriteStatus, favorited } = useFavorites()

const { setItem } = useContentCacheStore()

const props = defineProps({
	song: {
		type: Object as PropType<Song>,
		required: true
	},
	link: {
		type: String,
		required: true
	},
	isSongDetailPage: {
		type: Boolean,
		default: false
	},
	isQueueItem: {
		type: Boolean,
		default: false
	},
	isMiniPlayer: {
		type: Boolean,
		default: false
	},
	playlistIsOfficial: {
		type: Boolean,
		required: false,
		default: false
	},
	playlistResourceId: {
		type: String,
		required: false,
		default: null
	},
	accessPoint: {
		type: String,
		required: true
	}
})

const segmentData = ref({
	access_point: props.accessPoint,
	song_id: props.song.resource_id,
	song_name: props.song.name
})

const playlistTitle = ref('')
const loadingFavoriteStatus = ref(false)
const playlistInputElem = ref(null as any)

const showCredits = (song: Song) => {
	const credits = {
		artist: song.artists.map(artist => artist.name).join(', ').toString(),
		composer: song.composer,
		originalLyrics: song.original_lyrics,
		translatedLyrics: song.translated_lyrics,
		publisher: song.publisher,
		copyright: song.copyright_owner
	}
	openSongCreditsModal(credits)
}

const emit = defineEmits(['reset', 'setActive'])
const isMobile = useDevice().isMobile

const isPlaylistPage = computed(() => {
	const route = useRoute()
	return route.fullPath.includes('playlist')
})

const sendFavoriteEvent = () => {
	if (!favorited.value && isPlaylistPage.value) {
		useSinglistSongFavorited(props.song.resource_id, props.playlistResourceId, props.accessPoint, props.playlistIsOfficial)
	}
}

enum ContextMenuStage {
	INIT = 'init',
	SHARE = 'share',
	ADD = 'add',
	NEW_PLAYLIST = 'new_playlist',
	PLAYLISTS = 'playlists'
}
const contextView = ref(ContextMenuStage.INIT)
const dropdown = ref()
const localePath = useLocalePath()
const scrollToLoad = ref(false)

const favoritesLink = computed(() => localePath(props.link))
const url = `https://${import.meta.client ? window.location.host : ''}${localePath(props.link)}`

const openDetailPage = () => {
	setItem('song', props.song)
	navigateTo(localePath(props.link))
}

const closeDropdown = () => {
	dropdown?.value?.toggleDropdown()
}

const setPlaylistsStage = async () => {
	scrollToLoad.value = false
	contextView.value = ContextMenuStage.ADD
	await loadUserPlaylists(true)
}

useInfiniteScroll(
	loadMoreTrigger,
	() => {
		if (hasNextPage.value && !loading.value && scrollToLoad.value) {
			loadUserPlaylists()
		}
	},
	{ distance: 10, interval: 500 }
)
const loadMoreContent = () => {
	scrollToLoad.value = true
	loadUserPlaylists()
}

const userStore = useUserStore()

const { segmentEvent } = useSegment()
const saveToPlaylist = (playlist: Playlist) => {
	$singaApi.Playlists.addVariant({
		id: playlist.id,
		params: { variants: [props.song.variants[0].id] }
	}).then(() => {
		const lengthCheckedTitle = useTruncate(playlist.title)
		$oruga.notification.open({
			component: AddedToPlaylist,
			props: {
				songLink: props.link,
				songName: props.song.name,
				playlistTitle: lengthCheckedTitle,
				playlistLink: `/playlists/${playlist.slug}/${playlist.hash}`
			},
			icon: 'checkmark-outline',
			iconSize: 'medium'
		})
		const playlistData = {
			access_point: props.accessPoint,
			country: userStore.user?.country,
			user_id: userStore.user?.resource_id,
			singlist_id: playlist.resource_id,
			song_id: props.song.id,
			variant_id: props.song.variants[0].id
		}
		segmentEvent('Singlist Song Added', playlistData)
	}).catch((e: any) => {
		console.log(e)
		$oruga.notification.open({
			message: t('song.generalError'),
			variant: 'warning'
		})
	})
	// if (props.closeAfterAction) { $oruga.modal.closeAll()  }
	closeDropdown()
	emit('reset')
	playlistTitle.value = ''
}

const closeContextMenu = () => {
	emit('reset')
}

const closeAll = () => {
	closeDropdown()
	closeContextMenu()
}

// Focus on the singlist name input when the dropdown is opened
watch(() => contextView.value, async (val) => {
	if (val === 'new_playlist') {
		await nextTick()
		playlistInputElem.value?.focus()
	}
})

const createNewPlaylist = async () => {
	const validatePlaylist = () => {
		if (playlistTitle.value.length < 1 || !/[A-Za-zŽžÀ-ÿ0-9]/.test(playlistTitle.value)) {
			throw new Error('playlist.title.invalidError')
		}
	}

	try {
		validatePlaylist()
		loading.value = true

		const { data: resp, error } = await $singaApi.Playlists.add({ title: playlistTitle.value })
		const respData = resp.value
		const lengthCheckedTitle = useTruncate(respData.title)
		const playlist = respData
		saveToPlaylist(playlist)
		$oruga.notification.open({
			component: PlaylistCreated,
			props: {
				playlistTitle: lengthCheckedTitle,
				playlistLink: `/playlists/${playlist.slug}/${playlist.hash}`
			},
			icon: 'checkmark-outline',
			iconSize: 'medium'
		})
		if (error.value) {
			console.error(error.value)
			$oruga.notification.open({
				message: t('playlist.generalError'),
				variant: 'warning'
			})
		}
		loading.value = false
	} catch (e) {
		console.error(e)
		$oruga.notification.open({
			message: t('playlist.title.invalidError'),
			variant: 'warning'
		})
	}
}

const initContext = async (song: Song) => {
	contextView.value = ContextMenuStage.INIT
	emit('setActive')
	favorited.value = await getFavoriteStatus('songs', song.id) || null
}

const addSongToQueue = async (song: Song) => {
	const queueItem = await queueItemSelection(song)
	if (!queueItem) { return }
	closeDropdown()
	await addToQueue(queueItem, props.link, 'Song context menu', false, t('song.error.queue'))
}

useEventOn(('queueActions:addToQueue'), () => {
	emit('reset')
})

onUnmounted(() => {
	useEventOff('queueActions:addToQueue')
})
</script>

<style lang="sass" scoped>
.btn-wrap
	display: flex
	align-items: center
	gap: 8px
.dropdown-item
	padding: 12px 24px
	@include fontSize(s)
	&.is-active
		background-color: $transparent-white-8
		@include font(basier, medium)
.scrollable
	max-height: 180px
	min-height: 80px
	overflow: auto
	overflow-x: hidden
	.dropdown-item
		max-width: 100%
		text-overflow: ellipsis
		overflow: hidden
	.center
		margin: auto
.new-playlist-wrap
	padding: $spacing-4 $spacing-24 $spacing-16
	display: flex
	flex-direction: column
	gap: $spacing-4

.load-more-wrap
	text-align: center
	margin-bottom: $spacing-8
</style>
