<script>
import { OCheckbox } from '@oruga-ui/oruga-next/dist/esm/checkbox'

export default {
	name: 'SingaCheckbox',
	extends: OCheckbox
}
</script>

<style lang="sass">
.control-label
	color: $color-grey-30
</style>
