import type { SongRequestQuery } from '../types'
import { useRuntimeConfig } from '#imports'

export const createApiWrapper = () => {
	const config = useRuntimeConfig()
	const resolveUrl = config.public.SINGA_RESOLVE_PREFIX
	return {
		Me: {
			get: () => useAPI('/me/'),
			patch: (params: any) => useAPI('/me/', { method: 'PATCH', body: { ...params } }),
			delete: (params: any) => useAPI('/me/', { method: 'DELETE', body: params.data, headers: params.headers }),
			Preferences: {
				get: (ids: any) => useAPI(`/me/preferences/?variants=${ids.join(',')}`),
				put: (variantId: any, data: any) => useAPI(`me/preferences/songvariants/${variantId}/`, { method: 'PUT', body: { ...data } })
			},
			Reset: {
				Email: {
					getResetToken: (newEmail: string) => useAPI('/me/reset/email/', { method: 'POST', body: { email: newEmail } }),
					checkResetToken: (token: string) => useAPI(`/me/reset/email/${token}/`),
					useResetToken: (token: string) => useAPI(`/me/reset/email/${token}/`, { method: 'PATCH' }),
					cancelResetToken: (token: string) => useAPI(`/me/reset/email/${token}/`, { method: 'DELETE' })
				},
				Password: {
					getResetToken: (userOrEmail: string) => useAPI('/me/reset/password/', { method: 'POST', body: { user: userOrEmail } }),
					checkResetToken: (token: string) => useAPI(`/me/reset/password/${token}/`),
					useResetToken: (token: string, newPassword: string) => useAPI(`/me/reset/password/${token}/`, { method: 'PATCH', body: { password: newPassword } }),
					cancelResetToken: (token: string) => useAPI(`/me/reset/password/${token}/`, { method: 'DELETE' })
				}
			},
			SongHistory: {
				list: (params: object) => useAPI('/me/history/songs/', { query: params })
			},
			Playlists: {
				list: (params: object) => useAPI('/me/playlists/songs/', { query: params })
			},
			Stripe: {
				customerPortalSession: (body: object) => useAPIProxy('/api/stripe/customerPortalSession', { method: 'POST', body }),
				checkoutSession: (body: object) => useAPIProxy('/api/stripe/checkoutSession', { method: 'POST', body })
			},
			JWT: {
				get: () => useAPI('/me/iterable/jwt/', { method: 'POST', body: { } })
			}
		},
		Users: {
			UserExists: (params: any) => useAPI('/users/exists/', { method: 'POST', body: params })
		},
		Favorites: {
			list: (params: object) => useAPI('/me/favorites/exists?type=songs,playlists,artists', { method: 'POST', params }),
			checkFavoriteStatus: (params: object) => useAPI('/me/favorites/exists/', { method: 'POST', params }),
			FavoriteSongs: {
				list: (params: object) => useAPI('/me/favorites/songs/', { query: params }),
				addFavorite: (id: number) => useAPI(`/me/favorites/songs/${id}/`, { method: 'PUT' }),
				deleteFavorite: (id: number) => useAPI(`/me/favorites/songs/${id}/`, { method: 'DELETE' })
			},
			FavoriteArtists: {
				list: (params: object) => useAPI('/me/favorites/artists/', { query: params }),
				addFavorite: (id: number) => useAPI(`/me/favorites/artists/${id}/`, { method: 'PUT' }),
				deleteFavorite: (id: number) => useAPI(`/me/favorites/artists/${id}/`, { method: 'DELETE' })
			},
			FavoritePlaylists: {
				list: (params: object) => useAPI('/me/favorites/playlists/songs/', { query: params }),
				addFavorite: (id: number) => useAPI(`/me/favorites/playlists/songs/${id}/`, { method: 'PUT' }),
				deleteFavorite: (id: number) => useAPI(`/me/favorites/playlists/songs/${id}/`, { method: 'DELETE' })
			}
		},
		Auth: {
			getTokens: (credentials: object) => useAPIProxy('/api/oauth/token', {
				body: { ...credentials },
				method: 'POST'
			}),
			getGoogleProfile: (payload: string) => useAPIProxy(`/api/google/${payload}`),
			register: (credentials: object) => useAPIProxy('/api/registration/', {
				body: { ...credentials },
				method: 'POST'
			})
		},
		Sheets: {
			addRow: (sheet: 'deleteAccount', payload: object) => useAPIProxy(`/api/google/sheet/${sheet}/`, { body: { ...payload }, method: 'POST' })
		},
		Connections: {
			getTokens: (payload: object) => useAPIProxy('/api/connections/social', { // Not supposed to have a trailing backslash
				body: { ...payload },
				method: 'POST'
			}),
			connectDevice: (pin: string) => useAPIProxy(`/api/connections/${pin}`, { method: 'PATCH', body: { state: 'accepted' } })
		},
		Songs: {
			get: ({ id }: { id: number }) => useAPI(`/songs/${id}/`),
			post: ({ song, artist }: { song: string, artist: string }) => useAPI('/songs/requests/', { method: 'POST', body: { song, artist } }),
			list: (params: SongRequestQuery) => useAPI('/songs/', { query: params }),
			nextPage: ({ nextUrl }: { nextUrl: string }) => useAPI(nextUrl),
			log: (data: any) => useAPI('/log/song/', { method: 'POST', body: data }),
			getVariant: (songId: number, variantId: number) => useAPI(`/songs/${songId}/variants/${variantId}/`),
			related: ({ id }: { id: number }) => useAPI(`/songs/${id}/related/`),
			postError: (data: any) => useAPI('songs/reports/', { method: 'POST', body: data })
		},
		Artists: {
			get: ({ id, params }: { id: number, params: object }) => useAPI(`/artists/${id}/`, { query: params }),
			list: (params: any) => useAPI('/artists/', { query: params }),
			nextPage: ({ nextUrl }: { nextUrl: string }) => useAPI(nextUrl),
			artistSongs: (params: any, id: any) => useAPI(`/artists/${id}/songs/`, { query: params }),
			getArtistImages: ({ id, params }: { id: number, params: object }) => useAPI(`/artists/${id}/images/`, { query: params })
		},
		Playlists: {
			get: ({ id, params }: { id: number, params: object }) => useAPI(`/playlist/${id}/`, { query: params }),
			list: (params: any) => useAPI('/playlists/songs/', { query: params }),
			nextPage: ({ nextUrl }: { nextUrl: string }) => useAPI(nextUrl),
			playlistSongs: (params: any, id: { id: number, params: object }) => useAPI(`/playlists/songs/${id}/entries/`, { query: params }),
			reOrderSongs: ({ id, rangeStart, insertBefore }: { id: number, rangeStart: number, insertBefore: number }) => useAPI(`/playlists/songs/${id}/entries/`, { method: 'PUT', body: { range_start: rangeStart, insert_before: insertBefore } }),
			getArtistImages: ({ id, params }: { id: number, params: object }) => useAPI(`/playlist/${id}/images/`, { query: params }),
			add: ({ title, description, availability }: { title: string, description?: string, availability?: 'public' | 'private' }) => useAPI('/playlists/songs/', { method: 'POST', body: { title, description, availability } }),
			edit: ({ id, title, description }: { id: number, title: string, description?: string }) => useAPI(`/playlists/songs/${id}/`, { method: 'PATCH', body: { title, description } }),
			togglePublicity: ({ id, publicity }: { id: number, publicity: string }) => useAPI(`/playlists/songs/${id}/`, { method: 'PATCH', body: { availability: publicity } }),
			delete: (id: number) => useAPI(`/playlists/songs/${id}/`, { method: 'DELETE' }),
			addVariant: ({ id, params }: { id: number, params: object }) => useAPI(`/playlists/songs/${id}/entries/`, { method: 'POST', body: { ...params } }),
			deleteVariant: (id: number, params: any) => useAPI(`/playlists/songs/${id}/entries/`, { method: 'DELETE', ...params })
		},
		ScheduledPlaylists: {
			get: ({ contentMarket, params }: { contentMarket: string, params: object }) => useAPI(`/playlists/songs/scheduled/?country=${contentMarket}&market=${contentMarket}`, { query: params })
		},
		Media: {
			getAudio: (songId: number, variantId: number, pitch: number) => useAPI(`/songs/${songId}/variants/${variantId}/audio/?pitch=${pitch}`),
			getLyrics: (songId: number, variantId: number) => useAPI(`/songs/${songId}/variants/${variantId}/lyrics/`),
			getPreviewAudio: (songId: number, variantId: number) => useAPI(`/songs/${songId}/variants/${variantId}/`),
			getPreviewLyrics: (songId: number, variantId: number) => useAPI(`/songs/${songId}/variants/${variantId}/lyrics/preview/`),
			getVariantImages: (songId: number, variantId: number) => useAPI(`/songs/${songId}/variants/${variantId}/images/`)
		},
		Genres: {
			list: (params: any) => useAPI('/genres/', { query: params, dedupe: 'defer' }),
			get: (id: any) => useAPI(`/genres/${id}/`, { key: `genre-${id}` }),
			getImages: ({ id, params }: { id: number, params: object }) => useAPI(`/genres/${id}/images/`, { query: params })
		},
		Venues: {
			list: (params: any) => useAPI('/venues/', { query: params }),
			search: (params: string) => useAPI(`/venues/?search=${params}`)
		},
		Cities: {
			get: () => useAPI('venues/cities/?page_size=1000')
		},
		GenreImages: {
			get: (genreId: number) => useAPI(`imagebanks/${genreId}/entries/`)
		},
		Languages: {
			list: (params: any) => useAPI('/songs/languages/', { query: params, key: 'languages' })
		},
		Search: {
			get: (params: object) => useAPI('/search/', { query: params })
		},
		URL: {
			get: (url: any, params: object) => useAPI(url, { query: params })
		},
		Resolve: {
			Artists: {
				get: (slug: string, hash: string) => useAPI(`/resolve/?url=${resolveUrl}/artists/${slug}/${hash}`, { key: `artist-${slug}-${hash}` })
			},
			Playlists: {
				get: (slug: string, hash: string) => useAPI(`/resolve/?url=${resolveUrl}/lists/${slug}/${hash}`, { key: `playlist-${slug}-${hash}` })
			},
			Songs: {
				get: (slug: string, songSlug: string, hash: string) => useAPI(`/resolve/?url=${resolveUrl}/artists/${slug}/${songSlug}/${hash}`, { key: `song-${songSlug}-${hash}` })
			},
			Venues: {
				get: (slug: string) => useAPI(`/resolve/?url=${resolveUrl}/karaoke-near-me/${slug}`, { key: `venue-${slug}` })
			}
		},
		Products: {
			getPlans: (params: object) => useAPI('/products/plans/', { query: params }),
			getPasses: (params: object) => useAPI('/products/passes/', { query: params })
		}
	}
}
