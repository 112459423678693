<script>
import { OTooltip } from '@oruga-ui/oruga-next/dist/esm/tooltip'

export default {
	name: 'SingaTooltip',
	extends: OTooltip
}
</script>

<style lang="sass" scoped>
</style>
