<template lang="pug">
.SongTags(@click.prevent.stop :class="{ 'song-detail': variant === 'detail' }")
	.song-tag(v-if="tags.has_cover" @click="updateFilterByTag('has_cover')")
		span {{ t('tags.vocals') }}
	.song-tag(v-if="tags.has_duet" @click="updateFilterByTag('has_duet')")
		span {{ t('tags.duet') }}
	.song-tag(v-if="tags.has_explicit" @click="updateFilterByTag('has_explicit')")
		span {{ t('tags.explicit') }}
	.song-tag(v-if="tags.has_original" @click="updateFilterByTag('has_original')")
		span {{ t('tags.original') }}
	.song-tag(v-if="tags.has_plus" @click="updateFilterByTag('has_plus')")
		span Plus
</template>

<script setup lang="ts">
const { t } = useI18n()
const route = useRoute()
const localePath = useLocalePath()

defineProps({
	tags: {
		type: Object,
		default: null
	},
	variant: {
		type: String,
		default: 'list'
	}
})

const inFilterSearch = ref(route.fullPath.includes('/songs/'))

const updateFilterByTag = (tag: string) => {
	if (!inFilterSearch.value) {
		const query = ref({} as any)
		query.value[tag] = true
		navigateTo({ path: localePath('/songs/'), query: { ...query.value } })
	}

	switch (tag) {
		case 'has_cover':
			useEventEmit('tagFilterChange:updateHasCover', true)
			break
		case 'has_duet':
			useEventEmit('tagFilterChange:updateHasDuet', true)
			break
		case 'has_explicit':
			useEventEmit('tagFilterChange:updateHasExplicit', true)
			break
		case 'has_original':
			useEventEmit('tagFilterChange:updateHasOriginal', true)
			break
		case 'has_plus':
			useEventEmit('tagFilterChange:updateHasPlus', true)
			break
		default:
			break
	}
}
</script>

<style lang="sass" scoped>
.SongTags
	display: flex
	min-width: 0
	max-width: 322px
	flex-wrap: wrap
	align-items: center
	gap: $spacing-8
	.song-tag
		display: flex
		min-width: 0
		border-radius: $radius-round
		transition: .3s
		cursor: pointer
		span
			overflow: hidden
			white-space: nowrap
			text-overflow: ellipsis
			display: block
.SongTags.song-detail
	.song-tag
		background-color: $transparent-white-16
		padding: 2px $spacing-8
		span
			color: $color-grey-20
			@include fontSize(xxs)
			@include font(basier, medium)
		&:hover
			background-color: $transparent-white-24
		@media (min-width: $tablet)
			padding: 4px $spacing-16
			span
				@include fontSize(xs)
.SongTags:not(.song-detail)
	.song-tag
		background-color: $transparent-white-4
		padding: 2px $spacing-8
		span
			color: $color-grey-60
			@include fontSize(xxs)
		&:hover
			background-color: $color-grey-70
</style>
