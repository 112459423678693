<script>
import { OField } from '@oruga-ui/oruga-next/dist/esm/field'

export default {
	name: 'SingaField',
	extends: OField
}
</script>

<style lang="sass" scoped>
.label
	font-weight: normal
.field:not(.is-horizontal) label
	@include fontSize(xs)
.input-label
	label
		font-size: $size-8
		padding-bottom: $spacing-8
		text-transform: uppercase
.is-horizontal
	.field-label
		flex: 1
		text-align: left
	.field-body
		flex: 1
</style>
