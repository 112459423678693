import { usePlayerStore } from '../pinia/player/karaokePlayer'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'
import type { Song } from '~/types'

/**
 * @typedef {Song} Song
 */
/**
* Select a variant for the `song`, show the mini player and play the song
* @param {Song} song - The song object to play
* @param {boolean} isRequestModal - If the request is coming from the song request modal
* If the user does not have a premium sub or free songs left, show the freemium modal
* If this interrupts a song that is already playing, emit the `playerEvent:interruptedOrCompleted` event
*/
export const usePlaySong = async (song: Song, isRequestModal = false, errorMessage: string) => {
	const { $oruga } = useNuxtApp()
	if (!song) {
		$oruga.notification.open({ message: errorMessage, variant: 'warning' })
		throw new Error('useQueue: Tried to play a null song')
	}

	if (song.variants.length === 0) {
		$oruga.notification.open({ message: errorMessage, variant: 'warning' })
		throw new Error(`useQueue: Song: ${song.id} has no variants`)
	}

	const { hasPremiumOrFreeSongs } = useUser()
	const { setShowMiniPlayer } = usePlayerStore()
	const userStore = useUserStore()
	const audioStore = useAudioPlayerStore()
	const { isAudioPlaying } = storeToRefs(audioStore)

	if (isAudioPlaying.value) {
		useEventEmit('playerEvent:interruptedOrCompleted')
	}

	if (hasPremiumOrFreeSongs.value || userStore.showDemoPlayer) {
		const queueStore = useQueueStore()
		const { playNow } = queueStore
		const { queueItemSelection } = useVariantSelection()
		setShowMiniPlayer(true)
		const queueItem = await queueItemSelection(song)
		if (!queueItem) { return }
		await playNow(queueItem)
		// Player should be started regardless of auto play setting, thus bypass = true
		useEventEmit('player:startPlayer', { countdown: false, bypass: true })

		if (isRequestModal) {
			const { $oruga } = useNuxtApp()
			$oruga.modal.closeAll({ action: 'closeAll' })
		}
	} else {
		openFreemiumModal()
	}
}
