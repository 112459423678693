<script>
import { ODropdown } from '@oruga-ui/oruga-next/dist/esm/dropdown'

export default {
	name: 'SingaContextMenu',
	extends: ODropdown
}
</script>

<style lang="sass" scoped>
.mobile-nav-menu
	.dropdown-menu
		min-width: 271px
		margin-top: $spacing-8
		@media (min-width: 350px)
	min-width: 300px
.dropdown-menu
	margin-top: $spacing-16
	text-align: left
	padding: $spacing-8 0
	min-width: $dropdown-menu-min-width
	max-width: $dropdown-menu-max-width
	white-space: nowrap
	z-index: 100 !important

:not(.is-bottom-left)
	.dropdown-menu
		&:before
			bottom: 100%
			left: 50%
			border: solid transparent
			content: ""
			height: 0
			width: 0
			position: absolute
			pointer-events: none
			border-bottom-color: $color-grey-80
			border-width: 8px
			left: 12px
.is-bottom-left
	.dropdown-menu
		&:before
			bottom: 100%
			left: 80%
			border: solid transparent
			content: ""
			height: 0
			width: 0
			position: absolute
			pointer-events: none
			border-bottom-color: $color-grey-80
			border-width: 8px
.dropdown-menu
	margin-top: $spacing-16
	text-align: left
	padding: $spacing-8 0
	&:before
		bottom: 100%
		left: 50%
		border: solid transparent
		content: ""
		height: 0
		width: 0
		position: absolute
		pointer-events: none
		border-bottom-color: $color-grey-80
		border-width: 8px
		left: 12px

:deep(.dropdown-item)

	&:hover
		color: white
		background-color: $transparent-white-8

.dropdown-menu
	&.mini-player
		margin-top: -10px
		&:before
			display: none
</style>
