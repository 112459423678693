import SongCredits from '../components/modals/SongCredits.vue'
import { useNuxtApp } from '#imports'

export const openSongCreditsModal = (creditObject: object) => {
	const { $oruga } = useNuxtApp()
	$oruga.modal.open({
		component: SongCredits,
		width: 400,
		props: {
			credits: creditObject
		}
	})

	return { openSongCreditsModal }
}
