import { usePreviewPlayerStore } from '../pinia/player/previewPlayerStore'
import { initPreviewPlayer } from '~/player/previewplayer.js'

declare global {
	interface Window { previewPlayer: any }
}
declare module '#app' {
	interface NuxtApp {
		$previewPlayer: any
	}
}

export default defineNuxtPlugin({
	enforce: 'post',
	parallel: true,
	setup() {
		const player = initPreviewPlayer(window)
		const previewPlayerStore = usePreviewPlayerStore()

		player.events.addEventListener('pause', function (event: any) {
			previewPlayerStore.setPlaying(false)
		})
		player.events.addEventListener('play', function (event: any) {
			previewPlayerStore.setPlaying(true)
		})
		player.events.addEventListener('ended', function (event: any) {
			previewPlayerStore.setPlaying(false)
		})
		return {
			provide: {
				previewPlayer: player
			}
		}
	}
})
