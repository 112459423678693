<template lang="pug">
.circle-progress(:class="{ 'limit-reached': previewEnded }")
	.svg-wrapper
		svg.circle-progress__svg(viewBox="-2 -2 108 108", :width="size", :height="size")
			circle.circle-progress__circle.circle-progress__line--back(:r="radius", cx="52", cy="52")
			circle.circle-progress__circle.circle-progress__line--top(
			:class="fillingCircleClasses"
			:style="fillingCircleStyles"
			ref="fillingCircle"
			:r="radius"
			cx="52"
			cy="52"
			transform="rotate(-90 52 52)"
			)
	.circle-progress__content
		SingaIcon(icon="stop" size="medium")
</template>

<script setup lang="ts">
const { previewEnded } = useVariantPreview()

const props = defineProps({
	max: {
		type: Number,
		required: false,
		default: 100
	},

	value: {
		type: Number,
		required: true
	},

	colorFilled: {
		type: String,
		required: false,
		default: '#CCCCCC'
	},

	colorUnfilled: {
		type: String,
		required: false,
		default: '#CCCCCC'
	},

	colorBack: {
		type: String,
		required: false,
		default: '#FFFFFF'
	},

	rounded: {
		type: Boolean,
		required: false,
		default: true
	},

	strokeWidth: {
		type: String,
		required: false,
		default: '6px'
	},

	size: {
		type: [String, Number],
		required: false,
		default: '48'
	}
})

const fillingCircleClasses = computed(() => {
	return {
		'circle-progress__line--top--rounded': props.rounded,
		'circle-progress__line--filled': previewEnded.value,
		'circle-progress__line--unfilled': !previewEnded.value
	}
})

const currentFormatted = computed(() => previewEnded.value ? props.max : props.value)

const fillingCircle = ref(null)
const radius = ref(48)
const dashArray = computed(() => radius.value * Math.PI * 2)
const dashOffset = computed(() => {
	return dashArray.value - dashArray.value * currentFormatted.value / props.max
})

const fillingCircleStyles = computed(() => {
	return {
		strokeDashoffset: dashOffset.value,
		strokeDasharray: dashArray.value
	}
})
</script>

<style lang="sass" scoped>
.circle-progress__circle
	fill: transparent
	stroke-width: v-bind("props.strokeWidth")

.circle-progress__line
	&--back
		stroke: v-bind("props.colorBack")
		stroke-dashoffset: 0

	&--top
		transition: 1s all

	&--top--rounded
		stroke-linecap: round

	&--filled
		stroke: v-bind("props.colorFilled")

	&--unfilled
		stroke: v-bind("props.colorUnfilled")

	&--limit-reached
		display: none

.circle-progress
	display: flex
	position: relative
	justify-content: center
	align-items: center

	&.limit-reached
		display: none

	&__content
		position: absolute
		left: 0
		right: 0
		top: 0
		bottom: 0
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column

.circle-wrapper
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	height: 100%

.svg-wrapper
	display: flex
	justify-content: center
	align-items: center
	background: $transparent-black-72
	border-radius: 50%
	padding: 3px
	animation: fadeIn 0.5s

	@keyframes fadeIn
		0%
			opacity: 0
		100%
			opacity: 1
</style>
