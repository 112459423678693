<template lang="pug">
ModalsActionModal(@close="closeModal")
	template(v-slot:header)
		p.modal-card-title {{ t('modal.songConfirmation.title') }}
		SingaCheckbox.checkbox(v-model="dontShow" @update:modelValue="setSongConfirmationCookie") {{ t('general.dontShowAgain') }}
	template(v-slot:body)
		SingaButton.is-primary.is-full-width.is-regular.modal-button(@click="playSong") {{ t('song.singNow') }}
		SingaButton.is-transparent-dark.is-full-width.is-regular.modal-button(@click="addSongToQueue(song)" v-if="!hideQueueButton") {{ t('song.addToQueue') }}
</template>

<script setup lang="ts">
import { usePlaySong } from '~/composables/useQueue'
import type { Song } from '~~/types'

const queueStore = useQueueStore()
const { queueItemSelection } = useVariantSelection()
const { t } = useI18n()
const { addToQueue } = queueStore
const userStore	= useUserStore()
const { showDemoPlayer } = storeToRefs(userStore)

const props = defineProps({
	song: {
		type: Object as PropType<Song>,
		required: true
	},
	isRequestModal: {
		type: Boolean,
		default: false
	}
})

const emits = defineEmits(['close'])
const dontShow = ref(false)

const closeModal = () => {
	emits('close')
}

const playSong = () => {
	usePlaySong(props.song, props.isRequestModal, t('song.error.play'))
	emits('close')
}

const addSongToQueue = async (song: Song) => {
	if (!song) {
		console.warn('SongConfirmation: Trying to queue undefined song')
		return
	}
	const artistSlug = song.artists.map(artist => artist.slug).join(',')
	const link = `/artists/${artistSlug}/${song.slug}/${song.hash}`
	const queueItem = await queueItemSelection(song)
	emits('close')
	if (!queueItem) { return }
	await addToQueue(queueItem, link, 'Song interrupt confirmation modal', false, t('song.error.queue'))
	if (props.isRequestModal) {
		const { $oruga } = useNuxtApp()
		$oruga.modal.closeAll({ action: 'closeAll' })
	}
}

// Set a cookie to not show this modal again
const setSongConfirmationCookie = () => {
	const dismissSongConfirmation = useCookie<boolean>('dismissSongConfirmation')
	dismissSongConfirmation.value = dontShow.value
}

const hideQueueButton = computed(() => {
	return useDevice().isMobile || showDemoPlayer.value
})
</script>

<style scoped lang="sass">
.checkbox
	margin-top: $spacing-24
.modal-card-subtitle
	max-width: 100%
	padding-right: 0px

:deep(.modal-card-head)
	padding: $spacing-8 $spacing-24 0 $spacing-24 !important

:deep(.modal-body)
	padding: $spacing-16 $spacing-24 $spacing-16 $spacing-24 !important
.modal-button
	margin-top: $spacing-16
	border-bottom-left-radius: 8px
	border-top-left-radius: 8px
</style>
