<template lang="pug">
div
	NuxtLayout
		NuxtPage
		SingaCookieConsent(v-if="!cookieConsent")
	Teleport(to="body" v-if="showMiniPlayer" :disabled="isMobile")
		.fixed-bottom
			PlayerElement
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'
import { useUserStore } from '~/pinia/userStore'

const { loadIterable } = useIterable()

const { isMobile, isDesktop } = useDevice()

const karaokeStore = usePlayerStore()
const { showMiniPlayer } = storeToRefs(karaokeStore)
const cookieConsent = useCookie<boolean>('cookieConsent')

const userStore = useUserStore()
const authStore = useAuthStore()
const { getInitSettings } = useMediaFileStore()
const { loadSegment } = useSegment()
const { initSentry } = useSentry()
const { initFullstory } = useFullstory()

const getUTMTags = () => {
	try {
		const route = useRoute()
		const utmTags = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term']
		const usedUTMTags = Object.keys(route.query).filter(key => utmTags.includes(key)).map(key => [key, route.query[key]])
		if (typeof window !== 'undefined') {
			window.sessionStorage.setItem('usedUTMTags', JSON.stringify(usedUTMTags))
		}
		return usedUTMTags
	} catch (error) {
		console.error('Error getting tags:', error)
		return []
	}
}

function gtag(args: any) { window.dataLayer?.push(...args) }

const gtagConsent = () => {
	const gtm = useGtm()
	if (import.meta.client) {
		window.dataLayer = window.dataLayer || []
		// @ts-ignore
		gtag('consent', 'update', {
			analytics_storage: 'granted',
			ad_storage: 'granted',
			ad_user_data: 'granted',
			ad_personalization: 'granted',
			allow_ad_personalization_signals: true
		})
	}
	const config = useRuntimeConfig()
	config.public.gtm.enabled = true
	gtm?.enable(true)
}

const { setMetaImg } = useMetaTags()
const metaImg = setMetaImg()

useHead({
	meta: [
		...metaImg
	]
})

getUTMTags()

const contextMenuHandler = useDebounceFn(() => {
	const activeDropdown = document.getElementsByClassName('dropdown is-active')
	if (activeDropdown && activeDropdown.length > 0) {
		useEventEmit('contextMenu:close')
	}
}, 10)

if (authStore.isLoggedIn) {
	getInitSettings()
}
onMounted(async () => {
	await userStore.getUser()
	window.addEventListener('resize', contextMenuHandler)
	window.addEventListener('scroll', contextMenuHandler)
	if (import.meta.client && !import.meta.dev) {
		initSentry()
		loadIterable()
		loadSegment()
		consoleLogSingaLogo()
	}
	gtagConsent()
	if (isDesktop) {
		initFullstory()
	}
})

onUnmounted(() => {
	const contextMenuHandler = useDebounceFn(() => {
		const activeDropdown = document.getElementsByClassName('dropdown is-active')
		if (activeDropdown && activeDropdown.length > 0) {
			useEventEmit('contextMenu:close')
		}
	}, 10)
	window.removeEventListener('resize', contextMenuHandler)
	window.removeEventListener('scroll', contextMenuHandler)
})
</script>

<style lang="sass" scoped>
.fixed-bottom
	width: 100%
	position: fixed
	bottom: 0
	left: 0
	z-index: 40
	background: black
</style>
