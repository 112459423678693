<template lang="pug">
.SearchContainer
	.SingaSearchInput(:class="{'expanded': expanded}")
		.search-right(v-if="hasInput" @click.stop.prevent="clearSearch(true)")
			.esc {{ t('search.esc') }}
			SingaIcon(icon="close-circle" size="large")
		SingaField
			SingaInput.queue-search(ref="input" :placeholder="expanded ? t('search.queuePlaceholder') : ''" rounded icon="search-outline" v-model="inputString" @keydown.esc="clearSearch(true)")
</template>

<script setup lang="ts">
import { nextTick } from 'vue'
import { storeToRefs } from 'pinia'

const searchStore = useSearchStore()
const { t } = useI18n()

const { getSearchTerm } = storeToRefs(searchStore)

defineProps({
	expanded: {
		type: Boolean,
		default: false
	}
})

const inputString = ref('')

const input = ref()
const timeoutId = ref(-1)

const emit = defineEmits(['filterChanged', 'focus', 'focusout'])

const clearFocus = () => {
	// Temporary hack (🤞) to prevent a warning from useRoute
	if (useNuxtApp()._processingMiddleware) {
		setTimeout(() => {
			emit('focusout')
		}, 50)
	} else {
		emit('focusout')
	}
}

const hasInput = computed(() => {
	return inputString.value.length > 0
})

watch(inputString, () => {
	if (inputString.value === '') {
		return
	}
	if (timeoutId.value > -1) {
		clearTimeout(timeoutId.value)
		timeoutId.value = -1
	}
	timeoutId.value = window.setTimeout(() => {
		emit('filterChanged', inputString.value)
	}, 1000)
})

watch(() => getSearchTerm.value, () => {
	if (getSearchTerm.value) {
		inputString.value = getSearchTerm.value
	}
})

const clearSearch = function (focusout: boolean) {
	if (focusout) {
		clearFocus()
	}
	inputString.value = ''
	useEventEmit('search:clearSearch')
}

const clickedOutside = function (e: any) {
	inputString.value = ''
	if (input?.value?.$el !== null && input?.value?.$el.contains(e.target)) {
		emit('focus')
	} else {
		clearFocus()
	}
}

onUpdated(async () => {
	await nextTick()
	if (import.meta.client) {
		window.addEventListener('click', clickedOutside)
	}
})

onBeforeUnmount(() => {
	if (import.meta.client) {
		window.removeEventListener('click', clickedOutside)
	}
})
</script>

<style lang="sass" scoped>
.SearchContainer
	position: absolute
	display: flex
	top: 0
	width: 100%
	height: 100%
	justify-content: flex-end
	z-index: 1
	&.expanding
		:deep(.control .input)
			padding: 0 !important

.SingaSearchInput
	height: 48px
	width: 48px
	transition: width .5s ease-in-out

	&.expanded
		width: 100%
	&:not(.expanded)
		height: 48px
		.esc
			display: none
		:deep(.control .input)
			transition: width 5s ease-in-out
			cursor: pointer
			padding: 0 !important
			width: 48px !important
			height: 48px !important
			float: right
		:deep(.control)
			position: relative
			height: 48px
	:deep(.control .icon)
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		width: 24px
		height: 24px
		padding: 0
		.input
			padding: 0 !important

:deep(.input)
	border: transparent !important
	text-align: center
	height: 48px

.search-right
	z-index: 99
	cursor: pointer
	position: absolute
	right: $spacing-24
	display: flex
	margin: $spacing-8 0 $spacing-8 0
	width: 20%
	flex-direction: row
	align-items: flex-start
	justify-content: flex-end
	.icon
		color: $color-grey-50
	.esc
		background-color: $transparent-white-8
		color: $transparent-white-40
		padding: 4px 10px
		border-radius: $radius-default
		margin-right: $spacing-16
		display: none
		@media (min-width: $tablet)
			display: block
</style>
