export const useSinglistSongFavorited = (songId: string, playlistId: string, accessPoint: string, isOfficial: boolean) => {
	const userStore = useUserStore()
	const { segmentEvent } = useSegment()
	const playlistData = {
		access_point: accessPoint,
		user_id: userStore.user?.resource_id,
		singlist_id: playlistId,
		song_id: songId,
		is_official: isOfficial,
		source_page: usePreviousRoute()
	}
	segmentEvent('Song In Singlist Favorited', playlistData)
}
