import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'

export const useIterable = () => {
	const userStore = useUserStore()
	const audioPlayerStore = useAudioPlayerStore()
	const route = useRoute()
	const { $singaApi } = useNuxtApp()
	let iterableInitialized: boolean = false
	let iterableLoaded: boolean = false
	let iterableLogout: any = null

	const getInAppMessages = async () => {
		const excludeViews: any = ['login', 'account', 'search-results', 'search-results-search']
		const routeName = String(route.name).split('___').shift()
		if (import.meta.server) { return }
		if (!iterableInitialized) { return }
		if (audioPlayerStore.playing) { return }
		if (excludeViews.includes(routeName)) { return }

		try {
			// @ts-ignore How do I fix this?
			const { request, triggerDisplayMessages } = window['@iterable/web-sdk'].getInAppMessages(
				{
					displayInterval: 10000,
					count: 20,
					packageName: 'my-website'
				},
				{ display: 'immediate' }
			)
			await request()
		} catch (error) {
			console.log('Error getting in app messages', error)
		}
	}

	const initialize = async () => {
		if (import.meta.server) { return }
		if (!iterableLoaded) { return }
		if (!userStore.user?.resource_id) { return }
		if (iterableInitialized) { return }
		// console.log('------------ TRY INITIALIZE ITERABLE ------------')
		// @ts-ignore How do I fix this?
		window['@iterable/web-sdk'].config.setConfig({ baseURL: 'https://api.eu.iterable.com/api' })
		// @ts-ignore How do I fix this?
		const { setUserID, logout } = window['@iterable/web-sdk'].initialize(
			useRuntimeConfig().public.iterableKey,
			async () => {
				const { data, error } = await $singaApi.Me.JWT.get()
				if (error.value) {
					console.log(error.value)
				} else {
					return data.value.jwt
				}
			}
		)
		iterableLogout = logout
		try {
			await setUserID(userStore.user?.resource_id)
			iterableInitialized = true
			if (userStore.user?.email) {
				// @ts-ignore How do I fix this?
				window['@iterable/web-sdk'].updateUser({ dataFields: { email: userStore.user?.email } })
			}
			await getInAppMessages()
		} catch (error) {
			console.log('Error initializing iterable', error)
		}
	}

	const loadIterable = () => {
		if (import.meta.server) { return }
		useHead({
			script: [
				{
					src: 'https://unpkg.com/@iterable/web-sdk/index.js',
					onload: (el) => {
						iterableLoaded = true
						console.log('------------ ITERABLE LOADED ------------')
						initialize()
					}
				}
			]
		}, { mode: 'client' })
	}

	watch(
		() => userStore.user,
		() => {
			if (import.meta.dev || import.meta.server) { return }
			if (userStore.user?.resource_id && iterableLoaded && !iterableInitialized) { initialize() }
			if (userStore.user == null) {
				// console.log('------------ TRY LOGOUT ITERABLE ------------')
				iterableLogout()
				iterableInitialized = false
			}
		}
	)

	if (!import.meta.server) {
		window.addEventListener('focus', event => getInAppMessages())
	}

	return { loadIterable, initialize, getInAppMessages }
}
