<template lang="pug">
.notification-message
	span &nbsp;{{ t('playlist.title') }}&nbsp;
	a(@click="openLink(playlistLink)") {{ playlistTitle }}
	span &nbsp;{{ t('playlist.title.created') }}&nbsp;
</template>

<script setup lang="ts">
const { t } = useI18n()

const emit = defineEmits(['close'])

defineProps({
	playlistTitle: {
		type: String,
		required: true
	},
	playlistLink: {
		type: String,
		required: true
	}
})

const localePath = useLocalePath()

const openLink = (link: any) => {
	navigateTo(localePath(link))
	emit('close')
}
</script>
