import vue3GoogleLogin from 'vue3-google-login'

export default defineNuxtPlugin({
	enforce: 'post',
	parallel: true,
	hooks: {
		'app:created'() {
			const config = useRuntimeConfig()
			const nuxtApp = useNuxtApp()
			nuxtApp.vueApp.use(vue3GoogleLogin, {
				clientId: config.public.GOOGLE_CLIENT_ID
			})
		}
	}
})
