<template lang="pug">
.AuthInit(ref="el")
	div(v-if="!conversionModal")
		.auth-header
			p.auth-sub {{ t('auth.loginText') }}
		.auth-body
			//- SingaButton.is-full-width.is-regular.button-social.button-apple.has-icon(icon-left="logo-apple") {{ t('auth.apple') }}
			SingaButton.is-full-width.is-regular.button-social.button-facebook.has-icon(icon-left="logo-facebook" @click="checkFacebookLogin" :disabled="loading") {{ t('auth.facebook') }}
			SingaButton.is-full-width.is-regular.button-social.button-google(@click="googleLogin" :disabled="loading")
				img.icon(src="~/assets/images/logo-google.svg")
				| {{ t('auth.google') }}
			.signup-text-wrapper
				.auth-additional
					span {{ t('auth.emailContinue') }}
			form(@submit.prevent="checkUser(updatedUsername)")
				SingaField(:variant="userNameError !== '' ? 'danger' : ''" :message="userNameError" :label="t('auth.email.label')" labelFor="loginEmail")
					SingaInput.input-regular(
						@input="userNameError = ''"
						rounded
						type="text"
						v-model="updatedUsername"
						:placeholder="t('auth.email.placeholder')"
						icon="mail"
						auto-complete="updatedUsername"
						id="loginEmail")
				SingaButton.is-transparent-dark.is-regular.is-full-width(native-type="submit")
					CommonLoadingAnimation(v-if="loading" :timeOut="0" :size="23")
					span(v-else) {{ t('general.next') }}
	.ConversionModal(v-else)
		.conversion-header
			p.conversion-title {{ conversionModalTitle }}
			p.conversion-subtitle {{ t('auth.loginText.conversionModal.subtitle') }}
		.auth-body
			//- SingaButton.is-full-width.is-regular.button-social.button-apple.has-icon(icon-left="logo-apple") {{ t('auth.apple') }}
			SingaButton.is-full-width.is-regular.button-social.button-facebook.has-icon(v-if="!showEmail" icon-left="logo-facebook" @click="checkFacebookLogin(); closeModal()" :disabled="loading") {{ t('auth.facebook') }}
			SingaButton.is-full-width.is-regular.button-social.button-google(v-if="!showEmail" @click="googleLogin(); closeModal()" :disabled="loading")
				img.icon(src="~/assets/images/logo-google.svg")
				| {{ t('auth.google') }}
			SingaButton.is-transparent-dark.is-regular.is-full-width(v-if="!showEmail" @click="showEmail = !showEmail")
				CommonLoadingAnimation(v-if="loading" :timeOut="0" :size="23")
				span(v-else) {{ t('modal.conversion.email') }}
			form(@submit.prevent="checkUser(updatedUsername)" v-else)
				SingaField(:variant="userNameError !== '' ? 'danger' : ''" :message="userNameError" :label="t('auth.email.label')" labelFor="loginEmail")
					SingaInput.input-regular(
						@input="userNameError = ''"
						rounded
						type="text"
						v-model="updatedUsername"
						:placeholder="t('auth.email.placeholder')"
						icon="mail"
						auto-complete="updatedUsername"
						id="loginEmail")
				SingaButton.is-transparent-dark.is-regular.is-full-width(native-type="submit")
					CommonLoadingAnimation(v-if="loading" :timeOut="0" :size="23")
					span(v-else) {{ t('general.next') }}
		p.log-in-text
			| {{ t('modal.conversion.logIn') }}
			|
			NuxtLink.log-in-link(:to="localePath('/login/')" @click="closeModal") {{ t('modal.conversion.logIn.link') }}
</template>

<script setup lang="ts">
import { googleTokenLogin } from 'vue3-google-login'

const FacebookNotAvailable = resolveComponent('SongNotificationsFacebookNotAvailable')
const authStore = useAuthStore()
const config = useRuntimeConfig()
const { $singaApi, $oruga } = useNuxtApp()
const userNameError = ref('')
const userStore = useUserStore()
const { segmentEvent } = useSegment()
const { replaceDemoWithFullSong } = useQueueStore()

const updatedUsername = ref('')
const validatedUsername = ref('')
const el = ref<HTMLDivElement>()
const loading = ref(false)
const { t } = useI18n()
const localePath = useLocalePath()

const props = defineProps({
	username: {
		type: String,
		default: ''
	},
	conversionModal: {
		type: Boolean,
		default: false
	},
	action: {
		type: String,
		default: 'finish'
	}
})

const conversionModalTitle = computed(() => {
	return props.action === 'finish' ? t('auth.loginText.conversionModal') : t('auth.loginText.conversionModal.pause')
})

const emit = defineEmits([
	'email-login',
	'email-signup',
	'update-username',
	'update-height'
])

const closeModal = () => {
	$oruga.modal.closeAll({ action: 'closeAll' })
}

const getUTMTags = () => {
	const utmTagsString = window.sessionStorage.getItem('usedUTMTags') || '[]'
	const utmTagsArray = JSON.parse(utmTagsString)
	const utmTagsObject = Object.fromEntries(utmTagsArray)
	return utmTagsObject
}

const showEmail = ref(false)
const showFacebookError = () => {
	loading.value = false
	$oruga.notification.open({
		component: FacebookNotAvailable,
		variant: 'warning'
	})
}
const getFacebookTokens = (response: any) => {
	const utmTagsObject = getUTMTags()
	loading.value = true
	if (!response || !response.authResponse) {
		loading.value = false
		return
	}

	const payload = {
		provider: 'facebook',
		grant_type: 'social_token',
		token: response.authResponse.accessToken,
		expires: new Date(response.authResponse.data_access_expiration_time).toISOString()
	}
	try {
		authStore.getSocialTokens(undefined, payload).then((res: any) => {
			if (res && Number(res.isRegistration) === 1) {
				$oruga.notification.open({
					message: t('account.created'),
					icon: 'checkmark-outline',
					iconSize: 'medium'
				})
				segmentEvent('User Signed Up', {
					provider: 'Facebook',
					user_id: userStore.user?.resource_id,
					...utmTagsObject
				})
				replaceDemoWithFullSong()
			} else {
				segmentEvent('User Logged In', {
					provider: 'Facebook',
					user_id: userStore.user?.resource_id
				})
			}
			loading.value = false
		})
	} catch (err) {
		$oruga.notification.open({
			message: err,
			variant: 'warning'
		})
		console.log(err)
		loading.value = false
	}
}

const facebookLogin = () => {
	FB.login((response) => {
		if (!response) {
			showFacebookError()
			return
		}
		getFacebookTokens(response)
	})
}

const checkFacebookLogin = () => {
	if (!window.FB) {
		showFacebookError()
		return
	}
	FB.getLoginStatus((response) => {
		loading.value = true
		if (!response) {
			showFacebookError()
			return
		}
		if (response?.status !== 'connected') {
			facebookLogin()
		} else {
			getFacebookTokens(response)
		}
	})
}

const googleLogin = async () => {
	loading.value = true
	const utmTagsObject = getUTMTags()
	// This callback will be triggered when the user selects or login to
	// his Google account from the popup
	await googleTokenLogin().then((response: any) => {
		const expiration = new Date((response.expires_in * 60) * 60).toISOString()
		const payload = {
			provider: 'google',
			grant_type: 'social_token',
			token: response.access_token,
			expires: expiration
		}
		try {
			authStore.getSocialTokens(undefined, payload).then((res) => {
				if (res && Number(res.isRegistration) === 1) {
					$oruga.notification.open({
						message: t('account.created'),
						icon: 'checkmark-outline',
						iconSize: 'medium'
					})
					segmentEvent('User Signed Up', {
						provider: 'Google',
						user_id: userStore.user?.resource_id,
						...utmTagsObject
					})
					replaceDemoWithFullSong()
				} else {
					segmentEvent('User Logged In', {
						provider: 'Google',
						user_id: userStore.user?.resource_id
					})
				}
				loading.value = false
			})
		} catch (err) {
			$oruga.notification.open({
				message: err,
				variant: 'warning'
			})
			loading.value = false
			console.log(err)
		}
	}).catch((error: any) => {
		console.log(error)
		loading.value = false
	})
}

const continueEmailFlow = (userExists = false) => {
	if (userExists) {
		emit('email-login')
	} else {
		emit('email-signup')
	}
}

const updateUsername = () => {
	emit('update-username', validatedUsername.value)
}

const validateUsername = (updatedUsername: string) => {
	userNameError.value = ''
	const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

	// if contains @, check that valid email regex
	if (updatedUsername !== '' && updatedUsername.includes('@')) {
		if (regex.test(updatedUsername)) {
			validatedUsername.value = updatedUsername.trim()
			return true
		} else {
			userNameError.value = t('auth.email.invalid')
			return false
		}
	} else if (updatedUsername !== '') {
		validatedUsername.value = updatedUsername.trim()
		return true
	} else {
		userNameError.value = t('general.errors.empty')
		return false
	}
}

const checkUser = async (updatedUsername: string) => {
	const validated = validateUsername(updatedUsername)
	loading.value = true
	const params: { email?: string, username?: string } = {}

	if (String(updatedUsername).match('@') && validated) {
		params.email = validatedUsername.value
	} else if (validated) {
		params.username = validatedUsername.value
	} else {
		userNameError.value = t('signup.email.errorInvalid')
		loading.value = false
		return
	}
	try {
		const { data } = await $singaApi.Users.UserExists(params)
		const userExists = data.value.email || data.value.username
		updateUsername()
		if (!userExists && !String(updatedUsername).match('@')) {
			userNameError.value = t('signup.email.errorInvalid')
		} else {
			continueEmailFlow(userExists)
		}
	} catch (err: any) {
		if (err?.response?.status === 400) {
			// add all values from err.response "email" and "username"
			if (err.response.email) {
				userNameError.value += err.response.email.join(', ')
			} else {
				userNameError.value += err.response.username.join(', ')
			}
		}
	}
	loading.value = false
}

const updateHeightOnResize = useDebounceFn(() => {
	emit('update-height', el.value?.offsetHeight)
}, 100)

onMounted(() => {
	updatedUsername.value = props.username
	emit('update-height', el.value?.offsetHeight)
	window.addEventListener('resize', updateHeightOnResize)
	if (window.FB) {
		FB.init({
			appId: config.public.FACEBOOK_APP_ID,
			autoLogAppEvents: true,
			xfbml: true,
			version: 'v19.0'
		})
	}
})

onUnmounted(() => {
	window.removeEventListener('resize', updateHeightOnResize)
})
</script>

<style lang="sass" scoped>
.signup-text-wrapper
	padding: $spacing-8 0 $spacing-16
	.auth-additional
		color: $color-grey-30
		text-align: center
		line-height: 0.1em
		span
			color: $color-grey-30
			padding: $spacing-16
			display: block
	@media (min-width: $tablet)
		padding: $spacing-24 $spacing-40
		.auth-additional
			border-bottom: 1px solid $color-grey-50
			margin: $spacing-16 0
			span
				display: inline
				background-color: $color-grey-80
.auth-body
	button:not(:last-child)
		margin-bottom: $spacing-16
.button-social
	border-color: transparent
	outline: none
	&:hover
		border-color: transparent
		outline: none
	.icon
		border-radius: $radius-round
		overflow: hidden
.button-facebook
	background-color: #1877F2
	color: white
	&:hover
		background-color: #185BB4
	&:active
		background-color: #144890

.button-apple
	background-color: $color-black
	color: white
	width: 100%
	&:hover
		background-color: $color-grey-90
	&:active
		background-color: $color-grey-100
.button-google
	background-color: $color-white
	color: $color-black
	&:hover
		background-color: $color-grey-20
	&:active
		background-color: $color-grey-40
	.icon
		margin-left: $spacing-8 !important
.AuthInit
	position: absolute
	left: 0
	right: 0

.conversion-title
	@include fontSize(3xl)
	@include font(basier, bold)
	text-align: center
	margin-top: 0px

	@media (min-width: $tablet)
		@include fontSize(4xl)

.conversion-header
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center

.conversion-subtitle
	@include fontSize(l)
	@include font(basier, regular)
	margin: $spacing-16 0 $spacing-24 0
	text-align: center

.log-in-text
	text-align: center
	margin-top: $spacing-24
	@include fontSize(s)
	color: $color-grey-50

.log-in-link
	color: $color-grey-50
	text-decoration: underline
	&:hover
		color: $color-grey-40

.ConversionModal
	margin: 0 auto
	max-width: 400px
</style>
