import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

const pagesToRedirect = [
	'/choose-plan',
	'/voucher',
	'/gift-cards',
	'/account/billing'
]

function removeLocaleFromUrl(url: string): string {
	const localePattern = /^\/(fi|en|se|us)\//
	const cleanedUrl = url.replace(localePattern, '/')
	return cleanedUrl.replace(/\/+/g, '/')
}

export default defineNuxtRouteMiddleware((to) => {
	const config = useRuntimeConfig()
	const resolveUrl = config.public.SINGA_RESOLVE_PREFIX
	if (!config.public.legacyPaymentsEnabled) {
		const path = removeLocaleFromUrl(to.path)
		const shouldRedirect = pagesToRedirect.some(page => path.includes(page))
		if (shouldRedirect) {
			navigateTo(`${resolveUrl}${path}`, {
				external: true,
				redirectCode: 302
			})
		}
	}
})
