<template lang="pug">
.feedback-popup.popup(:class="[position, label === '' ? 'no-label' : '']")
	SingaIcon.feedback-icon(:icon="icon" size="xlarge")
	span.label(v-if="label !== ''") {{ label }}
</template>

<script setup lang="ts">
import { promiseTimeout } from '@vueuse/core'

const emit = defineEmits(['finished'])
defineProps({
	icon: {
		type: String,
		required: true
	},
	label: {
		type: String,
		required: true
	},
	position: {
		type: String,
		required: true
	}
})
onMounted(async () => {
	await promiseTimeout(1000)
	emit('finished')
})
</script>

<style lang="sass" scoped>
.feedback-popup
	position: absolute

	display: flex
	align-items: center
	justify-content: space-evenly
	flex-direction: column

	width: 96px
	height: 96px
	background: $transparent-black-56
	border-radius: 50%
	opacity: 0
	z-index: 9999

	@media (min-width: $tablet)
		width: 136px
		height: 136px
	&.no-label
		justify-content: center
	&.center
		left: 0
		right: 0
		top: 0
		bottom: 0
		margin: auto
	&.bottom
		left: 0
		right: 0
		bottom: $spacing-32
		margin: 0 auto
	&.right
		right: $spacing-32
		top: 0
		bottom: 0
		margin: auto 0
	&.left
		left: $spacing-32
		top: 0
		bottom: 0
		margin: auto 0
	.label
		color: $color-grey-30
	:deep(.icon)
		width: 52px
		height: 52px
.popup
	opacity: 1
	animation: grow 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards
@keyframes grow
	from
		scale: 1
		opacity: 1
	to
		scale: 1.5
		opacity: 0
</style>
