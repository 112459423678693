<template lang="pug">
.EmailLogin(ref="el")
	.auth-header
		template(v-if="props.userExists")
			p.auth-sub(v-html="t('auth.signup.existing')")
		template(v-else)
			p.auth-sub(v-html="t('auth.signup.newAccount')")
	.auth-body
		template(v-if="props.userExists")
			SingaField(:label="t('auth.email.label')" labelFor="loginEmail")
				SingaInput.input-regular(
					rounded
					type="text"
					v-model="updatedUsername"
					:placeholder="t('auth.email.placeholder')"
					icon="mail"
					size="is-small"
					disabled
					@update:modelValue="updateUsername"
					id="loginEmail")
			SingaField(:variant="loginError !== '' ? 'danger' : ''" :message="loginError" :label="t('auth.password.label')" labelFor="loginPassword")
				SingaInput.input-regular(
					ref="passwordInputElem"
					@keyup.enter="login"
					rounded
					type="password"
					v-model="password"
					@update:modelValue="loginError = ''"
					:placeholder="t('auth.password.placeholder')"
					icon="key"
					id="loginPassword")
			SingaSubmitButton.is-full-width.is-transparent-dark.is-regular(:disabled="loginError !== ''" @click="login" :isLoading="isPasswordLoginLoading" buttonCTA="Login")
			.link
				a(@click="cancelLogin") {{ t('general.cancel') }}
		template(v-else)
			SingaField(:message="emailErrors" messageClass="error" :variant="emailErrors !== '' ? 'danger' : ''" :label="t('auth.email.label')" labelFor="loginEmail")
				SingaInput.input-regular(
					rounded
					v-on:focusout="validateEmail"
					type="text"
					v-model="emailField"
					:placeholder="t('auth.email.placeholder')"
					@update:modelValue="emailError = ''"
					icon="mail"
					disabled
					id="loginEmail")
			SingaField(:message="emailConfirmError" messageClass="error" :variant="emailConfirmError !== '' ? 'danger' : ''" :label="t('auth.email.confirm.label')" labelFor="loginEmailConfirm")
				SingaInput.input-regular(
					rounded
					ref="confirmEmailElem"
					v-on:focusout="validateEmailConfirm"
					@update:modelValue="emailConfirmError = ''"
					type="text"
					v-model="emailConfirm"
					:placeholder="t('auth.signup.confirmPlaceholder')"
					icon="mail"
					id="loginEmailConfirm")
			SingaField(:message="passWordErrors" messageClass="error" :variant="passWordErrors !== '' ? 'danger' : ''" :label="t('auth.password.label')" labelFor="password")
				SingaInput.input-regular(
					ref="passwordInputElem"
					v-on:focusout="validatePassword"
					@keyup.enter="signup"
					@update:modelValue="passwordError = ''"
					rounded
					type="password"
					v-model="password"
					:placeholder="t('signup.password.createPassword')"
					icon="key"
					id="password")

			SingaSubmitButton.is-full-width.is-transparent-dark.is-regular(
				:disabled="!canSubmit"
				@click="signup"
				:isLoading="isPasswordLoginLoading"
				:buttonCTA="t('auth.signup.createCTA')")
			.link
				a(@click="cancelLogin") {{ t('general.cancel') }}
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import { storeToRefs } from 'pinia'

const authStore = useAuthStore()
const userStore = useUserStore()
const queueStore = useQueueStore()

const { replaceDemoWithFullSong } = queueStore
const { error: authApiError } = storeToRefs(authStore)
const { segmentEvent } = useSegment()

const updatedUsername = ref('')
const emailConfirm = ref('')
const emailField = ref('')
const el = ref<HTMLDivElement>()
const { t } = useI18n()
const loginError = ref('')
const localePath = useLocalePath()
const emailError = ref('')
const emailConfirmError = ref('')
const passwordError = ref('')
const signupError = ref('')

const props = defineProps({
	username: {
		type: String,
		required: true
	},
	userExists: {
		type: Boolean,
		required: true
	}
})
const emit = defineEmits([
	'reset-cancel',
	'update-username',
	'update-height'

])
const cancelLogin = () => {
	emit('reset-cancel')
}

const isPasswordLoginLoading = ref(false)
const route = useRoute()
const password = ref('')
const passwordInputElem = ref(null as any)
const confirmEmailElem = ref(null as any)

const canSubmit = computed(() => {
	// are not empty
	return !isPasswordLoginLoading.value && !passwordError.value && !emailError.value && !emailConfirmError.value && !signupError.value && password.value !== '' && emailField.value !== '' && emailConfirm.value !== ''
})

const redirectUrl = computed(() => {
	return route.query.redirect ? route.query.redirect as string : localePath('/discover')
})
// create a getter with argument, check if authApiError[argument] exists
const getApiError = computed(() => {
	return (fieldName: any) => {
		// return if authApiError is not Null and has the field name
		return authApiError.value && authApiError.value[fieldName] ? authApiError.value[fieldName] : []
	}
})

// merge the errors from the API and the local validation
const emailErrors = computed(() => {
	return [emailError.value, ...getApiError.value('email')].filter(e => e !== '').join('\n')
})
const passWordErrors = computed(() => {
	return [passwordError.value, ...getApiError.value('password')].filter(e => e !== '').join('\n')
})

const login = async () => {
	isPasswordLoginLoading.value = true
	console.log('login started')
	await authStore.getTokens({ username: props.username, email: undefined, password: password.value }, redirectUrl.value)
	if (authStore.getError) {
		loginError.value = t('signup.password.authError')
	} else {
		segmentEvent('User Logged In', {
			provider: 'Singa',
			user_id: userStore.user?.resource_id
		})
		replaceDemoWithFullSong()
	}
	isPasswordLoginLoading.value = false
	authStore.clearLoginError()
}
// Email confirm (see that matches the latter input)
const validateEmailConfirm = () => {
	emailConfirmError.value = ''
	if (emailField.value !== emailConfirm.value) {
		emailConfirmError.value = t('signup.email.confirmError')
	}
}

const updateUsername = () => {
	emit('update-username', updatedUsername.value)
}

// is Email valid (regex)
const validateEmail = () => {
	emailError.value = ''
	const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
	if (!regex.test(emailField.value)) {
		emailError.value = t('signup.email.errorInvalid')
	}
	if (emailField.value !== '') {
		validateEmailConfirm()
	}
}

// Password (min length 8 characters), no spaces at the beginning or end
const validatePassword = () => {
	passwordError.value = ''
	if (password.value.length < 8) {
		passwordError.value += t('signup.password.errorLimit')
	}
	// if password starts or ends with a space
	if (password.value.startsWith(' ') || password.value.endsWith(' ')) {
		passwordError.value += t('signup.password.errorSpaces')
	}
}

const signup = async () => {
	signupError.value = ''
	validateEmail()
	validateEmailConfirm()
	validatePassword()
	if (!canSubmit.value) {
		return
	}
	isPasswordLoginLoading.value = true
	await authStore.registerUser({ email: emailField.value, username: undefined, password: password.value }, redirectUrl.value, t)
	const utmTagsString = window.sessionStorage.getItem('usedUTMTags') || '[]'
	const utmTagsArray = JSON.parse(utmTagsString)
	const utmTagsObject = Object.fromEntries(utmTagsArray)
	segmentEvent('User Signed Up', {
		provider: 'Singa',
		user_id: userStore.user?.resource_id,
		...utmTagsObject
	})
	if (authStore.getError) {
		signupError.value = authStore.getError.error_description
	} else {
		replaceDemoWithFullSong()
	}
	isPasswordLoginLoading.value = false
}

const updateHeightOnResize = useDebounceFn(() => {
	emit('update-height', el.value?.offsetHeight)
}, 100)

onMounted(() => {
	emit('update-height', el.value?.offsetHeight)
	window.addEventListener('resize', updateHeightOnResize)
	if (props.userExists) {
		updatedUsername.value = props.username
		passwordInputElem.value?.focus()
	} else {
		emailField.value = props.username
		confirmEmailElem.value?.focus()
	}
})

onUnmounted(() => {
	window.removeEventListener('resize', updateHeightOnResize)
	authStore.clearLoginError()
})
</script>

<style lang="sass" scoped>
.auth-sub
	white-space: break-spaces !important
.wrap
	position: relative
.link
	margin-top: $spacing-16
.EmailLogin
	position: absolute
	left: 0
	right: 0
</style>
