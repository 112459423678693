<template lang="pug">
SingaButton(:disabled="isLoading")
	span.button-text(v-if="!isLoading") {{ props.buttonCTA }}
	CommonLoadingAnimation(:timeOut="0" :size="23" v-else)
</template>

<script setup lang="ts">
const props = defineProps({
	buttonCTA: {
		type: String,
		required: true
	},
	isLoading: {
		type: Boolean,
		required: true
	}
})
</script>

<style lang="sass" scoped>
.button-text
	@include font(basier, medium)
</style>
