<script>
import { OSlider } from '@oruga-ui/oruga-next/dist/esm/slider'

export default {
	name: 'SingaSlider',
	extends: OSlider
}
</script>

<style lang="sass" scoped>
.b-slider
	margin: 0

.mini-player
	&:not(:hover)
		.b-slider-thumb-wrapper
			opacity: 0
			visibility: hidden
	.b-slider-track, .b-slider-fill
		border-radius: 0
	.b-slider-track
		background-color: $transparent-white-12
	.b-slider-thumb-wrapper
		//transition: opacity 200ms, visibility 200ms

.volume-slider
	height: 40px
	border-radius: $radius-round
	overflow: hidden
	.b-slider-track
		height: 40px
		border-radius: $radius-round
		overflow: hidden
		background-color: $transparent-white-12
	.b-slider-fill
		border-radius: 0
		background-color: $transparent-white-40
</style>
