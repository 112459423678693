// @ts-ignore No idea why this import is giving an error
import AddedToLibrary from '../components/song/notifications/AddedToLibrary.vue'
import { useNuxtApp } from '#imports'

export const useFavorites = () => {
	const { favorites, addFavorite, deleteFavorite } = useUserStore()
	const { $singaApi, $oruga } = useNuxtApp()
	const loadingFavorite = ref(false)
	type Nullable<T> = T | null
	const favorited: Ref<Nullable<boolean>> = ref(false)
	const { segmentEvent } = useSegment()

	/**
 * Get the favorite status of a playlist, artist, or song
 *
 * @param {string} objTypeToCheck - Playlist, artist, or song
 * @param {number} id - ID of the object
 * @param {boolean} isToggle - Whether this was called from the toggleFavoriteStatus function
 */
	const getFavoriteStatus = (objTypeToCheck: string, id: number, isToggle = false) => {
		if (!isToggle) { loadingFavorite.value = true }
		const favoritesList = favorites[objTypeToCheck as keyof typeof favorites]
		if (favoritesList && favoritesList.length > 0 && favoritesList.includes(id)) {
			if (!isToggle) { loadingFavorite.value = false }
			return true
		} else {
			if (!isToggle) { loadingFavorite.value = false }
			return false
		}
	}

	// Show a toast message when the favorite is added or removed
	const showMessage = (remove: boolean, title: string, link: string) => {
		const lengthCheckedTitle = useTruncate(title)
		$oruga.notification.open({
			component: AddedToLibrary,
			props: {
				itemLink: link,
				itemTitle: lengthCheckedTitle,
				removed: remove
			},
			icon: 'checkmark-outline',
			iconSize: 'medium'
		})
	}

	/**
 * Toggles the favorite status of a playlist, artist, or song
 *
 * @param {string} objTypeToCheck - Playlist, artist, or song
 * @param {number} id - ID of the object
 * @param {string} title - Title of the object
 * @param {string} link - Link the the object (e.g. song page)
 * @param {boolean} songContextMenu - Whether this was called from the song context menu
 * @param {object} segmentData - Data for the segment event
 * @param {boolean} playlistContexMenu - Whether this was called from the playlist context menu
 */
	const toggleFavoriteStatus = async (
		objTypeToCheck: string,
		id: number,
		title: string,
		link: string,
		songContextMenu = false,
		segmentData: object = {},
		playlistContexMenu = false
	) => {
		loadingFavorite.value = true
		const favoriteGroup = ref('')
		if (objTypeToCheck === 'songs') {
			favoriteGroup.value = 'FavoriteSongs'
		} else if (objTypeToCheck === 'artists') {
			favoriteGroup.value = 'FavoriteArtists'
		} else if (objTypeToCheck === 'playlists') {
			favoriteGroup.value = 'FavoritePlaylists'
		}

		const response = getFavoriteStatus(objTypeToCheck, id, true)
		if (response) {
			showMessage(true, title, link)
			await $singaApi.Favorites[favoriteGroup.value].deleteFavorite(id)
			deleteFavorite(objTypeToCheck, id)
			favorited.value = false
			if (objTypeToCheck === 'playlists') {
				segmentEvent('Singlist Unfavourite', segmentData)
				useEventEmit('libraryPlaylists:reloadFavorites')
				const favoritesList = favorites[objTypeToCheck as keyof typeof favorites]

				// This checks if there's anything else in the favorites list except undefined values. This is probably not a good way of handling the list of favorites
				if (!favoritesList.some(Boolean)) {
					useEventEmit('libraryFavoritePlaylists:removeLastPlaylist')
				}
			}
			if (objTypeToCheck === 'songs') {
				segmentEvent('Song Unfavorited', segmentData)
			}
			if (objTypeToCheck === 'artists') {
				segmentEvent('Artist Unfavorited', segmentData)
			}
		} else {
			await $singaApi.Favorites[favoriteGroup.value].addFavorite(id)
			addFavorite(objTypeToCheck, id)
			favorited.value = true
			showMessage(false, title, link)
			if (objTypeToCheck === 'playlists') {
				segmentEvent('Singlist Favourite', segmentData)
			}
			if (objTypeToCheck === 'songs') {
				segmentEvent('Song Favorited', segmentData)
			}
			if (objTypeToCheck === 'artists') {
				segmentEvent('Artist Favorited', segmentData)
			}
		}
		loadingFavorite.value = false
		if (songContextMenu) { useEventEmit('song:getFavoriteStatus', id) }
		if (playlistContexMenu) { useEventEmit('libraryPlaylist:getFavoriteStatus', id) }
	}

	return { loadingFavorite, getFavoriteStatus, favorites, favorited, toggleFavoriteStatus }
}
