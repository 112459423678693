export const useLyrics = () => {
	const outroStart = (lyrics: any) => {
		if (!lyrics) {
			console.error('useLyrics: no lyrics')
			return 0
		}
		const lines = lyrics.singers.map((item: any) => item.lines)
		const words = [].concat(...lines).map((item: any) => item.words)
		const syllables = [].concat(...words).map((item: any) => item.syllables)
		const ends = [].concat(...syllables)
		const maxEnd = Math.max(...ends.map((item: any) => item.end))
		return Math.ceil(maxEnd / 1000)
	}
	return {
		outroStart
	}
}
