import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default defineNuxtPlugin({
	enforce: 'post',
	parallel: true,
	hooks: {
		'app:created'() {
			const nuxtApp = useNuxtApp()
			nuxtApp.vueApp.use(VueVirtualScroller)
		}
	}
})
