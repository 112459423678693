<template lang="pug">
div
	.header
		transition(name="fade")
			.header-texts(v-show="showHeaderText")
				span.queue-title {{ t('player.queue') }}
				span.songs-info {{ t('playlists.songs', { count: queue.length }) }} · {{ queueLengthMinutes }}
		SearchQueueSearchInput.search-input(
			@filterChanged="setSearchString"
			@focus="toggleSearch(true)"
			@focusout="handleFocusOut"
			@mousedown="handleMouseDown"
			@mouseup="handleMouseUp"
			:queueMode="true"
			:class="{'expanded': showSearch, 'expanding': expandingSearchBar}"
			:expanded="showSearch"
			ref="searchInput"
			v-if="!showDemoPlayer"
		)
	.loading-wrap
		SingaLoading(:active.sync="loading" v-if="!scrollToLoad")
			CommonLoadingAnimation
	.search-songs(v-if="searchString")
		SongItemPlayerSearch(v-for="song in songs" :key="song.id" :song="song" :searchMode="true")
		.load-more-wrap(ref="loadMoreTrigger" v-if="songs.length > 0")
			SingaButton.is-regular.is-transparent-dark(@click="loadMoreContent()" v-if="nextPage && !scrollToLoad") {{ t('general.loadMore') }}
			CommonLoadingAnimation(v-else-if="scrollToLoad && loading")
		.empty-queue-wrap(v-if="searchString && songs && songs.length === 0 && !loading")
			.truncate
				span {{ t('search.term.notFound') }} "{{searchString}}" :(
	template(v-else-if="isSearchView && !searchString && historySongs.length > 0")
		.recently-sung
			span.recently-sung-text {{ t('player.queue.recentlySung') }}
		SongItemPlayerSearch(v-for="song in historySongs" :key="song.id" :song="song.song" layout="row")
		SingaButton.is-full-ghost.see-all-button(v-if="hasNextPage" @click="goToSongHistory()") {{ t('general.seeAll') }}
	template(v-else-if="hasSongs && !isSearchView")
		ol.queue-songs
			SongItemMiniQueue(v-if="firstInQueue" :song="firstInQueue" class="queue-playing")
			draggable(
				v-if="hasSongs"
				v-model="currentQueue"
				:animation="200"
				handle='.draggable-item'
				ghost-class="ghost"
				class="draggable"
				item-key="entry.id"
				:component-data="{ name: 'list' }"
				)
				template(#item="{ element }")
					li.drag-animation
						SongItemMiniQueue.draggable-item(:song="element" :timeUntilSong="timeUntilThisSongInQueue(element.entryID)" :key="element.entry.id")
	.button-wrap(v-if="hasSongs && !searchString && restOfQueue.length && !isSearchView")
		SingaButton.empty-queue.is-transparent-dark.rounded(icon-left="trash-outline" size="small" @click="emptyQueue()") {{ t('player.emptyQueue') }}
	template(v-else-if="!hasSongs && !isSearchView")
		.empty-queue-wrap
			span {{ t('player.queueIsEmpty') }}
	.demo-player-cta(v-if="showDemoPlayer")
		img(src="~/assets/images/queue_placeholder.svg")
		img(src="~/assets/images/queue_placeholder.svg")
		span.demo-cta-text.top {{ t('miniQueue.demoPlayer.cta') }}
		NuxtLink(:to="localePath('/login')")
			SingaButton.is-primary.is-regular {{ t('button.demoPlayer.cta') }}
		span.demo-cta-text {{ t('miniQueue.demoPlayer.cta.subtitle') }}
</template>

<script setup lang="ts">
import { useInfiniteScroll } from '@vueuse/core'
import draggable from 'vuedraggable'
import { storeToRefs } from 'pinia'
import { useQueueStore } from '~/pinia/queueStore'
import { useSongHistory } from '~~/composables/library/useSongHistory'

const { loadSongs: loadSongHistory, hasNextPage, songHistory: songHistorySongs } = useSongHistory()

const { t } = useI18n()
const queueStore = useQueueStore()

const { emptyQueue, setQueue } = queueStore
const { firstInQueue, hasSongs, restOfQueue, queue, queueLengthPrecise } = storeToRefs(queueStore)

const { setSearchFilter, songs, loading, searchString, loadSongs, nextPage } = useQueueSearch()
const loadMoreTrigger = ref()

const queueLengthMinutes = computed(() => {
	const queueLength = queueLengthPrecise.value
	const minutes = Math.floor(queueLength / 60)
	const seconds = queueLength - minutes * 60
	return `${minutes} ${t('player.queue.minutes')} ${seconds} ${t('player.queue.seconds')}`
})

const isSearchView = ref()
const searchInput = ref()
const scrollToLoad = ref(false)
const showSearch = ref()
const showHeaderText = ref(true)
const expandingSearchBar = ref(false)

const userStore	= useUserStore()
const { showDemoPlayer } = storeToRefs(userStore)
const localePath = useLocalePath()
const historySongs = ref<Song[]>([])

const loadHistorySongs = async () => {
  if (historySongs.value.length === 0) { 
    await loadSongHistory(true, { page_size: 5 }, 0, true)
    historySongs.value = songHistorySongs.value
  }
}

const toggleSearch = async (expand: boolean) => {
	loadHistorySongs()
	if (expand) {
		expandingSearchBar.value = true
		isSearchView.value = true
		setTimeout(() => {
			expandingSearchBar.value = false
		}, 300)
	} else {
		isSearchView.value = false
	}
}

const handleFocusOut = () => {
	if (!expandingSearchBar.value) {
		toggleSearch(false)
	}
}

const handleMouseDown = () => {
	expandingSearchBar.value = true
}

const handleMouseUp = () => {
	expandingSearchBar.value = false
}

watch(isSearchView, async (value) => {
	if (value) {
		showHeaderText.value = false
		await nextTick()
		showSearch.value = true
	} else {
		showSearch.value = false
		await nextTick()
		setTimeout(() => showHeaderText.value = true, 200)
	}
})

const currentQueue = computed({
	get() {
		return restOfQueue.value
	},
	set(value: any) {
		setQueue([firstInQueue.value, ...value])
	}
})

const goToSongHistory = () => {
	useEventEmit(('player:closeSettings'))
	navigateTo(localePath('/my-library?tab=song-history'))
}

// Find the right queue entry, and return -1 if it's not found
const timeUntilThisSongInQueue = (songId: string) => {
	let time = 0
	const fullQueue = [firstInQueue.value, ...currentQueue.value]

	for (let i = 0; i < fullQueue.length; i++) {
		if (fullQueue[i] && fullQueue[i].entryID === songId) {
			const minutes = Math.round(time / 60)
			return `${minutes} ${t('player.queue.minutes')}`
		}
		time += fullQueue[i].entry.duration
	}
	return '-1'
}

// This makes sure the unexpanded search input is blurred if the user closes the input with the escape key
watch(isSearchView, (value) => {
	if (!value && searchInput.value) {
		(document.activeElement as HTMLElement)?.blur()
	}
})

// In-player search
const setSearchString = (value: string) => {
	setSearchFilter(value)
	scrollToLoad.value = false
}

useInfiniteScroll(
	loadMoreTrigger,
	() => {
		if (nextPage.value && !loading.value && scrollToLoad.value) {
			loadSongs(false)
		}
	},
	{ distance: 10, interval: 500 }
)

const loadMoreContent = () => {
	scrollToLoad.value = true
	loadSongs(false)
}

useEventOn('search:clearSearch', () => {
	setSearchString('')
})

onUnmounted(() => {
	useEventOff('search:clearSearch')
	historySongs.value = []
})
</script>

<style lang="sass" scoped>
.header
	display: flex
	flex-direction: row
	align-items: center
	position: relative
	height: 53px
	margin-bottom: $spacing-16
	width: 100%
	&:not(.expanded)
		&::after
			content: ''
			position: absolute
			margin-top: $spacing-8
			top: 100%
			width: 100%
			height: 1px
			background-color: $transparent-white-8

.header-texts
	display: flex
	flex-direction: column
	@include fontSize(s)
	color: $color-grey-60
	width: 90%

.fade-enter-active
	transition: opacity .3s
.fade-leave-active
	transition: opacity .3s

.fade-enter, .fade-leave-to
	opacity: 0

.queue-title
	@include font(basier, semiBold)
	@include fontSize(xl)
	color: $color-grey-30
.search-songs, .queue-songs
	max-height: calc(100vh - 328px) // 100vh + (player bar and other queue elements + spacing top and bottom)
	overflow-x: hidden
	overflow-y: auto
	margin-top: $spacing-8
.load-more-wrap
	text-align: center
	margin: $spacing-32 0 $spacing-32
.empty-queue-wrap
	display: flex
	align-items: center
	justify-content: center
	min-height: 200px
.button-wrap
	padding: $spacing-4 0
	text-align: center

.truncate
	overflow: hidden
	text-overflow: ellipsis
	white-space: nowrap
	min-width: 0
	max-width: calc(100% - $spacing-8)

.queue-songs
	counter-reset: song-counter 1

.queue-songs li
	counter-increment: song-counter
	position: relative
.queue-songs li::before
	position: absolute
	top: 50%
	left: 8.75%
	z-index: 100
	transform: translate(-50%, -50%)
	content: counter(song-counter)
	@include font(basier, medium)
	color: $color-grey-10
	@include fontSize(s)

.draggable-item
	cursor: grab
.draggable-item:active
	cursor: grabbing

.button-wrap
	display: flex
	justify-content: flex-end
	.empty-queue
		margin-top: $spacing-8
		&:hover
			background-color: $color-red-70 !important

.demo-player-cta
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	height: 360px
	padding: $spacing-16
	border-radius: $radius-default
	width: 100%
	background-color: rgba($color-grey-90, 0.05)
	gap: $spacing-16

.demo-cta-text
	@include font(basier, regular)
	@include fontSize(s)
	color: $color-grey-30
	text-align: center
	&.top
		max-width: 350px

.see-all-button
	color: $color-grey-50
	text-decoration: underline

.recently-sung
	padding-top: $spacing-8
	margin-left: $spacing-16
	.recently-sung-text
		color: $color-grey-30
		@include font(basier, semiBold)
		@include fontSize(s)
</style>
