export const initPreviewPlayer = (window) => {
	const previewPlayer = () => {
		const Dispatcher = (function () {
			function Dispatcher() {
				this.dispatchEvents = []
			}

			Dispatcher.prototype.addEventListener = function (eventName, handler, identifier) {
				const id = identifier || `${Date.now() + Math.floor(Math.random() * 100000)}`
				const event = {
					eventName,
					handler,
					identifier: id
				}
				if ([
					'ended',
					'pause',
					'play'
				].includes(eventName)) {
					return this.dispatchEvents.push(event)
				}
			}

			Dispatcher.prototype.removeEventListener = function (eventName, id) {
				this.dispatchEvents = this.dispatchEvents.filter(n => n.eventName === eventName && !id || n.identifier !== id)
			}
			Dispatcher.prototype.dispatch = function (eventName, originalEvent) {
				let i, len
				const ref = this.dispatchEvents
				for (i = 0, len = ref.length; i < len; i++) {
					const event = ref[i]
					if (event.eventName === eventName) {
						setTimeout(event.handler(originalEvent), 0)
					}
				}
			}

			Dispatcher.prototype.clearEventListeners = function () {
				this.dispatchEvents = []
			}

			return Dispatcher
		})()

		const _previewPlayerObject = {
			events: new Dispatcher()
		}

		let browserSupported = true
		let audioElement = null
		let audioType = null
		let audioContext
		let track
		let gainNode

		const initAudioContext = function (e) {
			try {
				audioElement = new Audio()
				audioElement.crossOrigin = 'anonymous'
				audioContext = new AudioContext()

				track = audioContext.createMediaElementSource(audioElement)
				gainNode = audioContext.createGain()

				track.connect(gainNode).connect(audioContext.destination)
				audioElement.addEventListener('ended', function (event) {
					console.log('ended')
					_previewPlayerObject.events.dispatch('ended', event)
				})
				audioElement.addEventListener('play', function (event) {
					console.log('play in audioplayer')
					_previewPlayerObject.events.dispatch('play', event)
				})
				audioElement.addEventListener('pause', function (event) {
					console.log('pause in audioplayer')
					_previewPlayerObject.events.dispatch('pause', event)
				})

				_previewPlayerObject.paused = audioElement.paused

				console.log('audiocontext init')
			} catch (err) {
				console.log('catch error', err)
				browserSupported = false
			}

			document.removeEventListener('click', initAudioContext)
			return audioElement
		}

		document.addEventListener('click', initAudioContext)

		_previewPlayerObject.load = function (url, type) {
			audioElement = audioElement || new Audio(url)
			audioType = type
			audioElement.src = url
			audioElement.load()
		}

		_previewPlayerObject.play = function () {
			const playPromise = audioElement.play()
			if (playPromise !== undefined) {
				playPromise.then(function () {
					console.log('previewplayer: started')
				}).catch(function (error) {
					console.log('previewplayer player error ', error, audioElement, audioType)
				})
			} else {
				console.log('previewplayer: play promise not supported by browser')
			}
		}

		_previewPlayerObject.getCurrentTime = function () {
			return audioElement.currentTime
		}

		_previewPlayerObject.isPlaying = function () {
			return audioElement && audioElement.duration > 0 && !audioElement.paused
		}

		_previewPlayerObject.stop = function () {
			audioElement.pause()
			audioElement.currentTime = 0
		}

		_previewPlayerObject.isUnSupportedBrowser = function () {
			return !browserSupported
		}

		return _previewPlayerObject
	}

	if (typeof (window.previewPlayer) === 'undefined') {
		window.previewPlayer = previewPlayer()
	}

	return window.previewPlayer
}
