<template lang="pug">
SingaSlider.mini-player(v-model="audioProgress" :tooltip-always="true" :max="durationSeconds - 3" :step="0.1" @change="change" @dragend="dragEnd" @dragstart="dragStart" :custom-formatter="(val: any) => formatedTime(val)" rounded biggerSliderFocus @dragging="draggingMethod")
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'

const { $audioPlayer } = useNuxtApp()
const audioPlayerStore = useAudioPlayerStore()
const { setSeeking, seekAudioPlayer } = audioPlayerStore
const { currentSeconds, durationSeconds } = storeToRefs(audioPlayerStore)
const previousValue = ref(0)

const formatedTime = (value: number) => {
	const mins = ~~((value % 3600) / 60)
	const secs = ~~value % 60

	// Output like "1:01" or "4:03:59" or "123:03:59"
	let ret = ''
	ret += '' + mins + ':' + (secs < 10 ? '0' : '')
	ret += '' + secs
	return ret
}

const dragStart = () => {
	setSeeking(true)
	$audioPlayer.pause()
}

const dragEnd = () => {
	setSeeking(false)
	$audioPlayer.play()
}

// When seek will be changed
const change = (value: number) => {
	seekAudioPlayer(value, true)
	// $audioPlayer.play()
}

// Dragging seek
const draggingMethod = (value: number) => {
	const shouldClear = value < previousValue.value && (Math.abs(value - previousValue.value) > 0.5)
	previousValue.value = value
	seekAudioPlayer(value, shouldClear)
}

const audioProgress = computed({
	get() {
		return currentSeconds.value
	},
	set(): void {
		//
	}
})
</script>
