import { AnalyticsBrowser } from '@segment/analytics-next'
import type { AnalyticsBrowserSettings } from '@segment/analytics-next'

declare global {
	interface Window {
		sgmnt: AnalyticsBrowser
	}
}
export const useSegment = () => {
	const loadSegment = () => {
		if (import.meta.server || import.meta.dev) { return }
		const analytics = new AnalyticsBrowser()
		const key = useRuntimeConfig().public.segmentPublicKey
		const moduleOptions: AnalyticsBrowserSettings = {
			writeKey: key,
			cdnURL: 'https://cdn.s.singa.com'
		}
		const cdnSettings = {
			integrations: {
				'Segment.io': { apiHost: 's.singa.com/v1' }
			}
		}
		const loadedAnalytics = analytics.load(moduleOptions, cdnSettings)
		window.sgmnt = loadedAnalytics
	}
	const segmentEvent = (event: string, eventParams?: object) => {
		if (import.meta.server || import.meta.dev) { return }
		window.sgmnt.track(event, { ...eventParams })
	}

	return {
		loadSegment,
		segmentEvent
	}
}
