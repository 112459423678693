export const initAudioPlayer = (window) => {
	const audioPlayer = () => {
		const Dispatcher = (function () {
			function Dispatcher() {
				this.dispatchEvents = []
			}

			Dispatcher.prototype.addEventListener = function (eventName, handler, identifier) {
				const id = identifier || `${Date.now() + Math.floor(Math.random() * 100000)}`
				const event = {
					eventName,
					handler,
					identifier: id
				}
				if ([
					'ended',
					'timeupdate',
					'frequenttimeupdate',
					'pause',
					'canplaythrough',
					'play',
					'loadedmetadata',
					'seeked',
					'playing'
				].includes(eventName)) {
					return this.dispatchEvents.push(event)
				}
			}

			Dispatcher.prototype.removeEventListener = function (eventName, id) {
				this.dispatchEvents = this.dispatchEvents.filter(n => n.eventName === eventName && !id || n.identifier !== id)
			}
			Dispatcher.prototype.dispatch = function (eventName, originalEvent) {
				let i, len
				const ref = this.dispatchEvents
				for (i = 0, len = ref.length; i < len; i++) {
					const event = ref[i]
					if (event.eventName === eventName) {
						setTimeout(function (event, originalEvent) {
							event.handler(originalEvent)
						}, 0, event, originalEvent)
					}
				}
			}

			Dispatcher.prototype.clearEventListeners = function () {
				this.dispatchEvents = []
			}
			return Dispatcher
		})()

		const _audioPlayerObject = {
			events: new Dispatcher()
		}

		let browserSupported = true
		let audioElement = null
		let audioType = null
		let audioContext
		let track
		let gainNode

		const initAudioContext = function (e) {
			try {
				audioContext = new AudioContext()
				audioElement = new Audio()
				audioElement.crossOrigin = 'anonymous'

				track = audioContext.createMediaElementSource(audioElement)
				gainNode = audioContext.createGain()

				track.connect(gainNode).connect(audioContext.destination)
				audioElement.addEventListener('ended', function (event) {
					console.log('ended in audioplayer')
					_audioPlayerObject.events.dispatch('ended', event)
				})
				audioElement.addEventListener('play', function (event) {
					console.log('play in audioplayer')
					_audioPlayerObject.events.dispatch('play', event)
				})
				audioElement.addEventListener('pause', function (event) {
					console.log('pause in audioplayer')
					_audioPlayerObject.events.dispatch('pause', event)
				})
				audioElement.addEventListener('loadedmetadata', function (event) {
					_audioPlayerObject.events.dispatch('loadedmetadata', event)
				})

				audioElement.addEventListener('timeupdate', function (event) {
					_audioPlayerObject.events.dispatch('timeupdate', audioElement.currentTime)
				})

				audioElement.addEventListener('seeked', function (event) {
					_audioPlayerObject.events.dispatch('seeked', event)
				})

				audioElement.addEventListener('playing', function (event) {
					_audioPlayerObject.events.dispatch('playing', event)
				})

				_audioPlayerObject.paused = audioElement.paused
				console.log('audiocontext init')
				_audioPlayerObject.initialized = true
			} catch (err) {
				console.log('catch error', err)
				browserSupported = false
			}

			document.removeEventListener('click', initAudioContext)
			return audioElement
		}

		document.addEventListener('click', initAudioContext)

		_audioPlayerObject.load = function (url, type) {
			audioElement = audioElement || new Audio(url)
			audioType = type
			audioElement.src = url
			audioElement.load()
		}

		_audioPlayerObject.play = function () {
			const playPromise = audioElement.play()
			if (playPromise !== undefined) {
				playPromise.then(function () {
					console.log('audioplayer: started')
				}).catch(function (error) {
					console.log('audioplayer player error ', error, audioElement, audioType)
					useEventEmit('playerEvent:error')
					audioElement.pause()
					audioElement.currentTime = 0
				})
			} else {
				console.log('audioplayer: play promise not supported by browser')
			}
		}

		setInterval(function () {
			if (audioElement && !audioElement.paused) {
				_audioPlayerObject.events.dispatch('frequenttimeupdate', audioElement.currentTime)
			}
		}, 33.3334)
		_audioPlayerObject.pause = function () {
			audioElement?.pause()
		}

		_audioPlayerObject.seek = function (timeStamp) {
			console.log('IN SEEK', timeStamp)
			if (audioElement) {
				audioElement.currentTime = timeStamp
			}
		}

		_audioPlayerObject.setStartingTime = function (time) {
			audioElement.currentTime = time
		}

		_audioPlayerObject.clearEvents = function () {
			_audioPlayerObject.events.clearEventListeners()
		}

		_audioPlayerObject.isPlaying = function () {
			return audioElement && audioElement.duration > 0 && !audioElement.paused
		}

		_audioPlayerObject.isKaraokePlaying = function () {
			return audioElement && audioElement.duration > 0 && !audioElement.paused && audioType === 'karaoke'
		}

		_audioPlayerObject.isPreviewPlaying = function () {
			console.log(audioElement, audioType)
			return audioElement && audioElement.duration > 0 && !audioElement.paused && audioType === 'preview'
		}

		_audioPlayerObject.stop = function () {
			audioElement.pause()
			audioElement.currentTime = 0
		}

		_audioPlayerObject.setVolume = function (volumeValue) {
			gainNode.gain.value = volumeValue
		}

		_audioPlayerObject.getCurrentTime = function () {
			return audioElement.currentTime
		}

		_audioPlayerObject.getDuration = function () {
			return audioElement.duration
		}

		_audioPlayerObject.hasEnded = function () {
			return audioElement.ended
		}
		_audioPlayerObject.getVolume = function () {
			return gainNode.gain.value
		}

		_audioPlayerObject.isUnSupportedBrowser = function () {
			return !browserSupported
		}

		return _audioPlayerObject
	}

	if (typeof (window.audioPlayer) === 'undefined') {
		window.audioPlayer = audioPlayer()
	}
	return window.audioPlayer
}
